
//import "./PageHeader.scss";

export const PageHeader = (props: any) =>
{
    return (
        <div className="page-header" style={{ gridArea: 'header' }}>
            {props.children}
        </div>
    );
};