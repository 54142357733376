import { CompoundButton, DefaultButton, Dialog, DialogFooter, DialogType, Dropdown, PrimaryButton, Tooltip, TooltipHost } from "@fluentui/react";
import { useCallback, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { Card } from "../components/Card";
import { CardContent } from "../components/CardContent";
import { CardHeader } from "../components/CardHeader";
import { Flex } from "../components/Flex";
import { FormList } from "../components/FormList";
import { PageContainer } from "../components/PageContainer";
import { AppRoutes } from "../constants/AppRoutes";
import { DeleteForm, GetFormsAssignedToMe, GetForms, UpdateForm, CancelForm } from "../data/queries/FormQueries";
import { QueryKeys } from "../data/QueryKeys";
import { Form, FormStatus } from "../data/models/Form";

import "./AdvisorDashboard.scss";
import { useUserContext } from "../hooks/useUserContext";
import { hasPermission, hasRole, UserRole } from "../data/models/User";
import { useToggle } from "../hooks/useToggle";
import { AssignedToList } from "../components/AssignedToList";
import { FormVppwPreApproval } from "../data/models/FormVppwPreApproval";

export const AdvisorDashboard = (props: any) => 
{
    const nav = useNavigate();
    const withdrawRequestDialog = useToggle(false);
    const deleteRequestDialog = useToggle(false);
    const revokeRequestDialog = useToggle(false);
    const [withdrawForm, setWithdrawForm] = useState<Form | null>(null);
    const [deleteForm, setDeleteForm] = useState<Form | null>(null);
    const [revokeForm, setRevokeForm] = useState<Form | null>(null);
    const { user } = useUserContext();
    const [statusFilter, setStatusFilter] = useState<FormStatus | string>('all');
    const { data, isLoading, status, refetch } = useQuery(QueryKeys.AllForms(statusFilter), () => GetForms(statusFilter === 'all' ? undefined : statusFilter as FormStatus));
    const assignedToMeQuery = useQuery(QueryKeys.AssignedToMe(), () => GetFormsAssignedToMe());

    useEffect(() => {
        // Automatically scroll to top of dashboard when loaded
        // Fixes issue where users would be shown bottom of list after approving a form
        window.scrollTo(0, 0);
    }, []);


    const statusFilterOptions = [
        { key: 'all', text: 'All' },
        { key: FormStatus.Draft, text: 'Draft' },
        { key: `${FormStatus.DealerApproval},${FormStatus.ComplianceApproval},${FormStatus.FinanceApproval},${FormStatus.AdvisorClarification},${FormStatus.DealerClarification},${FormStatus.ComplianceClarification},${FormStatus.AdvisorRevision},${FormStatus.BusinessApproval}`, text: 'Submitted' },
        { key: FormStatus.Completed, text: 'Approved' },
        { key: FormStatus.Paid, text: 'Paid' },
        { key: FormStatus.Rejected, text: 'Rejected' },
        { key: FormStatus.Withdrawn, text: 'Withdrawn' }
    ];

    const handleRowAction = useCallback(async (form: Form, action: string) => 
    {
        switch(action)
        {
            case 'withdraw':
                setWithdrawForm(form);    
                withdrawRequestDialog.open();
                break;
            case 'reimbursement':
                switch(form.type)
                {
                    case 'coop-preapproval': nav(AppRoutes.Coop.Reimbursement('', form.id)); break;
                    case 'vpic-preapproval': nav(AppRoutes.VPIC.Reimbursement('', form.id)); break;
                    case 'vppw-preapproval': nav(AppRoutes.VPPW.Reimbursement('', form.id)); break;
                }
                break;
            case 'delete':
                setDeleteForm(form);
                deleteRequestDialog.open();
                break;
            case 'revoke-approval':
                setRevokeForm(form);
                revokeRequestDialog.open();
                break;
            default:
                console.warn(`No handler for action '${action}'`);
        }
    }, [nav]);

    return (
        <PageContainer type="2column">
            <Card style={{ flex: 2, paddingBottom: '40px' }}>
                <CardHeader>Dashboard</CardHeader>

                <Flex row align="center" style={{ padding: '15px 20px' }}>
                    <div style={{ marginRight: '10px' }}>Status</div>
                    <Dropdown selectedKey={statusFilter} onChange={(e, option) => setStatusFilter(option?.key as any || 'all')} styles={{ root: { maxWidth: '200px', width: '100%' } }} options={statusFilterOptions} />
                </Flex>

                <FormList items={data} isLoading={isLoading} onRowClick={(e, form) => { e.preventDefault(); nav(AppRoutes.byFormType(form.type, form.id))} } onRowAction={handleRowAction} />
            </Card>
            <Card style={{ flex: 1 }}>
                {
                    hasRole(user, UserRole.Advisor, UserRole.Assistant, UserRole.Compliance, UserRole.Finance, UserRole.VPPWCompliance, UserRole.VPPWFinance, UserRole.Administrator) &&
                    <>
                        <CardHeader>Start a New Form</CardHeader>
                        <CardContent style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                            {
                                hasPermission(user, 'coop.write') &&
                                <DefaultButton text="Co-op Pre-Approval Request" onClick={() => nav(AppRoutes.Coop.PreApproval())} />
                            }
                            {
                                hasRole(user, UserRole.Compliance, UserRole.Finance) &&
                                <DefaultButton text="Co-op Reimbursement Request" onClick={() => nav(AppRoutes.Coop.Reimbursement())} />
                            }
                            {
                                hasPermission(user, 'vpic.write') &&
                                <DefaultButton text="VPIC Pre-Approval Request" onClick={() => nav(AppRoutes.VPIC.PreApproval())} />
                            }
                            {
                                hasRole(user, UserRole.Compliance, UserRole.Finance) &&
                                <DefaultButton text="VPIC Reimbursement Request" onClick={() => nav(AppRoutes.VPIC.Reimbursement())} />
                            }
                            {
                                hasPermission(user, 'vppw.write') &&
                                <DefaultButton text="VPPW Pre-Approval Request" onClick={() => nav(AppRoutes.VPPW.PreApproval())} />
                            }
                            {
                                hasRole(user, UserRole.VPPWCompliance, UserRole.VPPWFinance) &&
                                <DefaultButton text="VPPW Reimbursement Request" onClick={() => nav(AppRoutes.VPPW.Reimbursement())} />
                            }
                        </CardContent>

                        <hr />
                    </>
                }

                {
                    hasRole(user, UserRole.Advisor, UserRole.Assistant, UserRole.Dealer, UserRole.Compliance, UserRole.Finance, UserRole.Accounting, UserRole.VPPWBusiness, UserRole.VPPWCompliance, UserRole.VPPWFinance) &&
                    <>
                        <CardHeader>Assigned To Me</CardHeader>
                        <AssignedToList items={assignedToMeQuery.data} isLoading={assignedToMeQuery.isFetching} onRowClick={(e, form) => nav(AppRoutes.byFormType(form.type, form.id))} />
                        <div style={{ height: '40px' }}></div>
                    </>
                }
                
            </Card>

            <Dialog
                hidden={!withdrawRequestDialog.isOpen}
                onDismiss={withdrawRequestDialog.close}
                dialogContentProps={{
                    type: DialogType.normal,
                    title: `Withdraw Request`,
                    subText: `Are you sure you want to withdraw request ${withdrawForm?.formId}?`
                }}
            >
                <DialogFooter>
                    <PrimaryButton text="Withdraw" disabled={withdrawForm === null} onClick={async () => {
                        if(withdrawForm)
                        {
                            const type = withdrawForm.type;
                            const id = withdrawForm.id;

                            setWithdrawForm(null)
                            await CancelForm(type, id, { comments: '' })
                            withdrawRequestDialog.close();
                            refetch();
                        }
                    }} />
                    <DefaultButton text="Cancel" disabled={withdrawForm === null} onClick={withdrawRequestDialog.close} />
                </DialogFooter>
            </Dialog>

            <Dialog
                hidden={!deleteRequestDialog.isOpen}
                onDismiss={deleteRequestDialog.close}
                dialogContentProps={{
                    type: DialogType.normal,
                    title: 'Delete Request',
                    subText: `Are you sure you want to delete request ${deleteForm?.formId}?`
                }}
            >
                <DialogFooter>
                    <PrimaryButton text="Delete" disabled={deleteForm === null} styles={{ root: { backgroundColor: '#c50f1f' } }} onClick={async () => {
                        if(deleteForm)
                        {
                            const type = deleteForm.type;
                            const id = deleteForm.id;

                            await DeleteForm(type, id);
                            deleteRequestDialog.close();
                            setDeleteForm(null);
                            refetch();
                        }
                    }} />
                    <DefaultButton text="Cancel" disabled={deleteForm === null} onClick={deleteRequestDialog.close} />
                </DialogFooter>
            </Dialog>

            <Dialog
                hidden={!revokeRequestDialog.isOpen}
                onDismiss={revokeRequestDialog.close}
                dialogContentProps={{
                    type: DialogType.normal,
                    title: 'Revoke Standing Approval',
                    subText: `Are you sure you want to revoke the standing approval for request ${revokeForm?.formId}?`
                }}
            >
                <DialogFooter>
                    <PrimaryButton text="Revoke" disabled={revokeForm === null} onClick={async () => {
                        if(revokeForm)
                        {
                            const type = revokeForm.type;
                            const id = revokeForm.id;

                            await UpdateForm(type, id, {
                                isStandingApproval: false
                            } as FormVppwPreApproval);
                            
                            revokeRequestDialog.close();
                            setRevokeForm(null);
                            refetch();
                        }
                    }} />
                    <DefaultButton text="Cancel" disabled={revokeForm === null} onClick={revokeRequestDialog.close} />
                </DialogFooter>
            </Dialog>

        </PageContainer>
    );
};