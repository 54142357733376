
import React, { useCallback } from "react";
import * as Yup from "yup";
import { Formik, Form, FormikHelpers, FormikProps, useFormikContext } from "formik";
import { VPITextField } from "../../fields/VPITextField"
import { FormCoopPreApproval } from "../../data/models/FormCoopPreApproval";
import { copyInto, currencyToString, validateWhen } from "../../utilities/FormUtils";
import { DefaultButton, PrimaryButton } from "@fluentui/react";
import { useUserContext } from "../../hooks/useUserContext";
import { FormSectionHeader } from "../../components/FormSectionHeader";
import { TaskOutcome } from "../../data/models/Task";
import { format } from "date-fns";
import { UpdateTask } from "../../data/queries/TaskQueries";
import { AdvisorClarificationDialog, handleSelectedOutcome } from "../../forms/DefaultStages";
import { VPIDisplayTextBox } from "../../fields/VPIDisplayTextBox";
import { FormCoopReimbursement } from "../../data/models/FormCoopReimbursement";
import { TaskCoopReimbursement } from "../../data/models/TaskCoopReimbursement";
import { VPICurrencyField } from "../../fields/VPICurrencyField";
import { CardContent } from "../../components/CardContent";
import { CardHeader } from "../../components/CardHeader";
import { FormGridStyles } from "../../constants/Constants";
import { useToggle } from "../../hooks/useToggle";

export interface CoopReimbursementComplianceFormProps
{
    form: FormCoopReimbursement;
    task: TaskCoopReimbursement;
    preApprovalForm?: FormCoopPreApproval;
    onSave?: () => void;
    allowDealerClarification: boolean;
}

export const CoopReimbursementComplianceForm = (props: CoopReimbursementComplianceFormProps) => 
{
    const { form, task, preApprovalForm, onSave, allowDealerClarification } = props;

    const s: React.CSSProperties = Object.assign({}, FormGridStyles, {
        gridTemplateAreas: `
            'assessmentHeader assessmentHeader assessmentHeader'
            'assessmentQ1 assessmentQ1 assessmentQ1'
            'assessmentQ2 assessmentQ2 assessmentQ2'
            'analysisHeader analysisHeader analysisHeader'
            'analysisQ1 analysisQ1 analysisQ1'
            'analysisQ2 analysisQ2 analysisQ2'
            'analysisQ3 analysisQ3 analysisQ3'
            'analysisQ4 analysisQ4 analysisQ4'
            'conclusion conclusion conclusion'
            'certificationForm certificationForm certificationForm'
        `
    });

    const rowStyle: React.CSSProperties = {
        display: 'flex', 
        gap: '15px', 
        marginBottom: '15px'
    };

    const onValidate = useCallback(validateWhen(Yup.object({
        providedInvoiceCopiesComment: Yup.string().required('Notes are required').max(2000, 'Notes cannot be longer than 2,000 characters'),
        reimbursementPaidDealerMemberComment: Yup.string().required('Notes are required').max(2000, 'Notes cannot be longer than 2,000 characters'),
        actualCost: Yup.number().integer().required('Actual Cost is required'),
        totalAttendance: Yup.number().integer('Must be a whole number').moreThan(-1, 'Attendance cannot be negative').required('Total Attendance is required'),
        budgetedCostVarianceComment: Yup.string().max(2000, 'Comments cannot be longer than 2,000 characters'),
        numberParticipantsVarianceComment: Yup.string().max(2000, 'Comments cannot be longer than 2,000 characters'),
        budgetedCostPerPersonVarianceComment: Yup.string().max(2000, 'Comments cannot be longer than 2,000 characters'),
        comments: Yup.string().optional().max(2000, 'Comments cannot be longer than 2,000 characters'),
        action: Yup.string()
    }), values => values.action === 'approve'), []);

    const initialValues = copyInto({
        providedInvoiceCopiesComment: '',
        reimbursementPaidDealerMemberComment: '',
        actualCost: 0,
        totalAttendance: 0,
        budgetedCostVarianceComment: '',
        numberParticipantsVarianceComment: '',
        budgetedCostPerPersonVarianceComment: '',
        comments: '',
        action: ''
    }, { 
        ...task, 
        actualCost: task.actualCost || form.amountRequested, 
        totalAttendance: task.totalAttendance || form.attendanceClients + form.attendanceEmployees 
    });

    const onSubmit = async (values: TaskCoopReimbursement & {[key: string]: any}, actions: FormikHelpers<any>) =>
    {
        const { action, comments, ...payload } = values;

        try
        {
            let outcome = TaskOutcome.Approved;

            switch(action)
            {
                case 'approve': outcome = TaskOutcome.Approved; break;
                case 'reject': outcome = TaskOutcome.Rejected; break;
                case 'advisor-clarification': outcome = TaskOutcome.AdvisorClarification; break;
                case 'advisor-revision': outcome = TaskOutcome.AdvisorRevision; break;
                case 'dealer-clarification': outcome = TaskOutcome.DealerClarification; break;
            }

            await UpdateTask(form.type, form.id, task.id, payload);
            await handleSelectedOutcome(form, task, outcome, comments);

            onSave?.();
        }
        catch(e: any)
        {
            console.error(e);
            alert(e.message);
        }
    };

    return (
        <>
        <CardHeader>Internal Checklist - Co-operative Marketing Arrangements</CardHeader>
        <CardContent>
            <Formik 
                initialValues={initialValues} 
                onSubmit={onSubmit}
                validate={onValidate}
                enableReinitialize>
                { formProps => (
                    <Form style={s}>
                        <FormSectionHeader gridArea="assessmentHeader">
                            <h3>Assessment</h3>
                        </FormSectionHeader>

                        <div style={{ gridArea: 'assessmentQ1' }}>
                            <p>1. Has the dealer provided copies of invoices? If so, are there any ineligible expenses, for example, gifts, in invoice(s)?</p>
                            <VPITextField name="providedInvoiceCopiesComment" label="Notes" multiline required />
                        </div>

                        <div style={{ gridArea: 'assessmentQ2' }}>
                            <p>2. Is the reimbursement being paid out to Dealer member? Note: Reimbursements to dealing representatives is not allowed.</p>
                            <VPITextField name="reimbursementPaidDealerMemberComment" label="Notes" multiline required />
                        </div>

                        <FormSectionHeader gridArea="analysisHeader">
                            <h3>Quantitative Analysis</h3>
                        </FormSectionHeader>

                        <div style={{ gridArea: 'analysisQ1' }}>
                            <h4>Cost</h4>
                            <div style={rowStyle}>
                                <VPIDisplayTextBox label="Budgeted Cost" value={preApprovalForm ? currencyToString(preApprovalForm.eventEstimatedTotalCost) : 'N/A'} />
                                <VPICurrencyField name="actualCost" label="Actual Cost" required style={{ flex: 1 }} />
                                <VPIDisplayTextBox label="Variance" value={preApprovalForm ? currencyToString(formProps.values.actualCost - preApprovalForm.eventEstimatedTotalCost) : 'N/A'} />
                            </div>
                            <VPITextField name="budgetedCostVarianceComment" label="Notes" multiline />
                        </div>

                        <div style={{ gridArea: 'analysisQ2' }}>
                            <h4>Attendance</h4>
                            <div style={rowStyle}>
                                <VPIDisplayTextBox label="Budgeted Attendance" value={preApprovalForm ? preApprovalForm.attendanceClients + preApprovalForm.attendanceEmployees : 'N/A'} />
                                <VPITextField type="number" name="totalAttendance" label="Total Attendance" required style={{ flex: 1 }} />
                                <VPIDisplayTextBox label="Variance" value={preApprovalForm ? formProps.values.totalAttendance - (preApprovalForm.attendanceClients + preApprovalForm.attendanceEmployees) : 'N/A' } />
                            </div>
                            <VPITextField name="numberParticipantsVarianceComment" label="Notes" multiline />
                        </div>

                        <div style={{ gridArea: 'analysisQ3' }}>
                            <h4>Cost Per Person</h4>
                            <div style={rowStyle}>
                                <VPIDisplayTextBox label="Budgeted Cost Per Person" value={preApprovalForm && (preApprovalForm.attendanceClients + preApprovalForm.attendanceEmployees > 0) ? 
                                    currencyToString(preApprovalForm.eventEstimatedTotalCost / (preApprovalForm.attendanceClients + preApprovalForm.attendanceEmployees)) : 'N/A'} />
                                <VPIDisplayTextBox label="Actual Cost Per Person" value={formProps.values.totalAttendance > 0 ? currencyToString(formProps.values.actualCost / formProps.values.totalAttendance) : 'N/A'} />
                                <VPIDisplayTextBox label="Variance" value={
                                    preApprovalForm && (preApprovalForm.attendanceClients + preApprovalForm.attendanceEmployees > 0) && formProps.values.totalAttendance > 0 ?
                                    currencyToString((formProps.values.actualCost / formProps.values.totalAttendance) - (preApprovalForm.eventEstimatedTotalCost / (preApprovalForm.attendanceClients + preApprovalForm.attendanceEmployees)))
                                    : 'N/A'
                                } />
                            </div>
                            <VPITextField name="budgetedCostPerPersonVarianceComment" label="Notes" multiline />
                        </div>

                        <CertificationForm formProps={formProps} allowDealerClarification={allowDealerClarification} />
                    </Form>
                )}
            </Formik>
        </CardContent>
        </>
    );
};

const CertificationForm = (props: { formProps: FormikProps<any>, allowDealerClarification: boolean }) =>
{
    const { formProps, allowDealerClarification } = props;
    const formikContext = useFormikContext();
    const { user } = useUserContext();
    const advisorDialog = useToggle(false);

    return (
        <div style={{ gridArea: 'certificationForm' }}>
            <hr />

            <h4>Certification</h4>
            <p>The undersigned has reviewed the above request and authorizes the request.</p>

            <VPIDisplayTextBox label="VPI Compliance Name/Signature" value={user?.name} />
            <VPIDisplayTextBox label="Date" value={format(new Date(), 'PP')} />
            <VPITextField name="comments" label="Comments" multiline />

            <div style={{ display: 'flex', gap: '10px', justifyContent: 'flex-end', marginTop: '15px' }}>
                <DefaultButton text="Advisor Clarification..." disabled={formikContext.isSubmitting} onClick={() => {
                    //formProps.setFieldValue('action', 'advisor-clarification');
                    //setTimeout(() => formProps.submitForm());
                    advisorDialog.open();
                }} />
                {/* <DefaultButton text="Advisor Revision" disabled={formikContext.isSubmitting} onClick={() => {
                    formProps.setFieldValue('action', 'advisor-revision');
                    setTimeout(() => formProps.submitForm());
                }} /> */}
                {
                    allowDealerClarification &&
                    <DefaultButton text="LP Dealer Clarification" disabled={formikContext.isSubmitting} onClick={() => {
                        formProps.setFieldValue('action', 'dealer-clarification');
                        setTimeout(() => formProps.submitForm());
                    }} />
                }
            </div>
            <hr />
            <div style={{ display: 'flex', gap: '10px', justifyContent: 'flex-end' }}>
                <DefaultButton text="Reject" disabled={formikContext.isSubmitting} onClick={() => {
                    formProps.setFieldValue('action', 'reject');
                    // Form fails validation if submitted immediately after setting value
                    setTimeout(() => formProps.submitForm());
                }}  />
                <PrimaryButton text="Approve" disabled={formikContext.isSubmitting} onClick={() => {
                    formProps.setFieldValue('action', 'approve');
                    // Form fails validation if submitted immediately after setting value
                    setTimeout(() => formProps.submitForm());
                }} />
            </div>
            <AdvisorClarificationDialog open={advisorDialog.isOpen} onDismiss={advisorDialog.close} onSelect={async (action) => {
                formProps.setFieldValue('action', action === 'clarification' ? 'advisor-clarification' : 'advisor-revision');
                return new Promise((resolve) => {
                    setTimeout(() => formProps.submitForm().then(resolve));
                });
            }} />
        </div>
    );
};
