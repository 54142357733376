import { DatePicker, TimePicker } from "@fluentui/react";
import { format, isDate, isValid } from "date-fns";
import { useField, useFormik, useFormikContext } from "formik";

export interface VPIDateFieldProps 
{
    name: string;
    label?: string;
    minDate?: Date;
    maxDate?: Date;
    includeTime?: boolean;
    disabled?: boolean;
    dateFormatString?: string;
    required?: boolean;
}

export const VPIDateField = (props: VPIDateFieldProps) => 
{
    const { name, label, minDate, maxDate, includeTime, required = false, disabled = false, dateFormatString = 'PP' } = props;
    const [ field, meta, helpers ] = useField(name);
    const formikContext = useFormikContext();

    const formatDate = (date?: Date) => {
        return date && isValid(date) ? format(new Date(date), dateFormatString) : 'Invalid Date';
    };

    return (
        <div style={{ gridArea: name, display: 'flex' }}>
            <DatePicker
                label={label}
                styles={{ root: { flex: 3 }, statusMessage: { display: 'none' } }}
                onSelectDate={date => helpers.setValue(date)}
                value={field.value}
                onBlur={field.onBlur}
                minDate={minDate}
                maxDate={maxDate}
                disabled={disabled || formikContext.isSubmitting}
                formatDate={dateFormatString ? formatDate : undefined}
                textField={{ 
                    errorMessage: meta.touched ? meta.error : '',
                    required 
                }} />
            {
                includeTime &&
                <TimePicker
                    styles={{ container: { flex: '0 0 120px', marginLeft: '10px' }, label: { visibility: 'hidden' }}}
                    label="Time"
                    useHour12
                    defaultValue={field.value}
                    useComboBoxAsMenuWidth
                    allowFreeform
                    autoComplete="on"
                    placeholder="00:00"
                    timeRange={{ start: 0, end: 24 }}
                    disabled={disabled || formikContext.isSubmitting}
                    onChange={(e, time) => {
                        const d: Date = new Date(field.value);
                        d.setHours(time.getHours());
                        d.setMinutes(time.getMinutes());
                        d.setSeconds(0);
                        helpers.setValue(d)
                    }}
                    onBlur={field.onBlur}
                />
            }
        </div>
    );
}