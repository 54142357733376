import React, { useEffect } from 'react';
import { useAuth } from '../../hooks/useAuth';
import '../../App.scss';
import { Card } from '../../components/Card';
import { CardContent } from '../../components/CardContent';
import { Spinner, SpinnerSize } from '@fluentui/react';

import "./AuthPage.scss";

export const LogoutPage: React.FunctionComponent = () => 
{
    const auth = useAuth();

    useEffect(() => { auth.logout(); }, [auth]);

    return (
        <div className="auth-page">
            <Card style={{ width: '320px', margin: '40px auto', textAlign: 'center' }}>
                <CardContent>
                    <Spinner size={SpinnerSize.large} />
                    <div style={{ marginTop: '20px' }}>Logging out</div>
                </CardContent>
            </Card>
        </div>
    );
};
