import { Form, Formik } from "formik";
import { format, isDate } from "date-fns";
import { CSSProperties } from "react";
import { useQuery } from "react-query";
import { FormSectionHeader } from "../../components/FormSectionHeader";
import { GetUser } from "../../data/queries/UserQueries";
import { QueryKeys } from "../../data/QueryKeys";
import { VPIDisplayField } from "../../fields/VPIDisplayField";
import { FormVpicPreApproval } from "../../data/models/FormVpicPreApproval";
import { VPIDisplayTextBox } from "../../fields/VPIDisplayTextBox";
import { currencyToString } from "../../utilities/FormUtils";
import { VPIAttachmentsDisplayField } from "../../fields/VPIAttachmentsDisplayField";
import { FormStatusBar } from "../../components/FormStatusBar";
import { DetailsGridStyles2Col, DetailsGridStyles3Col } from "../../constants/Constants";

export interface VPICPreApprovalDetailsProps
{
    form: FormVpicPreApproval;
    variant?: '3column' | '2column';
}

export const VPICPreApprovalDetails = (props: VPICPreApprovalDetailsProps) =>
{
    const { form, variant = '3column' } = props;
    const initialValues = form ?? {};
    const userQuery = useQuery(QueryKeys.User.Id(form.advisorUserId), () => GetUser(form.advisorUserId), { enabled: !!form.advisorUserId });

    let s: CSSProperties = Object.assign({}, DetailsGridStyles3Col, {
        gridTemplateAreas: `
            'date telephone .'
            'advisorUserId . .'
            'city province .'
            'expenseType expenseType .'
            'expenseEstimatedTotalCost expenseAmountRequested eventDate'
            'expenseDetails expenseDetails expenseDetails'
            'attachments attachments .'
            'attendanceHeader attendanceHeader attendanceHeader'
            'attendanceClients attendanceEmployees .'
            'attendanceAnalysisHeader attendanceAnalysisHeader attendanceAnalysisHeader'
            'totalAttendance totalCost .'
            'costPerPerson . .'
        `
    });

    if(variant === '2column')
    {
        s = Object.assign({}, DetailsGridStyles2Col, {
            gridTemplateAreas: `
                'date telephone'
                'advisorUserId advisorUserId'
                'city province'
                'expenseType expenseType'
                'expenseEstimatedTotalCost expenseAmountRequested'
                'eventDate .'
                'expenseDetails expenseDetails'
                'attachments attachments'
                'attendanceHeader attendanceHeader'
                'attendanceClients attendanceEmployees'
                'attendanceAnalysisHeader attendanceAnalysisHeader'
                'totalAttendance totalCost'
                'costPerPerson .'
            `
        });
    }

    return (
        <>
        <FormStatusBar status={form?.formStatus} />
        <Formik
            initialValues={initialValues}
            onSubmit={() => {}}
            enableReinitialize>
            { formProps => (
                <Form style={s}>
                    <VPIDisplayField name="date" label="Date" render={(date: Date) => isDate(date) ? format(date, 'PP') : '(No date set)' } />
                    <VPIDisplayField name="telephone" label="Telephone" render={() => userQuery.data?.telephone || ''} />
                    <VPIDisplayField name="advisorUserId" label="Advisor Name" render={() => userQuery.data?.name || ''} />
                    <VPIDisplayField name="city" label="City" render={() => userQuery.data?.city || ''} />
                    <VPIDisplayField name="province" label="Province" render={() => userQuery.data?.province || ''} />
                    <VPIDisplayField name="expenseType" label="Expense Type" />
                    
                    <VPIDisplayField name="expenseEstimatedTotalCost" label="Estimated Total Cost" render={(val: number) => currencyToString(val)} />
                    <VPIDisplayField name="expenseAmountRequested" label="Amount Requested" render={(val: number) => currencyToString(val)} />
                    <VPIDisplayField name="eventDate" label="Event Date" render={(date: Date) => isDate(date) ? format(date, 'PP') : '(No date set)'} />

                    {/* <FormSectionHeader gridArea="expenseDetailsHeader">
                        <h3>Expense Details</h3>
                    </FormSectionHeader> */}
                    <VPIDisplayField name="expenseDetails" label="Expense Details" />

                    {/* <FormSectionHeader gridArea="supportingDocsHeader">
                        <h3>Supporting documents</h3>
                    </FormSectionHeader> */}
                    <VPIAttachmentsDisplayField name="attachments" label="Supporting Documents" />

                    <FormSectionHeader gridArea="attendanceHeader">
                        <h3>Attendance Information (Estimated)</h3>
                    </FormSectionHeader>                    
                    <VPIDisplayField name="attendanceClients" label="Clients" />
                    <VPIDisplayField name="attendanceEmployees" label="Employees" />

                    <FormSectionHeader gridArea="attendanceAnalysisHeader">
                        <h3>Attendance Information Analysis</h3>
                    </FormSectionHeader>       
                    <VPIDisplayField name="totalAttendance" label="Total Attendance (Estimate)" render={() => (
                        form.attendanceClients + form.attendanceEmployees).toString()
                    } />
                    <VPIDisplayField name="totalCost" label="Total Cost (Estimate)" render={() => (
                        currencyToString(form.expenseEstimatedTotalCost)
                    )} />
                    <VPIDisplayField name="costPerPerson" label="Cost Per Person (Estimate)" render={() => (
                        (form.attendanceClients + form.attendanceEmployees) > 0 ? currencyToString(form.expenseEstimatedTotalCost / (form.attendanceClients + form.attendanceEmployees)) : 'N/A'
                    )} />
                </Form>
            )}
        </Formik>
        </>
    )
}