
import React, { useCallback } from "react";
import * as Yup from "yup";
import { Formik, Form, FormikHelpers, FormikProps, useFormikContext } from "formik";
import { VPITextField } from "../../fields/VPITextField";
import { FormCoopPreApproval } from "../../data/models/FormCoopPreApproval";
import { copyInto, currencyToString, validateWhen } from "../../utilities/FormUtils";
import { DefaultButton, PrimaryButton } from "@fluentui/react";
import { useUserContext } from "../../hooks/useUserContext";
import { FormSectionHeader } from "../../components/FormSectionHeader";
import { VPIChoiceField } from "../../fields/VPIChoiceField";
import { TaskCoopPreApproval } from "../../data/models/TaskCoopPreApproval";
import { TaskOutcome } from "../../data/models/Task";
import { format } from "date-fns";
import { UpdateTask } from "../../data/queries/TaskQueries";
import { AdvisorClarificationDialog, handleSelectedOutcome } from "../../forms/DefaultStages";
import { VPIDisplayTextBox } from "../../fields/VPIDisplayTextBox";
import { FormGridStyles } from "../../constants/Constants";
import { useToggle } from "../../hooks/useToggle";
import { VPICurrencyField } from "../../fields/VPICurrencyField";
import { CardHeader } from "../../components/CardHeader";
import { CardContent } from "../../components/CardContent";

export interface CoopPreApprovalComplianceFormProps
{
    form: FormCoopPreApproval;
    task: TaskCoopPreApproval;
    onSave?: () => void;
    allowDealerClarification: boolean;
}

export const CoopPreApprovalComplianceForm = (props: CoopPreApprovalComplianceFormProps) => 
{
    const { form, task, onSave, allowDealerClarification } = props;

    const s: React.CSSProperties = Object.assign({}, FormGridStyles, {
        gridTemplateAreas: `
            'assessmentHeader assessmentHeader assessmentHeader'
            'assessmentQ1 assessmentQ1 assessmentQ1'
            'assessmentQ2 assessmentQ2 assessmentQ2'
            'assessmentQ3 assessmentQ3 assessmentQ3'
            'assessmentQ4 assessmentQ4 assessmentQ4'
            'assessmentQ5 assessmentQ5 assessmentQ5'
            'analysisHeader analysisHeader analysisHeader'
            'analysisQ1 analysisQ1 analysisQ1'
            'analysisQ2 analysisQ2 analysisQ2'
            'analysisQ3 analysisQ3 analysisQ3'
            'analysisQ4 analysisQ4 analysisQ4'
            'conclusion conclusion conclusion'
            'certificationForm certificationForm certificationForm'
        `
    });

    const onValidate = useCallback(validateWhen(Yup.object({
        collectedVPICoopPreApproval: Yup.boolean().required('This field is required'),
        collectedVPICoopPreApprovalComment: Yup.string().max(2000, 'Notes cannot be longer than 2,000 characters'),
        collectedAgenda: Yup.boolean().required('This field is required'),
        collectedAgendaComment: Yup.string().max(2000, 'Notes cannot be longer than 2,000 characters'),
        collectedCopyOfMaterial: Yup.boolean().required('This field is required'),
        collectedCopyOfMaterialComment: Yup.string().max(2000, 'Notes cannot be longer than 2,000 characters'),
        collectedBudgetCosts: Yup.boolean().required('This field is required'),
        collectedBudgetCostsComment: Yup.string().max(2000, 'Notes cannot be longer than 2,000 characters'),
        collectedAttendanceInformation: Yup.boolean().required('This field is required'),
        collectedAttendanceInformationComment: Yup.string().max(2000, 'Notes cannot be longer than 2,000 characters'),
        budgetedCost: Yup.number().required('Budgeted Cost is required').positive('Budgeted Cost must be greater than 0').integer(),
        estimatedAttendance: Yup.number().integer('Must be a whole number').moreThan(-1, 'Number of participants cannot be negative').required('Please enter the number of participants'),
        reasonabilityFinancial: Yup.string().required('This field is required'),
        reasonabilityEventClientAppreciation: Yup.string().required('This field is required'),
        reasonabilityInvestorsInformedInWriting: Yup.string().required('This field is required'),
        reasonabilityPrimaryPurposeTestMet: Yup.string().required('This field is required'),
        expensesConclusion: Yup.string().required('This field is required'),
        comments: Yup.string().optional().max(2000, 'Comments cannot be longer than 2,000 characters'),
        action: Yup.string()
    }), values => values.action === 'approve'), []);

    const initialValues = copyInto({
        collectedVPICoopPreApproval: '',
        collectedVPICoopPreApprovalComment: '',
        collectedAgenda: '',
        collectedAgendaComment: '',
        collectedCopyOfMaterial: '',
        collectedCopyOfMaterialComment: '',
        collectedBudgetCosts: '',
        collectedBudgetCostsComment: '',
        collectedAttendanceInformation: '',
        collectedAttendanceInformationComment: '',
        budgetedCost: 0,
        estimatedAttendance: 0,
        reasonabilityFinancial: '',
        reasonabilityEventClientAppreciation: '',
        reasonabilityInvestorsInformedInWriting: '',
        reasonabilityPrimaryPurposeTestMet: '',
        expensesConclusion: '',
        comments: '',
        action: ''
    }, { 
        ...task, 
        budgetedCost: task.budgetedCost || form.eventEstimatedTotalCost,
        estimatedAttendance: task.estimatedAttendance || form.attendanceClients + form.attendanceEmployees
    });

    const onSubmit = async (values: TaskCoopPreApproval & {[key: string]: any}, actions: FormikHelpers<any>) =>
    {
        const { action, comments, ...payload } = values;

        try
        {
            let outcome = TaskOutcome.Approved;

            switch(action)
            {
                case 'approve': outcome = TaskOutcome.Approved; break;
                case 'reject': outcome = TaskOutcome.Rejected; break;
                case 'advisor-clarification': outcome = TaskOutcome.AdvisorClarification; break;
                case 'advisor-revision': outcome = TaskOutcome.AdvisorRevision; break;
                case 'dealer-clarification': outcome = TaskOutcome.DealerClarification; break;
            }

            await UpdateTask(form.type, form.id, task.id, payload);
            await handleSelectedOutcome(form, task, outcome, comments);

            onSave?.();
        }
        catch(e: any)
        {
            console.error(e);
            alert(e.message);
        }
    };

    return (
        <>
        <CardHeader>Internal Checklist - Co-operative Marketing Arrangements</CardHeader>
        <CardContent>
            <Formik 
                initialValues={initialValues} 
                onSubmit={onSubmit}
                validate={onValidate}
                enableReinitialize>
                { formProps => (
                    <Form style={s}>

                        <FormSectionHeader gridArea="assessmentHeader">
                            <h3>Assessment</h3>
                            <p>Identify the items collected to complete initial assessment and note reasons for items not collected.</p>
                        </FormSectionHeader>
                        
                        <div style={{ gridArea: 'assessmentQ1' }}>
                            <p>1. VPIs Pre-Approval Form for Co-operative Marketing Request</p>
                            <VPIChoiceField name="collectedVPICoopPreApproval" label="Collected" required direction="horizontal" options={[
                                { key: true, text: 'Yes' },
                                { key: false, text: 'No' }
                            ]} />
                            <VPITextField name="collectedVPICoopPreApprovalComment" label="Notes" multiline />
                        </div>

                        <div style={{ gridArea: 'assessmentQ2' }}>
                            <p>2. Agenda for the event highlighting topics and the time allocation.</p>
                            <VPIChoiceField name="collectedAgenda" label="Collected" required direction="horizontal" options={[
                                { key: true, text: 'Yes' },
                                { key: false, text: 'No' }
                            ]} />
                            <VPITextField name="collectedAgendaComment" label="Notes" multiline />
                        </div>

                        <div style={{ gridArea: 'assessmentQ3' }}>
                            <p>3. Copy of material (presentation, notes, etc.) to be discussed at the event.</p>
                            <p>For events where no printed or electronic materials are discussed, confirm the discussion topics via email from the dealer.</p>
                            <VPIChoiceField name="collectedCopyOfMaterial" label="Collected" required direction="horizontal" options={[
                                { key: true, text: 'Yes' },
                                { key: false, text: 'No' }
                            ]} />
                            <VPITextField name="collectedCopyOfMaterialComment" label="Notes" multiline />
                        </div>

                        <div style={{ gridArea: 'assessmentQ4' }}>
                            <p>4. Budgeted Costs (usually recorded in Pre-Approval Form for Co-operative Marketing Request).</p>
                            <VPIChoiceField name="collectedBudgetCosts" label="Collected" required direction="horizontal" options={[
                                { key: true, text: 'Yes' },
                                { key: false, text: 'No' }
                            ]} />
                            <VPITextField name="collectedBudgetCostsComment" label="Notes" multiline />
                        </div>

                        <div style={{ gridArea: 'assessmentQ5' }}>
                            <p>5. Attendance Information (both employees and clients)</p>
                            <VPIChoiceField name="collectedAttendanceInformation" label="Collected" required direction="horizontal" options={[
                                { key: true, text: 'Yes' },
                                { key: false, text: 'No' }
                            ]} />
                            <VPITextField name="collectedAttendanceInformationComment" label="Notes" multiline />
                        </div>

                        <FormSectionHeader gridArea="analysisHeader">
                            <h3>Qualitative & Quantitative Analysis:</h3>
                            <p>Considering the agenda and the activities planned for this event the event is being treated as Co-operative Marketing Event and consequently will be assessed under section 5.1 of NI 81-105 and VPI Compliance Policies (Section 3.1 of Part III of Sales and Marketing Policy), to determine the eligibility of proposed expenses.</p>
                        </FormSectionHeader>

                        <div style={{ gridArea: 'analysisQ1' }}>
                            <div style={{ display: 'flex', gap: '15px', marginBottom: '15px' }}>
                                <VPICurrencyField name="budgetedCost" label="Budgeted Cost" style={{ flex: 1 }} required />
                                <VPITextField type="number" minimum={0} name="estimatedAttendance" label="Number of Participants" style={{ flex: 1 }} required />
                                <VPIDisplayTextBox label="Cost Per Person" value={formProps.values.estimatedAttendance > 0 ? currencyToString(formProps.values.budgetedCost / formProps.values.estimatedAttendance) : 'N/A'} />

                            </div>
                            <VPIChoiceField name="reasonabilityFinancial" label="1. Financial Reasonability" required direction="horizontal" options={[
                                { key: true, text: 'Reasonable' },
                                { key: false, text: 'Not Reasonable' }
                            ]} />
                        </div>

                        <div style={{ gridArea: 'analysisQ2' }}>
                            <VPIChoiceField name="reasonabilityEventClientAppreciation" label="2. Is event a Client Appreciation Event" required direction="horizontal" options={[
                                { key: true, text: 'Yes' },
                                { key: false, text: 'No' }
                            ]} />
                        </div>

                        <div style={{ gridArea: 'analysisQ3' }}>
                            <VPIChoiceField name="reasonabilityInvestorsInformedInWriting" label="3. Are Investors informed in WRITING of the Sponsorship" required direction="horizontal" options={[
                                { key: true, text: 'Yes' },
                                { key: false, text: 'No' }
                            ]} />
                        </div>

                        <div style={{ gridArea: 'analysisQ4' }}>
                            <VPIChoiceField name="reasonabilityPrimaryPurposeTestMet" label="4. Is the Primary Purpose Test Met" required direction="horizontal" options={[
                                { key: true, text: 'Yes' },
                                { key: false, text: 'No' }
                            ]} />
                        </div>

                        <div style={{ gridArea: 'conclusion' }}>
                            <hr />
                            <h3>Conclusion</h3>
                            <VPIChoiceField name="expensesConclusion" label="As noted above, the expenses appear:" required direction="horizontal" options={[
                                { key: 'Reasonable', text: 'Reasonable' },
                                { key: 'Not Reasonable', text: 'Not Reasonable' }
                            ]} />
                            <p>VPI will make direct payment to dealer for 50% of the direct costs. Copy of invoices reflecting direct costs incurred will be attached to this assessment memo, once received.</p>
                        </div>
                        
                        <CertificationForm formProps={formProps} allowDealerClarification={allowDealerClarification} />
                    </Form>
                )}
            </Formik>
        </CardContent>
        </>
    );
};

const CertificationForm = (props: { formProps: FormikProps<FormCoopPreApproval>, allowDealerClarification: boolean }) =>
{
    const { formProps, allowDealerClarification } = props;
    const formikContext = useFormikContext();
    const { user } = useUserContext();
    const advisorDialog = useToggle(false);

    return (
        <div style={{ gridArea: 'certificationForm' }}>
            <hr />

            <h4>Certification</h4>
            <p>The undersigned has reviewed the above request and authorizes the request.</p>

            <VPIDisplayTextBox label="VPI Compliance Name/Signature" value={user?.name} />
            <VPIDisplayTextBox label="Date" value={format(new Date(), 'PP')} />
            <VPITextField name="comments" label="Comments" multiline />

            <div style={{ display: 'flex', gap: '10px', justifyContent: 'flex-end', marginTop: '15px' }}>
                <DefaultButton text="Advisor Clarification..." disabled={formikContext.isSubmitting} onClick={() => {
                    advisorDialog.open();
                }} />
                {
                    allowDealerClarification &&
                    <DefaultButton text="LP Dealer Clarification" disabled={formikContext.isSubmitting} onClick={() => {
                        formProps.setFieldValue('action', 'dealer-clarification');
                        setTimeout(() => formProps.submitForm());
                    }} />
                }
            </div>
            <hr />
            <div style={{ display: 'flex', gap: '10px', justifyContent: 'flex-end' }}>
                <DefaultButton text="Reject" disabled={formikContext.isSubmitting} onClick={() => {
                    formProps.setFieldValue('action', 'reject');
                    // Form fails validation if submitted immediately after setting value
                    setTimeout(() => formProps.submitForm());
                }}  />
                <PrimaryButton text="Approve" disabled={formikContext.isSubmitting} onClick={() => {
                    formProps.setFieldValue('action', 'approve');
                    // Form fails validation if submitted immediately after setting value
                    setTimeout(() => formProps.submitForm());
                }} />
            </div>
            <AdvisorClarificationDialog open={advisorDialog.isOpen} onDismiss={advisorDialog.close} onSelect={async (action) => {
                formProps.setFieldValue('action', action === 'clarification' ? 'advisor-clarification' : 'advisor-revision');
                return new Promise((resolve) => {
                    setTimeout(() => formProps.submitForm().then(resolve));
                });
            }} />
        </div>
    );
};
