import { Form, Formik } from "formik";
import { format, isDate } from "date-fns";
import { CSSProperties } from "react";
import { useQuery } from "react-query";
import { FormSectionHeader } from "../../components/FormSectionHeader";
import { GetUser } from "../../data/queries/UserQueries";
import { QueryKeys } from "../../data/QueryKeys";
import { VPIDisplayField } from "../../fields/VPIDisplayField";
import { FormVpicPreApproval } from "../../data/models/FormVpicPreApproval";
import { VPIDisplayTextBox } from "../../fields/VPIDisplayTextBox";
import { currencyToString } from "../../utilities/FormUtils";
import { FormVpicReimbursement } from "../../data/models/FormVpicReimbursement";
import { VPIAttachmentsDisplayField } from "../../fields/VPIAttachmentsDisplayField";
import { FormStatusBar } from "../../components/FormStatusBar";
import { AppRoutes } from "../../constants/AppRoutes";
import { FontIcon } from "@fluentui/react";
import { DetailsGridStyles2Col, DetailsGridStyles3Col } from "../../constants/Constants";

export interface VPICReimbursementDetailsProps
{
    form: FormVpicReimbursement;
    preApprovalForm?: FormVpicPreApproval;
    variant?: '3column' | '2column';
}

export const VPICReimbursementDetails = (props: VPICReimbursementDetailsProps) =>
{
    const { form, preApprovalForm, variant = '3column' } = props;
    const initialValues = form ?? {};
    const userQuery = useQuery(QueryKeys.User.Id(form.advisorUserId), () => GetUser(form.advisorUserId), { enabled: !!form.advisorUserId });

    let s: CSSProperties = Object.assign({}, DetailsGridStyles3Col, {
        gridTemplateAreas: `
            'preApprovalFormId preApprovalFormId .'
            'date telephone .'
            'advisorUserId . .'
            'city province .'
            'expenseType . .'
            
            'attachments attachments .'
            'attendanceHeader attendanceHeader attendanceHeader'
            'attendanceClients attendanceEmployees .'
            'attendanceAnalysisHeader attendanceAnalysisHeader attendanceAnalysisHeader'
            'estimatedTotalAttendance totalAttendance attendanceVariance'
            'estimatedCost totalCost costVariance'
            'estimatedCostPerPerson costPerPerson costPerPersonVariance'
        `
    });

    if(variant === '2column')
    {
        s = Object.assign({}, DetailsGridStyles2Col, {
            gridTemplateAreas: `
                'preApprovalFormId preApprovalFormId'
                'date telephone'
                'advisorUserId advisorUserId'
                'city province'
                'expenseType expenseType'
                
                'attachments attachments'
                'attendanceHeader attendanceHeader'
                'attendanceClients attendanceEmployees'
                'attendanceAnalysisHeader attendanceAnalysisHeader'
                'estimatedTotalAttendance totalAttendance'
                'attendanceVariance .'
                'estimatedCost totalCost'
                'costVariance .'
                'estimatedCostPerPerson costPerPerson'
                'costPerPersonVariance .'
            `
        });
    }

    return (
        <>
        <FormStatusBar status={form?.formStatus} />
        <Formik
            initialValues={initialValues}
            onSubmit={() => {}}
            enableReinitialize>
            { formProps => (
                <Form style={s}>
                    <VPIDisplayField name="preApprovalFormId" label="Related Pre-Approval" render={() => {
                        if(preApprovalForm)
                            return <a target="_blank" href={AppRoutes.byFormType(preApprovalForm.type, preApprovalForm.id)}>{preApprovalForm.formId} <FontIcon iconName="NavigateExternalInline" /></a>;
                        else
                            return 'N/A';
                    }} />
                    <VPIDisplayField name="date" label="Date" render={(date: Date) => isDate(date) ? format(date, 'PP') : '(No date set)' } />
                    <VPIDisplayField name="telephone" label="Telephone" render={() => userQuery.data?.telephone || ''} />
                    <VPIDisplayField name="advisorUserId" label="Advisor Name" render={() => userQuery.data?.name || ''} />
                    <VPIDisplayField name="city" label="City" render={() => userQuery.data?.city || ''} />
                    <VPIDisplayField name="province" label="Province" render={() => userQuery.data?.province || ''} />
                    <VPIDisplayField name="expenseType" label="Expense Type" />
                    
                    {/* <FormSectionHeader gridArea="expenseDetailsHeader">
                        <h3>Expense Details</h3>
                    </FormSectionHeader> */}
                    <VPIAttachmentsDisplayField name="attachments" label="Expense Details" />

                    <FormSectionHeader gridArea="attendanceHeader">
                        <h3>Event Attendance (if applicable)</h3>
                    </FormSectionHeader>
                    <VPIDisplayField name="attendanceClients" label="Clients" />
                    <VPIDisplayField name="attendanceEmployees" label="Employees" />

                    <FormSectionHeader gridArea="attendanceAnalysisHeader">
                        <h3>Attendance Information Analysis</h3>
                    </FormSectionHeader>       
                    <VPIDisplayField name="estimatedTotalAttendance" label="Total Attendance (Estimate)" render={() => (
                        (preApprovalForm ? (preApprovalForm.attendanceClients + preApprovalForm.attendanceEmployees) : 'N/A').toString()
                    )} />
                    <VPIDisplayField name="totalAttendance" label="Total Attendance (Actual)" render={() => (
                        (form.attendanceClients + form.attendanceEmployees).toString()
                    )} />
                    <VPIDisplayField name="attendanceVariance" label="Variance" render={() => (
                        (preApprovalForm ? (form.attendanceClients + form.attendanceEmployees) - (preApprovalForm.attendanceClients + preApprovalForm.attendanceEmployees) : 'N/A').toString()
                    )} />

                    <VPIDisplayField name="estimatedCost" label="Total Cost (Estimate)" render={() => (
                        preApprovalForm ? currencyToString(preApprovalForm.expenseEstimatedTotalCost) : 'N/A'
                    )} />
                    <VPIDisplayField name="totalCost" label="Total Cost (Actual)" render={() => (
                        currencyToString(form.amountRequested)
                    )} />
                    <VPIDisplayField name="costVariance" label="Variance" render={() => (
                        preApprovalForm ? currencyToString(form.amountRequested - preApprovalForm.expenseEstimatedTotalCost) : 'N/A'
                    )} />

                    <VPIDisplayField name="estimatedCostPerPerson" label="Cost Per Person (Estimate)" render={() => (
                        preApprovalForm && (preApprovalForm.attendanceClients + preApprovalForm.attendanceEmployees) > 0 ?  
                        currencyToString(preApprovalForm.expenseEstimatedTotalCost / (preApprovalForm.attendanceClients + preApprovalForm.attendanceEmployees)) :
                        'N/A'
                    )} />
                    <VPIDisplayField name="costPerPerson" label="Cost Per Person (Actual)" render={() => (
                        (form.attendanceClients + form.attendanceEmployees) > 0 ? currencyToString(formProps.values.amountRequested / (form.attendanceClients + form.attendanceEmployees)) : 'N/A'
                    )} />
                    <VPIDisplayField name="costPerPersonVariance" label="Variance" render={() => (
                        preApprovalForm 
                            && (preApprovalForm.attendanceClients + preApprovalForm.attendanceEmployees) > 0 
                            && (form.attendanceClients + form.attendanceEmployees) > 0
                        ? 
                            currencyToString(
                                (formProps.values.amountRequested / (form.attendanceClients + form.attendanceEmployees)) -
                                (preApprovalForm.expenseEstimatedTotalCost / (preApprovalForm.attendanceClients + preApprovalForm.attendanceEmployees))
                            )
                        : 'N/A'
                    )} />
                </Form>
            )}
        </Formik>
        </>
    )
}
