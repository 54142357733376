import React, { useCallback, useEffect } from "react";
import * as Yup from "yup";
import { Formik, Form, FormikHelpers, FormikProps } from "formik";
import { VPITextField } from "../../fields/VPITextField";
import { VPIDropdownField } from "../../fields/VPIDropdownField";
import { VPIDateField } from "../../fields/VPIDateField";
import { VPICheckboxField } from "../../fields/VPICheckboxField";
import { FormCoopPreApproval } from "../../data/models/FormCoopPreApproval";
import { attachmentsToAttachmentItems, copyInto, validateWhen } from "../../utilities/FormUtils";
import { VPICurrencyField } from "../../fields/VPICurrencyField";
import { DefaultButton, PrimaryButton, TooltipDelay, TooltipHost } from "@fluentui/react";
import { useId } from "@fluentui/react-hooks";
import { FormStatus } from "../../data/models/Form";
import { useUserContext } from "../../hooks/useUserContext";
import { hasPermission, hasProcessFlow, hasRole, User, UserRole } from "../../data/models/User";
import { QueryKeys } from "../../data/QueryKeys";
import { GetAdvisors } from "../../data/queries/UserQueries";
import { useQuery } from "react-query";
import { FormShimmer } from "../../helpers/Shimmer";
import { format, sub } from "date-fns";
import { Constants, FormGridStyles } from "../../constants/Constants";
import { FormSectionHeader } from "../../components/FormSectionHeader";
import { VPIAttachmentField, VPIFieldAttachment } from "../../fields/VPIAttachmentField";

import "./PrintStyles.scss";
import { Flex } from "../../components/Flex";
import { VPIDisplayTextBox } from "../../fields/VPIDisplayTextBox";

export interface CoopPreApprovalFormProps
{
    form?: FormCoopPreApproval;
    onSave?: (data: Partial<FormCoopPreApproval>, action: 'save-draft' | 'submit') => void | Promise<void>;
    allowComments?: boolean;
}

export const CoopPreApprovalForm = (props: CoopPreApprovalFormProps) => 
{
    const { form = null, onSave, allowComments = false } = props;
    const usersQuery = useQuery(QueryKeys.User.All(), () => GetAdvisors());
    const isLoading = usersQuery.isFetching;

    const s: React.CSSProperties = Object.assign({}, FormGridStyles, {
        gridTemplateAreas: `
            'advisorUserId advisorUserId advisorUserId'
            'date repCode telephone'
            'fax city province'
            'postalCode . .'
            'eventDetailsHeader eventDetailsHeader eventDetailsHeader'
            'eventType eventType eventLocation'
            'eventDate eventDate .'
            'eventEstimatedTotalCost eventAmountRequested .'
            'attendanceHeader attendanceHeader attendanceHeader'
            'attendanceClients attendanceEmployees .'
            'fundCompaniesHeader fundCompaniesHeader fundCompaniesHeader'
            'fundCompanies fundCompanies fundCompanies'
            'companiesAmountRequested companiesAmountRequested .'
            'sponsorshipHeader sponsorshipHeader sponsorshipHeader'
            'indicatedClientInvitation indicatedClientInvitation indicatedClientInvitation'
            'otherIndicatedClientInvitation otherIndicatedClientInvitation .'
            'attachmentsDivider attachmentsDivider attachmentsDivider'
            'attachments attachments .'
            'attachmentsNotes attachmentsNotes attachmentsNotes'
            'certificationForm certificationForm certificationForm'
        ` 
    });

    const onValidate = useCallback(validateWhen(Yup.object({
        formStatus: Yup.string().required(),
        advisorUserId: Yup.string().required('Please select an advisor'),
        date: Yup.date().required(),
        repCode: Yup.string(),

        eventType: Yup.string().required('Please enter an event type'),
        eventLocation: Yup.string().required('Please enter a location'),
        eventDate: Yup.date().min(sub(new Date(), { days: 1 }), 'Event Date must be in the future').required('Event Date is required'),
        eventEstimatedTotalCost: Yup.number().required('Estimated Cost is required').positive('Amount must be greater than 0').integer(),
        eventAmountRequested: Yup.number().required('Amount Requested is required').positive('Amount must be greater than 0').integer(),
        eventChequePayableTo: Yup.string(),

        attendanceClients: Yup.number().integer('Must be a whole number').moreThan(-1, 'Number of clients cannot be negative').required('Please enter the number of clients'),
        attendanceEmployees: Yup.number().integer('Must be a whole number').moreThan(-1, 'Number of employees cannot be negative').required('Please enter the number of employees'),

        eventDescription: Yup.string().max(2000, 'Event Description cannot be more than 2,000 characters'),
        attachments: Yup.array().ensure()
            .of(Yup.mixed().test('size', 'The file is too large', (value: VPIFieldAttachment) => value.size <= Constants.MAX_FILE_SIZE))
            .of(Yup.mixed().test('inProgress', 'Please wait for all files to finish uploading', (value: VPIFieldAttachment) => value.inProgress === false))
            .min(1, 'Please attach at least 1 file')
            .required('Please attach at least 1 file'),

        fundCompanies: Yup.string().max(2000, 'Fund Companies cannot be more than 2,000 characters'),
        companiesAmountRequested: Yup.number(),
        indicatedClientInvitation: Yup.boolean(),
        otherIndicatedClientInvitation: Yup.string().max(2000, 'Other cannot be more than 2,000 characters'),
        
        comments: Yup.string().max(2000, 'Comments cannot be more than 2,000 characters'),
        action: Yup.string().required()
    }), values => values.action !== 'save-draft'), []);

    const initialValues = copyInto({
        formStatus: FormStatus.Draft,
        advisorUserId: form?.advisorUserId,
        date: new Date(),
        repCode: '',
        eventType: '',
        eventDate: new Date(),
        eventLocation: '',
        eventEstimatedTotalCost: 0,
        eventAmountRequested: 0,
        eventChequePayableTo: '',
        attendanceClients: 0,
        attendanceEmployees: 0,
        attachments: [],
        eventDescription: '',
        fundCompanies: '',
        companiesAmountRequested: 0,
        indicatedClientInvitation: false,
        otherIndicatedClientInvitation: '',
        dealerName: '',
        externalDealer: false,
        dealerForm: [],
        comments: '', // Only visible when sent back for advisor revision
        action: ''
    }, form);
    initialValues.attachments = attachmentsToAttachmentItems(form?.attachments);

    const onSubmit = useCallback(async (values: any, actions: FormikHelpers<any>) => 
    {
        const { action, ...formData } = values;
        await onSave?.(formData, action);
    }, [form, onSave]);

    const advisorOptions = (usersQuery.data ?? [])
        .filter(user => user.role === UserRole.Advisor && hasProcessFlow(user, 'Co-op'))
        .map(user => { return { key: user.id, text: `${user.name} (${user.accountName})` } });
    const getAdvisor = (advisorUserId: string) => usersQuery.data?.find(u => u.id === advisorUserId);

    return (
        <>
        <div className="form-header">
            <div className="form-logo">
                <img src="/images/vpi-logo.png" />
            </div>
            <div className="form-title">
                <div className="form-title-text">Co-op Marketing Request</div>
                <div className="form-title-subtext">Pre-Approval Form</div>
            </div>
        </div>
        <hr className="print-only" />
        {
            !isLoading && 
            <Formik 
                initialValues={initialValues} 
                onSubmit={onSubmit}
                validate={onValidate}>
                { formProps => {
                    const advisor = getAdvisor(formProps.values.advisorUserId);

                    // eslint-disable-next-line
                    useEffect(() => {
                        formProps.setFieldValue('externalDealer', advisor?.dealerType === 'External');
                    }, [formProps.values.advisorUserId]);

                    return (
                    <Form style={s}>
                        <VPIDropdownField name="advisorUserId" label="Advisor" options={advisorOptions} required placeholder="Select..." noItemsLabel="No advisors found" />
                        <VPIDateField name="date" label="Date" />
                        <VPITextField name="repCode" label="Rep Code" />

                        <VPIDisplayTextBox name="telephone" label="Telephone" value={advisor?.telephone} />
                        <VPIDisplayTextBox name="fax" label="Fax" value={advisor?.fax} />
                        <VPIDisplayTextBox name="city" label="City" value={advisor?.city} />
                        <VPIDisplayTextBox name="province" label="Province" value={advisor?.province} />
                        <VPIDisplayTextBox name="postalCode" label="Postal Code" value={advisor?.postalCode} />

                        <FormSectionHeader gridArea="eventDetailsHeader">
                            <h3>Event Details</h3>
                        </FormSectionHeader>

                        <VPITextField name="eventType" label="Event Type" required placeholder="Enter event" description="Client appreciation events do not qualify as co-op events. Please see instructions for details." />
                        <VPITextField name="eventLocation" label="Location" required placeholder="Enter location" />
                        <VPIDateField name="eventDate" label="Event Date" includeTime />

                        <VPICurrencyField name="eventEstimatedTotalCost" label="Estimated Cost" required placeholder="Enter estimate" />
                        <VPICurrencyField name="eventAmountRequested" label="Amount Requested" required placeholder="Enter requested amount" />
                        {/* <VPITextField name="eventChequePayableTo" label="Cheque Payable To..." placeholder="Enter cheque information" /> */}

                        <FormSectionHeader gridArea="attendanceHeader"><h3>Attendance Information (Estimate)</h3></FormSectionHeader>
                        <VPITextField name="attendanceClients" label="Clients" type="number" required placeholder="Enter clients" minimum={0} />
                        <VPITextField name="attendanceEmployees" label="Employees" type="number" required placeholder="Enter employees" minimum={0} />
                        
                        {/* <FormSectionHeader gridArea="eventDescriptionHeader">
                            <h3>Event Description</h3>
                            <p>Please provide a copy of the agenda.</p>
                        </FormSectionHeader> */}
                        
                        {/* <p style={{ gridArea: 'eventDescriptionNote' }}>* Agenda should list activities planned in the event along with time allocated for each activity. Also please provide a copy of presentation(s) and details of topics being discussed in the event.</p> */}
                        {/* <VPITextField name="eventDescription" multiline placeholder="Enter description" label="Description" rows={4} /> */}

                        <FormSectionHeader gridArea="fundCompaniesHeader">
                            {/* <h3>Fund Companies</h3> */}
                        </FormSectionHeader>
                        <VPITextField name="fundCompanies" label="Fund Companies Approached" placeholder="Enter fund companies" multiline rows={4} />
                        <VPICurrencyField name="companiesAmountRequested" label="Amount Requested ($) From All Fund Companies" placeholder="Enter amount" />
                        
                        <FormSectionHeader gridArea="sponsorshipHeader">
                            <h3>VPI Sponsorship Declaration To Clients</h3>
                        </FormSectionHeader>
                        <VPICheckboxField name="indicatedClientInvitation" label="We have indicated on the client invitation: 'This event is sponsored in part by Value Partners Investments' (if so, please include a copy of the client invitation)" required />
                        <VPITextField name="otherIndicatedClientInvitation" label="Other means by which clients have been informed of VPI Sponsorship" placeholder="Other" multiline />

                        <hr style={{ gridArea:'attachmentsDivider' }} />

                        <VPIAttachmentField name="attachments" multiple buttonLabel="Attach Documents" required formId={form?.id} />
                        <p style={{ gridArea: 'attachmentsNotes' }}>
                            Please attach:
                            <ol>
                                <li>Agenda</li>
                                <li>Budget</li>
                                <li>Invitation to clients</li>
                                <li>Presentation materials</li>
                            </ol>
                            Agenda should list activities planned in the event along with time allocated for each activity and topics being discussed in the event
                        </p>

                        {/* <p style={{ gridArea: 'attachmentsNotes' }}>Please attach: (1) Agenda; (2) Budget; (3) Invitation to clients; (4) Presentation materials. Agenda should list activities planned in the event along with time allocated for each activity and topics being discussed in the event.</p> */}

                        {
                            advisor?.dealerType === 'LP' &&
                            <LPDealerCertificationForm formProps={formProps} advisor={advisor} allowComments={allowComments} />
                        }
                        {
                            advisor?.dealerType?.length! > 0 &&
                            advisor?.dealerType !== 'LP' &&
                            <ExternalDealerCertificationForm formProps={formProps} advisor={advisor} allowComments={allowComments} formId={form?.id} />
                        }
                        {
                            !formProps.values.advisorUserId &&
                            <div style={{ gridArea: 'certificationForm', textAlign: 'right' }}>
                                <hr />
                                <p>Please select an advisor to continue</p>
                            </div>
                        }
                    </Form>);
                }}
            </Formik>            
        }
        { isLoading && <FormShimmer /> }
        </>
    );
};

const LPDealerCertificationForm = (props: { formProps: FormikProps<any>, advisor?: User, allowComments: boolean }) =>
{
    const { formProps, advisor, allowComments } = props;
    const { user } = useUserContext();
    const canSubmit = hasPermission(user, 'coop.submit');
    const submitToCompliance = hasRole(user, UserRole.Compliance, UserRole.Finance);
    const tooltipId = useId('tooltip');

    return (
        <div className="certification-form" style={{ gridArea: 'certificationForm' }}>
            <hr />

            <h3>Certification</h3>
            <p>We hereby certify that the expenses noted above are eligible for reimbursement under the standards outlined in National Instrument 81-105, Mutual Fund Sales Practices (&quot;The National Instrument&quot;). We further certify that the amounts claimed from all companies, in total, will not exceed limits outlined in the National Instrument.</p>

            <Flex gap="15px" align="normal" justify="normal" column>
                <VPIDisplayTextBox name="certificationName" label="Advisor Name/Signature" value={advisor?.name} />
                <VPIDisplayTextBox name="certificationDate" label="Date" value={format(new Date(), 'PP')} />
                {
                    allowComments &&
                    <VPITextField name="comments" label="Comments" description="Please include any changes you have made to your request" disabled={formProps.isSubmitting} multiline rows={4} />
                }
            </Flex>
            <hr />
            <div style={{ display: 'flex', gap: '10px', justifyContent: 'flex-end' }}>
                <DefaultButton text="Save as Draft" disabled={formProps.isSubmitting} onClick={() => {
                    formProps.setFieldValue('dealerName', 'LP Financial Planning Services Ltd');
                    formProps.setFieldValue('action', 'save-draft');
                    // Form fails validation if submitted immediately after setting value
                    setTimeout(() => formProps.submitForm());
                }}  />
                <TooltipHost
                    content={canSubmit ? '' : 'You must be an advisor to submit'}
                    delay={TooltipDelay.zero}
                    id={tooltipId}>
                    <PrimaryButton aria-describedby={tooltipId} text={submitToCompliance ? 'Send to VPI Compliance' : 'Send to LP Dealer'} disabled={formProps.isSubmitting || !canSubmit} onClick={() => {
                        formProps.setFieldValue('dealerName', 'LP Financial Planning Services Ltd');
                        formProps.setFieldValue('action', 'submit');
                        // Form fails validation if submitted immediately after setting value
                        setTimeout(() => formProps.submitForm());
                    }} />
                </TooltipHost>
            </div>
        </div>
    );
};

const ExternalDealerCertificationForm = (props: { formProps: FormikProps<any>, advisor?: User, allowComments: boolean, formId: string | undefined }) =>
{
    const { formProps, advisor, allowComments, formId } = props;
    const { user } = useUserContext();
    const canSubmit = hasPermission(user, 'coop.submit') && formProps.values.dealerForm?.length > 0;
    const tooltipId = useId('tooltip');

    let tooltipMessage = '';
    if(!hasPermission(user, 'coop.submit'))
        tooltipMessage = 'You must be an advisor to submit';
    else if(!(formProps.values.dealerForm?.length > 0))
        tooltipMessage = 'Please attach a signed copy of the form to continue';

    return (
        <div className="certification-form" style={{ gridArea: 'certificationForm' }}>
            <hr />
            <h3>Certification</h3>
            <p>We hereby certify that the expenses noted above are eligible for reimbursement under the standards outlined in National Instrument 81-105, Mutual Fund Sales Practices (&quot;The National Instrument&quot;). We further certify that the amounts claimed from all companies, in total, will not exceed limits outlined in the National Instrument.</p>
            <p>Before signing, enter the name of the dealer who will be reviewing this form.</p>

            <Flex gap="15px" align="normal" justify="normal" column>
                <VPIDisplayTextBox name="certificationName" label="Advisor Name/Signature" value={advisor?.name} />
                <VPIDisplayTextBox name="certificationDate" label="Date" value={format(new Date(), 'PP')} />
                <VPIDisplayTextBox name="dealerName" label="Dealer Name/Signature" value="" />
                <VPIDisplayTextBox name="dealerDate" label="Date" value="" />
            </Flex>

            <div className="hide-print">
                <hr />
                <h3>Send to External Dealer</h3>
                <p>For External Dealer approval, please print and sign this approval form then attach using the button below.</p>
                <Flex gap="15px">
                    <DefaultButton text="Print Approval Form" iconProps={{ iconName: 'Print' }} onClick={() => window.print()} />
                </Flex>
                <Flex gap="15px" style={{ margin: '15px 0' }} column align="normal" justify="normal">
                    <VPIAttachmentField name="dealerForm" buttonLabel="Attach Signed Form" formId={formId} />
                    {
                        allowComments &&
                        <div className="hide-print">
                            <VPITextField name="comments" label="Comments" description="Please include any changes you have made to your request" disabled={formProps.isSubmitting} multiline rows={4} />
                        </div>
                    }
                </Flex>
                <Flex gap="10px" justify="flex-end">
                    <DefaultButton text="Save as Draft" disabled={formProps.isSubmitting} onClick={() => {
                        formProps.setFieldValue('action', 'save-draft');
                        setTimeout(() => formProps.submitForm());
                    }} />

                    <TooltipHost
                        content={tooltipMessage}
                        delay={TooltipDelay.zero}
                        id={tooltipId}>
                        <PrimaryButton text="Send to VPI Compliance" disabled={formProps.isSubmitting || !canSubmit} aria-describedby={tooltipId} onClick={() => {
                            formProps.setFieldValue('action', 'submit');
                            // Form fails validation if submitted immediately after setting value
                            setTimeout(() => formProps.submitForm());
                        }} />
                    </TooltipHost>
                </Flex>
            </div>
        </div>
    );
};
