import { Shimmer, ShimmerElementType } from "@fluentui/react";

export const FormCardShimmer = () => (
    <div style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '10px' }}>
        <Shimmer style={{ padding: '20px 20px 0 20px' }} shimmerElements={[{ type: ShimmerElementType.line, height: 18 }]} />
        <hr />
        <div style={{ padding: '0 20px 20px 20px' }}>
            <Shimmer />
            <Shimmer style={{ paddingBottom: '20px' }} />
            <Shimmer />
            <Shimmer style={{ paddingBottom: '20px' }} />
            <Shimmer />
            <Shimmer style={{ paddingBottom: '20px' }} />
        </div>
    </div>
);

export const FormShimmer = () => (
    <div style={{ padding: '5px 0 0 0' }}>
        <Shimmer />
        <Shimmer style={{ paddingBottom: '20px' }} />
        <Shimmer />
        <Shimmer style={{ paddingBottom: '20px' }} />
        <Shimmer />
        <Shimmer style={{ paddingBottom: '20px' }} />
    </div>
);

export const DetailsCardShimmer = () => (
    <div style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '10px' }}>
        <Shimmer />
        <Shimmer style={{ paddingBottom: '20px' }} />
        <Shimmer />
        <Shimmer style={{ paddingBottom: '20px' }} />
        <Shimmer />
        <Shimmer style={{ paddingBottom: '20px' }} />
    </div>
);