
import { FontIcon, Label } from "@fluentui/react";
import { useField } from "formik";
import { Flex } from "../components/Flex";
import { Attachment } from "../data/models/Attachment";
import { GetAttachmentContentUrl } from "../data/queries/AttachmentQueries";
import { bytesToString } from "../utilities/FileUtilities";

export interface VPIDisplayFieldProps
{
    name: string;
    label?: string;
}

export const VPIAttachmentsDisplayField = (props: VPIDisplayFieldProps) => 
{
    const { name, label } = props;
    const [ field, meta, helpers ] = useField<Attachment[]>(name);
    const value = Array.isArray(field.value) ? field.value : [];

    return (
        <div style={{ gridArea: name }}>
            <Label>{label}</Label>
            <div style={{ color: '#7C7C7C', display: 'flex', flexDirection: 'column', gap: '5px' }}>
                
                {value.map(attachment => (
                <Flex key={attachment.id} row style={{ width: '100%', padding: '10px', gap: '10px', alignItems: 'center', border: '1px solid #ddd', borderRadius: '5px' }}>
                    <FontIcon iconName="TextDocument" />
                    <div style={{ flex: 1 }}>
                        <a href={GetAttachmentContentUrl(attachment.id || '')} target="_blank">{attachment.filename || attachment.filename}</a>
                        <div style={{ fontSize: '12px', color: '#7D7D7D' }}>{bytesToString(attachment.size)}</div>
                    </div>
                </Flex>
                ))}
                {value.length === 0 &&
                    <div>(No attachments)</div>
                }
            </div>
        </div>
    );
};
