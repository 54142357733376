import React, { useCallback } from "react";
import * as Yup from "yup";
import { Formik, Form, FormikProps, FormikHelpers } from "formik";
import { FormVppwReimbursement } from "../../data/models/FormVppwReimbursement";
import { VPITextField } from "../../fields/VPITextField";
import { VPICurrencyField } from "../../fields/VPICurrencyField";
import { FormSectionHeader } from "../../components/FormSectionHeader";
import { attachmentsToAttachmentItems, copyInto, currencyToString, validateWhen } from "../../utilities/FormUtils";
import { DefaultButton, PrimaryButton, TooltipDelay, TooltipHost } from "@fluentui/react";
import { FormStatus } from "../../data/models/Form";
import { GetForm } from "../../data/queries/FormQueries";
import { useUserContext } from "../../hooks/useUserContext";
import { VPIChoiceField } from "../../fields/VPIChoiceField";
import { Constants, FormGridStyles } from "../../constants/Constants";
import { hasPermission, hasProcessFlow, hasRole, User, UserRole } from "../../data/models/User";
import { useId } from "@fluentui/react-hooks";
import { useQuery } from "react-query";
import { QueryKeys } from "../../data/QueryKeys";
import { GetAdvisors } from "../../data/queries/UserQueries";
import { FormShimmer } from "../../helpers/Shimmer";
import { format } from "date-fns";
import { useSearchParams } from "react-router-dom";
import { FormVppwPreApproval } from "../../data/models/FormVppwPreApproval";
import { VPIDropdownField } from "../../fields/VPIDropdownField";
import { VPIDisplayTextBox } from "../../fields/VPIDisplayTextBox";
import { VPIAttachmentField, VPIFieldAttachment } from "../../fields/VPIAttachmentField";
import { Flex } from "../../components/Flex";

export interface VPPWReimbursementFormProps
{
    form?: FormVppwReimbursement;
    onSave?: (formData: Partial<FormVppwPreApproval>, action: 'save-draft' | 'submit') => void | Promise<void>;
    allowComments?: boolean;
}

export const VPPWReimbursementForm = (props: VPPWReimbursementFormProps) => 
{
    const { form = null, onSave, allowComments = false } = props;
    const [searchParams] = useSearchParams();
    const preApprovalFormId = form?.preApprovalFormId || searchParams.get('p') || '';
    const preApprovalFormQuery = useQuery<FormVppwPreApproval>(QueryKeys.VPPWPreApproval.Form(preApprovalFormId), () => GetForm('vppw-preapproval', preApprovalFormId), { enabled: !!preApprovalFormId });
    const usersQuery = useQuery(QueryKeys.User.All(), () => GetAdvisors());
    const isLoaded = (preApprovalFormQuery.isSuccess || !preApprovalFormId) && usersQuery.isSuccess;

    const s: React.CSSProperties = Object.assign({}, FormGridStyles, {
        gridTemplateAreas: `
            'advisorUserId advisorUserId advisorUserId'
            'date advisorName repCode'
            'telephone city province'
            'email email .'
            'expenseType expenseType expenseType'
            'preApprovalAmount amountRequested .'
            'expenseDetailsHeader expenseDetailsHeader expenseDetailsHeader'
            'attachments attachments .'
            'attendanceInfoHeader attendanceInfoHeader attendanceInfoHeader'
            'attendanceClients attendanceEmployees .'
            'certificationForm certificationForm certificationForm'
        `
    });

    const onValidate = useCallback(validateWhen(Yup.object({
        formStatus: Yup.string().required(),
        advisorUserId: Yup.string().required('Please select an advisor'),
        preApprovalFormId: Yup.string(),
        
        expenseType: Yup.string().required('Please select an expense type'),
        amountRequested: Yup.number().positive('Amount must be greater than 0').integer().required('Amount is required'),

        attachments: Yup.array().ensure()
            .of(Yup.mixed().test('size', 'The file is too large', (value: VPIFieldAttachment) => value.size <= Constants.MAX_FILE_SIZE))
            .of(Yup.mixed().test('inProgress', 'Please wait for all files to finish uploading', (value: VPIFieldAttachment) => value.inProgress === false))
            .min(1, 'Please attach at least 1 file')
            .required('Please attach at least 1 file'),
        
        attendanceClients: Yup.number().integer('Must be a whole number').moreThan(-1, 'Number of clients cannot be negative').required('Please enter the number of clients'),
        attendanceEmployees: Yup.number().integer('Must be a whole number').moreThan(-1, 'Number of employees cannot be negative').required('Please enter the number of employees'),

        comments: Yup.string().max(2000, 'Comments cannot be more than 2,000 characters'),
        action: Yup.string().required()
    }), values => values.action !== 'save-draft'), []);

    const initialValues = copyInto({
        formStatus: FormStatus.Draft,
        preApprovalFormId,
        advisorUserId: preApprovalFormQuery.data?.advisorUserId || '',
        date: new Date(),

        expenseType: preApprovalFormQuery.data?.expenseType || '',
        amountRequested: 0,
        attachments: [],
        attendanceClients: 0,
        attendanceEmployees: 0,

        comments: '', // Only visible when sent back for advisor revision
        action: ''
    }, form);
    initialValues.attachments = attachmentsToAttachmentItems(form?.attachments);

    const onSubmit = useCallback(async (values: any, actions: FormikHelpers<any>) => 
    {
        const { action, ...formData } = values;
        await onSave?.(formData, action);
    }, [form, onSave]);
    
    const advisorOptions = (usersQuery.data ?? [])
        .filter(user => user.role === UserRole.Advisor && hasProcessFlow(user, 'VPPW'))
        .map(user => { return { key: user.id, text: `${user.name} (${user.accountName})` } });
    const getAdvisor = (advisorUserId: string) => usersQuery.data?.find(u => u.id === advisorUserId);

    return (
        <>
        {
            isLoaded &&
            <Formik 
                initialValues={initialValues} 
                onSubmit={onSubmit}
                validate={onValidate}>
                { formProps => (
                    <Form style={s}>
                        <VPIDropdownField name="advisorUserId" label="Advisor" options={advisorOptions} required placeholder="Select..." disabled={!!preApprovalFormId} noItemsLabel="No advisors found" />

                        <VPIDisplayTextBox name="date" label="Date" value={format(new Date(), 'PP')} />
                        <VPIDisplayTextBox name="advisorName" label="Advisor Name" value={getAdvisor(formProps.values.advisorUserId)?.name} />
                        <VPIDisplayTextBox name="repCode" label="Rep Code" value={preApprovalFormId ? preApprovalFormQuery.data?.repCode || '(None)' : 'N/A'} />
                        <VPIDisplayTextBox name="telephone" label="Telephone" value={getAdvisor(formProps.values.advisorUserId)?.telephone} />
                        <VPIDisplayTextBox name="city" label="City" value={getAdvisor(formProps.values.advisorUserId)?.city} />
                        <VPIDisplayTextBox name="province" label="Province" value={getAdvisor(formProps.values.advisorUserId)?.province} />
                        <VPIDisplayTextBox name="email" label="Email" value={getAdvisor(formProps.values.advisorUserId)?.email} />

                        <VPIChoiceField  name="expenseType" label="Expense Type" required disabled={!!preApprovalFormId} options={[
                            { key: 'Advisor Development & Travel', text: 'Advisor Development & Travel' },
                            { key: 'Advisor Marketing', text: 'Advisor Marketing' },
                            { key: 'Client Events', text: 'Client Events' },
                            { key: 'Practice Management & Technology', text: 'Practice Management & Technology' }
                        ]} />

                        <VPIDisplayTextBox name="preApprovalAmount" label="Pre-Approval Amount" value={
                            preApprovalFormId ? currencyToString(preApprovalFormQuery.data?.expenseAmountRequested || 0) : 'N/A'
                        } />

                        <VPICurrencyField name="amountRequested" label="Amount Requested" required />

                        <FormSectionHeader gridArea="expenseDetailsHeader">
                            <h4>Expense Details</h4>
                            <p>Please attach invoices and itemized summary of expenses, as well as a copy of the approved Cost Sharing Request Pre-Approval form or pre-approval email. If this is not attached, requests for reimbursement will be returned.</p>
                        </FormSectionHeader>
                        <VPIAttachmentField name="attachments" multiple buttonLabel="Attach Documents" required formId={form?.id} />
                        
                        <FormSectionHeader gridArea="attendanceInfoHeader"><h4>Event Attendance (if applicable):</h4></FormSectionHeader>
                        <VPITextField name="attendanceClients" label="Clients" type="number" placeholder="Enter clients" minimum={0} />
                        <VPITextField name="attendanceEmployees" label="Employees" type="number" placeholder="Enter employees" minimum={0} />

                        <CertificationForm formProps={formProps} advisor={getAdvisor(formProps.values.advisorUserId)} allowComments={allowComments} />
                    </Form>
                )}
            </Formik>
        }
        { !isLoaded && <FormShimmer /> }
        </>
    );
};

const CertificationForm = (props: { formProps: FormikProps<any>, advisor?: User, allowComments: boolean }) =>
{  
    const { formProps, advisor, allowComments } = props;
    const { user } = useUserContext();
    const canSubmit = hasPermission(user, 'vppw.submit');
    const submitToCompliance = hasRole(user, UserRole.VPPWCompliance, UserRole.VPPWFinance);
    const tooltipId = useId('tooltip');

    return (
        <div style={{ gridArea: 'certificationForm' }}>
            <hr />

            <h4>Certification</h4>
            <p>We hereby certify that the expenses noted above are eligible for reimbursement under the standards outlined in Principal Distributorship Agreement with respects to National Instrument 81-105, Mutual Fund Sales Practices ("The National Instrument")</p>

            <Flex gap="10px" align="normal" justify="normal" column>
                <VPIDisplayTextBox label="Advisor Name/Signature" value={advisor?.name} />
                <VPIDisplayTextBox label="Date" value={format(new Date(), 'PP')} />
                {
                    allowComments &&
                    <VPITextField name="comments" label="Comments" description="Please include any changes you have made to your request" disabled={formProps.isSubmitting} multiline rows={4} />
                }
            </Flex>

            <hr />
            <div style={{ display: 'flex', gap: '10px', justifyContent: 'flex-end' }}>
                <DefaultButton text="Save as Draft" disabled={formProps.isSubmitting} onClick={() => {
                    formProps.setFieldValue('action', 'save-draft');
                    // Form fails validation if submitted immediately after setting value
                    setTimeout(() => formProps.submitForm());
                }}  />
                <TooltipHost
                    content={canSubmit ? '' : 'You must be an advisor to submit'}
                    delay={TooltipDelay.zero}
                    id={tooltipId}>
                    <PrimaryButton text={submitToCompliance ? 'Send to Compliance' : 'Send to Business'} disabled={formProps.isSubmitting || !canSubmit} aria-describedby={tooltipId} onClick={() => {
                        formProps.setFieldValue('action', 'submit');
                        // Form fails validation if submitted immediately after setting value
                        setTimeout(() => formProps.submitForm());
                    }} />
                </TooltipHost>
            </div>
        </div>
    );
};