import { ReactNode, useCallback } from "react";
import { DefaultButton, TextField } from "@fluentui/react";
import { useQuery, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { Card } from "../../components/Card"
import { CardContent } from "../../components/CardContent";
import { CardHeader } from "../../components/CardHeader"
import { Instructions } from "../../components/Instructions";
import { PageContainer } from "../../components/PageContainer";
import { PageHeader } from "../../components/PageHeader";
import { AppRoutes } from "../../constants/AppRoutes";
import { FormStatus, formStatusToString } from "../../data/models/Form";
import { TaskOutcome } from "../../data/models/Task";
import { GetForm } from "../../data/queries/FormQueries";
import { GetTasks } from "../../data/queries/TaskQueries";
import { QueryKeys } from "../../data/QueryKeys";
import { VPPWPreApprovalForm } from "./VPPWPreApprovalForm"
import { FormVppwPreApproval } from "../../data/models/FormVppwPreApproval";
import { TaskVppwPreApproval } from "../../data/models/TaskVppwPreApproval";
import { DetailsCardShimmer, FormCardShimmer } from "../../helpers/Shimmer";
import { TaskHistoryList } from "../../components/TaskHistoryList";
import { ClarificationStage, ComplianceApprovalStage, DealerApprovalStage, FinanceApprovalStage, NoActionsStage, submitForm } from "../../forms/DefaultStages";
import { useUserContext } from "../../hooks/useUserContext";
import { hasPermission, hasRole, UserRole } from "../../data/models/User";
import { VPICalculatedField } from "../../fields/VPICalculatedField";
import { currencyToString } from "../../utilities/FormUtils";
import { VPIDisplayTextBox } from "../../fields/VPIDisplayTextBox";
import { VPPWPreApprovalDetails } from "./VPPWPreApprovalDetails";

const FORM_TYPE = 'vppw-preapproval';
const INSTRUCTIONS_URL = '/instructions/VPPW - Pre-Approval Instructions.pdf';

export const VPPWPreApprovalWorkflow = (props: any) => 
{
    const params = useParams();
    const nav = useNavigate();
    const queryClient = useQueryClient();
    const { user } = useUserContext();
    const formId = params.formId ?? 'new'; // formId is set to the ID or 'new' the user is creating a new form
    const isNewForm = formId === 'new';

    const formQuery = useQuery(QueryKeys.VPPWPreApproval.Form(formId), async () => GetForm<FormVppwPreApproval>(FORM_TYPE, formId), { enabled: !isNewForm });
    const taskQuery = useQuery(QueryKeys.VPPWPreApproval.Tasks(formId), async () => GetTasks<TaskVppwPreApproval>(FORM_TYPE, formId), { enabled: !isNewForm });

    let detailsView: ReactNode = <DetailsCardShimmer />;
    let formView: ReactNode = <FormCardShimmer />

    const refreshPage = useCallback(() => { 
        queryClient.invalidateQueries(QueryKeys.VPPWPreApproval.Form(formId)); 
        nav(AppRoutes.Dashboard()); 
    }, [queryClient, formId]);

    const onSaveForm = useCallback(async (formData: Partial<FormVppwPreApproval>, action: 'save-draft' | 'submit') => {
        try
        {
            await submitForm(FORM_TYPE, formQuery.data?.id, formData, action);
            refreshPage();
        }
        catch(error: any)
        {
            console.log(error);
            alert(JSON.stringify(error.response.data));
        }
    }, [formQuery.data, refreshPage]);

    if(isNewForm)
    {
        detailsView = <Instructions url={INSTRUCTIONS_URL} />;
        formView = (
            <>
                <CardHeader>VPPW - Pre-Approval Request</CardHeader>
                <CardContent>
                    <VPPWPreApprovalForm onSave={onSaveForm} />
                </CardContent>
            </>
        );
    }
    // If a form item has already been created and is fetched (i.e. not new)
    else if(
        !formQuery.isFetching && formQuery.data &&
        !taskQuery.isFetching && taskQuery.data
    )
    {
        const form = formQuery.data;
        const currentTask = taskQuery.data.find(t => !t.outcome || t.outcome === TaskOutcome.InProgress);

        switch(form.formStatus)
        {
            case FormStatus.Draft:
            case FormStatus.AdvisorRevision:
                detailsView = <Instructions url={INSTRUCTIONS_URL} />;
                break;
            case FormStatus.AdvisorClarification:
            // case FormStatus.DealerApproval:
            // case FormStatus.DealerClarification:
            case FormStatus.BusinessApproval:
            case FormStatus.BusinessClarification:
            case FormStatus.ComplianceApproval:
            case FormStatus.ComplianceClarification:
            case FormStatus.FinanceApproval:
                detailsView = <VPPWPreApprovalDetails form={form} variant="2column" />
                break;
            default:
                detailsView = null;
                break;
        }

        // Set the form view based on form status and user role
        if((form.formStatus === FormStatus.Draft || form.formStatus === FormStatus.AdvisorRevision) && hasPermission(user, 'vppw.write'))
            formView = (
                <>
                    <CardHeader>VPPW - Pre-Approval Request</CardHeader>
                    <CardContent>
                        <VPPWPreApprovalForm form={form} onSave={onSaveForm} allowComments={!!currentTask} />
                    </CardContent>
                </>
            );
        else if(form.formStatus === FormStatus.AdvisorClarification && hasPermission(user, 'vppw.write'))
            formView = <ClarificationStage form={form} task={currentTask} onSave={refreshPage} title="Advisor Clarification Requested" />;
        // else if(form.formStatus === FormStatus.DealerClarification && hasRole(user, UserRole.Dealer))
        //     formView = <ClarificationStage form={form} task={currentTask} onSave={refreshPage} title="Dealer Clarification Requested" />;
        else if(form.formStatus === FormStatus.BusinessClarification && hasRole(user, UserRole.VPPWBusiness))
            formView = <ClarificationStage form={form} task={currentTask} onSave={refreshPage} title="Business Clarification Requested" />;
        else if(form.formStatus === FormStatus.ComplianceClarification && hasRole(user, UserRole.VPPWCompliance))
            formView = <ClarificationStage form={form} task={currentTask} onSave={refreshPage} title="Compliance Clarification Requested" />;
        //else if((form.formStatus === FormStatus.DealerApproval && hasRole(user, UserRole.Dealer)) 
        else if (form.formStatus === FormStatus.BusinessApproval && hasRole(user, UserRole.VPPWBusiness))
            formView = (
                <DealerApprovalStage form={form} task={currentTask} onSave={refreshPage}>
                    <CardHeader>Attendance Information</CardHeader>
                    <CardContent style={{ display: 'flex', gap: '15px' }}>
                        <VPIDisplayTextBox label="Total Attendance (Estimate)" value={form.attendanceClients + form.attendanceEmployees} />
                        <VPIDisplayTextBox label="Total Cost (Estimate)" value={currencyToString(form.expenseEstimatedTotalCost)} />
                        <VPIDisplayTextBox label="Cost Per Person (Estimate)" value={
                            (form.attendanceClients + form.attendanceEmployees) > 0 ? currencyToString(form.expenseEstimatedTotalCost / (form.attendanceClients + form.attendanceEmployees)) : 'N/A'
                        } />
                    </CardContent>
                </DealerApprovalStage>
            );
        else if(form.formStatus === FormStatus.ComplianceApproval && hasRole(user, UserRole.VPPWCompliance))
            formView = <ComplianceApprovalStage form={form} task={currentTask} onSave={refreshPage} allowDealerClarification={false} allowBusinessClarification vpiLabels={false} />;
        // else if(form.formStatus === FormStatus.FinanceApproval && hasRole(user, UserRole.Finance))
        //     formView = <FinanceApprovalStage form={form} task={currentTask} onSave={refreshPage} />
        else if(form.formStatus === FormStatus.Completed 
            || form.formStatus === FormStatus.Withdrawn 
            || form.formStatus === FormStatus.Rejected)
        {
            formView = (
                <>
                    <CardHeader>{form.formId} | VPPW - Pre-Approval Request</CardHeader>
                    <CardContent>
                        <VPPWPreApprovalDetails form={form} />
                    </CardContent>
                </>
            );
        }
        else 
        {
            formView = <NoActionsStage title="VPPW Pre-Approval Request" />;
        }
    } 

    return (
        <PageContainer type={detailsView ? '3column' : '2column'}>
            <PageHeader>
                <DefaultButton text="Back" onClick={() => nav(AppRoutes.Dashboard())} />
            </PageHeader>
            { detailsView &&
                <Card style={{ alignSelf: 'flex-start' }}>
                    <CardHeader>{formQuery.data?.formId || 'Details'}</CardHeader>
                    <CardContent>{detailsView}</CardContent>
                </Card>
            }
            <Card>{formView}</Card>
            <Card style={{ alignSelf: 'flex-start' }}>
                <CardHeader>History</CardHeader>
                <TaskHistoryList tasks={taskQuery.data?.filter(t => t.outcome && t.outcome !== TaskOutcome.InProgress)} isLoading={taskQuery.isFetching} />
            </Card>
        </PageContainer>
    );
};
