import { Form, Formik } from "formik";
import { format, isDate } from "date-fns";
import { CSSProperties } from "react";
import { useQuery } from "react-query";
import { FormSectionHeader } from "../../components/FormSectionHeader";
import { FormCoopPreApproval } from "../../data/models/FormCoopPreApproval"
import { GetUser } from "../../data/queries/UserQueries";
import { QueryKeys } from "../../data/QueryKeys";
import { VPIDisplayField } from "../../fields/VPIDisplayField";
import { currencyToString } from "../../utilities/FormUtils";
import { TaskCoopPreApproval } from "../../data/models/TaskCoopPreApproval";
import { GetTasks } from "../../data/queries/TaskQueries";
import { sortByProperty } from "../../utilities/Utils";
import { TaskOutcome } from "../../data/models/Task";
import { FormStatus } from "../../data/models/Form";
import { useUserContext } from "../../hooks/useUserContext";
import { hasPermission, hasRole, UserRole } from "../../data/models/User";
import { VPIAttachmentsDisplayField } from "../../fields/VPIAttachmentsDisplayField";
import { FormStatusBar } from "../../components/FormStatusBar";
import { DetailsGridStyles2Col, DetailsGridStyles3Col } from "../../constants/Constants";

export interface CoopPreApprovalDetailsProps
{
    form: FormCoopPreApproval;
    variant?: '3column' | '2column';
}

export const CoopPreApprovalDetails = (props: CoopPreApprovalDetailsProps) =>
{
    const { form, variant = '3column' } = props;
    const { user } = useUserContext();
    const initialValues = form ?? {};
    const userQuery = useQuery(QueryKeys.User.Id(form.advisorUserId), () => GetUser(form.advisorUserId), { enabled: !!form.advisorUserId });
    const tasksQuery = useQuery(QueryKeys.CoopPreApproval.Tasks(form.id), () => GetTasks<TaskCoopPreApproval>(form.type, form.id))

    const latestComplianceTask = [...(tasksQuery.data || [])].sort(sortByProperty('createdOn', false)).find(task => task.stage === 'compliance-review' && task.outcome === TaskOutcome.Approved);

    let s: CSSProperties = Object.assign({}, DetailsGridStyles3Col, {
        gridTemplateAreas: `
            'advisorUserId date .'
            'repCode telephone fax'
            'city province postalCode'
            'eventDetailsHeader eventDetailsHeader eventDetailsHeader'
            'eventType . .'
            'eventDate . .'
            'eventLocation . .'
            'eventEstimatedTotalCost eventAmountRequested .'
            'eventChequePayableTo . .'
            'attendanceHeader attendanceHeader attendanceHeader'
            'attendanceClients attendanceEmployees .'
            'fundCompaniesHeader fundCompaniesHeader fundCompaniesHeader'
            'fundCompanies fundCompanies fundCompanies'
            'companiesAmountRequested companiesAmountRequested .'
            'indicatedClientInvitation indicatedClientInvitation indicatedClientInvitation'
            'otherIndicatedClientInvitation otherIndicatedClientInvitation .'
            'attachmentsDivider attachmentsDivider .'
            'attachments attachments .'
            'certificationHeader certificationHeader certificationHeader'
            'dealerForm dealerForm .'
            'attendanceAnalysisHeader attendanceAnalysisHeader attendanceAnalysisHeader'
            'totalAttendance totalCost costPerPerson'
        `
    });

    if(variant === '2column')
    {
        s = Object.assign({}, DetailsGridStyles2Col, {
            gridTemplateAreas: `
                'advisorUserId date'
                'repCode telephone'
                'fax city'
                'province postalCode'
                'eventDetailsHeader eventDetailsHeader'
                'eventType eventLocation'
                'eventDate eventDate'
                'eventEstimatedTotalCost eventAmountRequested'
                'eventChequePayableTo .'
                'attendanceHeader attendanceHeader'
                'attendanceClients attendanceEmployees'
                'fundCompaniesHeader fundCompaniesHeader'
                'fundCompanies fundCompanies'
                'companiesAmountRequested companiesAmountRequested'
                'indicatedClientInvitation indicatedClientInvitation'
                'otherIndicatedClientInvitation otherIndicatedClientInvitation'
                'attachmentsDivider attachmentsDivider'
                'attachments attachments'
                'certificationHeader certificationHeader'
                'dealerForm dealerForm'
                'attendanceAnalysisHeader attendanceAnalysisHeader'
                'totalAttendance totalCost'
                'costPerPerson .'
            `
        });
    }

    return (
        <>
        <FormStatusBar status={form?.formStatus} />
        <Formik
            initialValues={initialValues}
            onSubmit={() => {}}
            enableReinitialize>
            { formProps => (
                <Form style={s}>
                    <VPIDisplayField name="advisorUserId" label="Advisor Name" render={() => userQuery.data?.name || ''} />
                    <VPIDisplayField name="date" label="Date" render={(date: Date) => isDate(date) ? format(date, 'PP') : 'No date set' } />
                    <VPIDisplayField name="repCode" label="Rep Code" />
                    <VPIDisplayField name="telephone" label="Telephone" render={() => userQuery.data?.telephone || ''} />
                    <VPIDisplayField name="fax" label="Fax" render={() => userQuery.data?.fax || ''} />
                    <VPIDisplayField name="city" label="City" render={() => userQuery.data?.city || ''} />
                    <VPIDisplayField name="province" label="Province" render={() => userQuery.data?.province || ''} />
                    <VPIDisplayField name="postalCode" label="Postal Code" render={() => userQuery.data?.postalCode || ''} />

                    {/* <VPIDisplayField name="dealerName" label="Dealer Name" /> */}

                    <FormSectionHeader gridArea="eventDetailsHeader">
                        <h3>Event Details</h3>
                    </FormSectionHeader>

                    <VPIDisplayField name="eventType" label="Event Type" />
                    <VPIDisplayField name="eventDate" label="Event Date" render={(date: Date) => format(date, 'PPp') } />
                    <VPIDisplayField name="eventLocation" label="Location" />
                    <VPIDisplayField name="eventEstimatedTotalCost" label="Estimated Total Cost" render={value => currencyToString(value)} />
                    <VPIDisplayField name="eventAmountRequested" label="Amount Requested" render={value => currencyToString(value)} />
                    {/* <VPIDisplayField name="eventChequePayableTo" label="Cheque Payable to..." /> */}

                    <FormSectionHeader gridArea="attendanceHeader">
                        <h3>Attendance Information (Estimated)</h3>
                    </FormSectionHeader>
                    <VPIDisplayField name="attendanceClients" label="Clients" />
                    <VPIDisplayField name="attendanceEmployees" label="Employees" />

                    {/* <FormSectionHeader gridArea="eventDescriptionHeader">
                        <h3>Event Description</h3>
                    </FormSectionHeader> */}
                    {/* <VPIDisplayField name="eventDescription" label="Description" /> */}

                    <FormSectionHeader gridArea="fundCompaniesHeader">
                        {/* <h3>Fund Companies</h3> */}
                    </FormSectionHeader>
                    <VPIDisplayField name="fundCompanies" label="Fund Companies Approached" />
                    <VPIDisplayField name="companiesAmountRequested" label="Amount Requested ($) From All Fund Companies" render={value => currencyToString(value)} />
                    <VPIDisplayField name="indicatedClientInvitation" label="We have indicated on the client invitation: 'This event is sponsored in part by Value Partners Investments'" render={value => value ? 'Yes' : 'No'} />
                    <VPIDisplayField name="otherIndicatedClientInvitation" label="Other (if applicable):" />

                    <hr style={{ gridArea: 'attachmentsDivider' }} />
                    <VPIAttachmentsDisplayField name="attachments" label="Attachments" />

                    <FormSectionHeader gridArea="certificationHeader">
                        <h3>Signed Dealer Certification</h3>
                    </FormSectionHeader>
                    <VPIAttachmentsDisplayField name="dealerForm" />

                    <FormSectionHeader gridArea="attendanceAnalysisHeader">
                        <h3>Attendance Information Analysis</h3>
                    </FormSectionHeader>
                    <VPIDisplayField name="totalAttendance" label="Total Attendance (Estimate)" render={() => (
                        form.attendanceClients + form.attendanceEmployees).toString()
                    } />
                    <VPIDisplayField name="totalCost" label="Total Cost (Estimate)" render={() => (
                        currencyToString(form.eventEstimatedTotalCost)
                    )} />
                    <VPIDisplayField name="costPerPerson" label="Cost Per Person (Estimate)" render={() => (
                        (form.attendanceClients + form.attendanceEmployees) > 0 ? currencyToString(form.eventEstimatedTotalCost / (form.attendanceClients + form.attendanceEmployees)) : 'N/A'
                    )} />
                </Form>
            )}
        </Formik>
        { 
            latestComplianceTask && 
            (form.formStatus === FormStatus.FinanceApproval || form.formStatus === FormStatus.Completed || form.formStatus === FormStatus.Paid) && 
            hasRole(user, UserRole.Compliance, UserRole.Finance) &&
            <CoopComplianceChecklist task={latestComplianceTask} /> 
        }
        </>
    )
}

const CoopComplianceChecklist = (props: { task: TaskCoopPreApproval }) =>
{
    const { task } = props;

    const s: CSSProperties = {
        display: 'flex',
        flexDirection: 'column',
        gap: '15px',
        maxWidth: '640px'
    };

    return (
        <Formik
            initialValues={task}
            onSubmit={() => {}}
            enableReinitialize>
            { formProps => (
                <Form style={s}>
                    <FormSectionHeader gridArea="">
                        <h2>Internal Checklist</h2>
                        <h3>Assessment</h3>
                    </FormSectionHeader>
                    <VPIDisplayField name="collectedVPICoopPreApproval" label="1. VPIs Pre-Approval Form for Co-operative Marketing Request" render={value => value ? 'Yes' : 'No'} />
                    <VPIDisplayField name="collectedVPICoopPreApprovalComment" label="Notes" />
                    <VPIDisplayField name="collectedAgenda" label="2. Agenda for the event highlighting topics and the time allocation" render={value => value ? 'Yes' : 'No'} />
                    <VPIDisplayField name="collectedAgendaComment" label="Notes" />
                    <VPIDisplayField name="collectedCopyOfMaterial" label="3. Copy of material (presentation, notes, etc.) to be discussed at the event" render={value => value ? 'Yes' : 'No'} />
                    <VPIDisplayField name="collectedCopyOfMaterialComment" label="Notes" />
                    <VPIDisplayField name="collectedBudgetCosts" label="4. Budgeted Costs (usually recorded in Pre-Approval Form for Co-operative Marketing Request" render={value => value ? 'Yes' : 'No'} />
                    <VPIDisplayField name="collectedBudgetCostsComment" label="Notes" />
                    <VPIDisplayField name="collectedAttendanceInformation" label="5. Attendance Information (both employees and clients)" render={value => value ? 'Yes' : 'No'} />
                    <VPIDisplayField name="collectedAttendanceInformationComment" label="Notes" />

                    <FormSectionHeader gridArea="">
                        <h3>Qualitative & Quantitative Analysis</h3>
                    </FormSectionHeader>

                    <VPIDisplayField name="budgetedCost" label="Budgeted Cost" render={() => currencyToString(task.budgetedCost)} />
                    <VPIDisplayField name="estimatedAttendance" label="Number of Participants" />
                    <VPIDisplayField name="costPerPerson" label="Cost Per Person" render={() => (
                        task.estimatedAttendance > 0 ? currencyToString(task.budgetedCost / task.estimatedAttendance) : 'N/A'
                    )} />

                    <VPIDisplayField name="reasonabilityFinancial" label="1. Financial Reasonability" render={value => value ? 'Reasonable' : 'Not Reasonable'} />
                    <VPIDisplayField name="reasonabilityEventClientAppreciation" label="2. Is event a Client Appreciation Event" render={value => value ? 'Yes' : 'No'} />
                    <VPIDisplayField name="reasonabilityInvestorsInformedInWriting" label="3. Are Investors informed in WRITING of the Sponsorship" render={value => value ? 'Yes' : 'No'} />
                    <VPIDisplayField name="reasonabilityPrimaryPurposeTestMet" label="4. Is the Primary Purpose Test Met" render={value => value ? 'Yes' : 'No'} />

                    <FormSectionHeader gridArea="">
                        <h3>Conclusion</h3>
                    </FormSectionHeader>
                    <VPIDisplayField name="expensesConclusion" label="As noted above, the expenses appear:" />

                </Form>
            )}
        </Formik>
    )
};