import { CSSProperties } from "react";
import "./CardContent.scss";

export const CardContent = (props: { children: any, style?: CSSProperties }) => 
{
    return (
        <div className="card-content" style={props.style}>
            {props.children}
        </div>
    );
};