export enum TaskOutcome
{
    Approved = 1,
    Rejected = 2,
    AdvisorClarification = 3,
    DealerClarification = 4,
    ComplianceClarification = 5,
    Completed = 6,
    InProgress = 7,
    Cancelled = 8,
    Resubmitted = 9,
    Paid = 10,
    AdvisorRevision = 11,
    BusinessClarification = 12
}

export type TaskStage = 
    'advisor-review' |
    'advisor-clarification' | 
    'dealer-review' | 
    'dealer-clarification' |
    'business-review' |
    'business-clarification' |
    'compliance-review' | 
    'compliance-clarification' |
    'finance-review' | 
    'accounting-review' |
    'paid' |
    'advisor-revision' |
    'invalid';


export interface Task {
    id: string;
    formId: string;
    stage: TaskStage;
    comments: string;
    outcome: TaskOutcome;
    createdOn: Date;
    completed: Date;
    completedByUserId: string;
}

export function taskOutcomeToString(taskOutcome: TaskOutcome)
{
    const mappings = {
        [TaskOutcome.Approved]: 'Approved',
        [TaskOutcome.Rejected]: 'Rejected',
        [TaskOutcome.AdvisorClarification]: 'Advisor Clarification',
        [TaskOutcome.DealerClarification]: 'Dealer Clarification',
        [TaskOutcome.ComplianceClarification]: 'Compliance Clarification',
        [TaskOutcome.BusinessClarification]: 'Business Clarification',
        [TaskOutcome.Completed]: 'Completed',
        [TaskOutcome.InProgress]: 'In Progress',
        [TaskOutcome.Cancelled]: 'Cancelled',
        [TaskOutcome.Resubmitted]: 'Resubmitted',
        [TaskOutcome.Paid]: 'Marked as Paid',
        [TaskOutcome.AdvisorRevision]: 'Advisor Revision'
    };

    return mappings[taskOutcome] ?? taskOutcome?.toString();
}

export function taskStageToString(taskStage: string)
{
    switch(taskStage)
    {
        case 'advisor-review': return 'Advisor Review';
        case 'advisor-clarification': return 'Advisor Clarification';
        case 'advisor-revision': return 'Advisor Revision';
        case 'dealer-review': return 'Dealer Review';
        case 'dealer-clarification': return 'Dealer Clarification';
        case 'business-review': return 'Business Review';
        case 'business-clarification': return 'Business Clarification';
        case 'compliance-review': return 'Compliance Review';
        case 'compliance-clarification': return 'Compliance Clarification';
        case 'finance-review': return 'Finance Review';
        case 'accounting-review': return 'Reimbursement';
        case 'approved': return 'Approved';
        default: return taskStage;
    }
}
