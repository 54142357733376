
export function MakeAPIUrl(path: string) {
    let retval: string | undefined = process.env['REACT_APP_API_URL'];

    if(!retval)
    {
        if(window.location.host.includes('localhost'))
            retval = `${window.location.protocol}//${window.location.hostname}:7071/api`;
        else
            retval = `${window.location.protocol}//${window.location.host}/api`;
    }

    return `${retval}${path.startsWith('/') ? '' : '/'}${path}`;
}