import "./CardHeader.scss";

export const CardHeader = (props: { children: any, subtitle?: string }) => 
{
    return (
        <div className="card-header">
            <div className="title">{props.children}</div>
            { 
                props.subtitle ?
                    <div className="subtitle">{props.subtitle}</div> 
                    : null
            }
        </div>
    );
};