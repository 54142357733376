import { Checkbox, TextField } from "@fluentui/react";
import { useField, useFormikContext } from "formik";
import React from "react";

export interface VPICheckboxFieldProps
{
    name: string;
    label?: string;
    required?: boolean;
    disabled?: boolean;
}

export const VPICheckboxField = (props: VPICheckboxFieldProps) => 
{
    const { name, label, required, disabled } = props;
    const [ field, meta, helpers ] = useField(name);
    const formikContext = useFormikContext();

    return (
        <>
        <Checkbox
            label={label}
            required={required}
            disabled={disabled || formikContext.isSubmitting}
            //errorMessage={meta.touched ? meta.error : ''}
            styles={{ root: { gridArea: name } }}
            checked={field.value}
            {...field}
        />
        <div>{meta.touched ? meta.error : ''}</div>
        </>
    );
}

