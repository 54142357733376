import { Label, TextField } from "@fluentui/react";
import { useField, useFormikContext } from "formik";
import { CSSProperties } from "react";

export interface VPITextFieldProps
{
    name: string;
    label?: string;
    type?: 'text' | 'email' | 'number';
    multiline?: boolean;
    rows?: number;
    required?: boolean;
    disabled?: boolean;
    placeholder?: string;
    description?: string;
    style?: CSSProperties;

    // For number type
    minimum?: number
    maximum?: number
}

export const VPITextField = (props: VPITextFieldProps) => 
{
    const { name, label, type, multiline, rows, required, disabled, placeholder, description, minimum, maximum, style } = props;
    const [ field, meta, helpers ] = useField(name);
    const formikContext = useFormikContext();

    return (
        <>
        <TextField
            type={type}
            label={label}
            multiline={multiline}
            className={multiline ? 'hide-print' : ''}
            rows={rows}
            required={required}
            disabled={disabled || formikContext.isSubmitting}
            placeholder={placeholder}
            errorMessage={meta.touched ? meta.error : ''}
            styles={{ root: { gridArea: name, ...(style as any) }, field: { resize: 'vertical' } }}
            min={minimum}
            max={maximum}
            description={description}
            {...field}
        />
        {
            multiline &&
            <div style={{ gridArea: name }} className="print-only">
                <Label>{label}</Label>
                <p style={{
                    border: '1px solid #F2F2F2',
                    borderRadius: '5px',
                    padding: '8px',
                    lineHeight: '22px',
                    minHeight: '37px'
                }}>{field.value}</p>
            </div>
        }
        </>
    );
};
