import { ReactNode, useCallback } from "react";
import { DefaultButton, TextField } from "@fluentui/react";
import { useQuery, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { Card } from "../../components/Card"
import { CardContent } from "../../components/CardContent";
import { CardHeader } from "../../components/CardHeader"
import { Instructions } from "../../components/Instructions";
import { PageContainer } from "../../components/PageContainer";
import { PageHeader } from "../../components/PageHeader";
import { TaskHistoryList } from "../../components/TaskHistoryList";
import { AppRoutes } from "../../constants/AppRoutes";
import { FormStatus } from "../../data/models/Form";
import { FormCoopPreApproval } from "../../data/models/FormCoopPreApproval";
import { TaskOutcome } from "../../data/models/Task";
import { TaskCoopPreApproval } from "../../data/models/TaskCoopPreApproval";
import { GetForm } from "../../data/queries/FormQueries";
import { GetTasks } from "../../data/queries/TaskQueries";
import { QueryKeys } from "../../data/QueryKeys";
import { CoopPreApprovalForm } from "./CoopPreApprovalForm";
import { FormCardShimmer, DetailsCardShimmer } from "../../helpers/Shimmer";
import { ClarificationStage, DealerApprovalStage, FinanceApprovalStage, NoActionsStage, StageProps, submitForm } from "../../forms/DefaultStages";
import { CoopPreApprovalComplianceForm } from "./CoopPreApprovalComplianceForm";
import { CoopPreApprovalDetails } from "./CoopPreApprovalDetails";
import { hasPermission, hasRole, UserRole } from "../../data/models/User";
import { useUserContext } from "../../hooks/useUserContext";
import { currencyToString } from "../../utilities/FormUtils";
import { VPIDisplayTextBox } from "../../fields/VPIDisplayTextBox";

const FORM_TYPE = 'coop-preapproval'
const INSTRUCTIONS_URL = '/instructions/Co-op Marketing - Pre-Approval Instructions.pdf';

export const CoopPreApprovalWorkflow = () => 
{
    const params = useParams();
    const nav = useNavigate();
    const queryClient = useQueryClient();
    const { user } = useUserContext();
    const formId = params.formId ?? 'new'; // formId is set to the ID or 'new' the user is creating a new form
    const isNewForm = formId === 'new';

    const formQuery = useQuery(QueryKeys.CoopPreApproval.Form(formId), async () => GetForm<FormCoopPreApproval>(FORM_TYPE, formId), { enabled: !isNewForm });
    const taskQuery = useQuery(QueryKeys.CoopPreApproval.Tasks(formId), async () => GetTasks<TaskCoopPreApproval>(FORM_TYPE, formId), { enabled: !isNewForm });    
    const isLoaded = isNewForm || (formQuery.isSuccess && taskQuery.isSuccess);

    let detailsView: ReactNode = <DetailsCardShimmer />;
    let formView: ReactNode = <FormCardShimmer />;

    const form = formQuery.data;
    const tasks = taskQuery.data;

    const refreshPage = useCallback(() => { 
        queryClient.invalidateQueries(QueryKeys.CoopPreApproval.Form(formId)); 
        nav(AppRoutes.Dashboard()); 
    }, [queryClient, formId]);

    const onSaveForm = useCallback(async (formData: Partial<FormCoopPreApproval>, action: 'save-draft' | 'submit') => {
        try
        {
            await submitForm('coop-preapproval', form?.id, formData, action);
            refreshPage();
        }
        catch(error: any)
        {
            console.log(error);
            alert(JSON.stringify(error.response.data));
        }
    }, [form, refreshPage]);

    if(isLoaded)
    {
        if(isNewForm)
        {
            detailsView = <Instructions url={INSTRUCTIONS_URL} />;
            formView = (
                <>
                    <CardHeader>Co-op - Pre-Approval Request</CardHeader>
                    <CardContent>
                        <CoopPreApprovalForm onSave={onSaveForm} />
                    </CardContent>
                </>
            );
        }
        // If a form item has already been created and is fetched (i.e. not new)
        else if (isLoaded && form && tasks)
        {
            const currentTask = tasks.find(t => !t.outcome || t.outcome === TaskOutcome.InProgress);
    
            // Set content for left pane / details pane
            switch(form.formStatus)
            {
                case FormStatus.Draft:
                case FormStatus.AdvisorRevision:
                    detailsView = <Instructions url={INSTRUCTIONS_URL} />;
                    break;
                case FormStatus.AdvisorClarification:
                case FormStatus.DealerApproval:
                case FormStatus.DealerClarification:
                case FormStatus.ComplianceApproval:
                case FormStatus.ComplianceClarification:
                case FormStatus.FinanceApproval:
                    detailsView = <CoopPreApprovalDetails form={form} variant='2column' />
                    break;
                default:
                    detailsView = null;
                    break;
            }
    
            // Set the form view based on form status and user role
            if((form.formStatus === FormStatus.Draft || form.formStatus === FormStatus.AdvisorRevision) && hasPermission(user, 'coop.write'))
                formView = (
                    <>
                        <CardHeader>Co-op - Pre-Approval Request</CardHeader>
                        <CardContent>
                            <CoopPreApprovalForm form={form} onSave={onSaveForm} allowComments={!!currentTask} />
                        </CardContent>
                    </>
                );
            else if(form.formStatus === FormStatus.AdvisorClarification && hasPermission(user, 'coop.write'))
                formView = <ClarificationStage form={form} task={currentTask} onSave={refreshPage} title="Advisor Clarification Requested" />;
            else if(form.formStatus === FormStatus.DealerClarification && hasRole(user, UserRole.Dealer))
                formView = <ClarificationStage form={form} task={currentTask} onSave={refreshPage} title="Dealer Clarification Requested" />;
            else if(form.formStatus === FormStatus.ComplianceClarification && hasRole(user, UserRole.Compliance))
                formView = <ClarificationStage form={form} task={currentTask} onSave={refreshPage} title="Compliance Clarification Requested" />;
            else if(form.formStatus === FormStatus.DealerApproval && hasRole(user, UserRole.Dealer))
                formView = (
                    <DealerApprovalStage form={form} task={currentTask} onSave={refreshPage}>
                        <CardHeader>Attendance Information</CardHeader>
                        <CardContent style={{ display: 'flex', gap: '15px' }}>
                            <VPIDisplayTextBox label="Budgeted Cost" value={currencyToString(form.eventEstimatedTotalCost)} />
                            <VPIDisplayTextBox label="Total Attendance" value={form.attendanceClients + form.attendanceEmployees} />
                            <VPIDisplayTextBox label="Cost Per Person (Total)" value={
                                (form.attendanceClients + form.attendanceEmployees) > 0 ? currencyToString(form.eventEstimatedTotalCost / (form.attendanceClients + form.attendanceEmployees)) : 'N/A'
                            } />
                        </CardContent>
                    </DealerApprovalStage>
                );
            else if(form.formStatus === FormStatus.ComplianceApproval && hasRole(user, UserRole.Compliance) && currentTask)
                formView = <CoopPreApprovalComplianceForm form={form} task={currentTask} onSave={refreshPage} allowDealerClarification={form.externalDealer === false} />;
            else if(form.formStatus === FormStatus.FinanceApproval && hasRole(user, UserRole.Finance))
                formView = <FinanceApprovalStage form={form} task={currentTask} onSave={refreshPage} allowDealerClarification={form.externalDealer === false} />
            else if(form.formStatus === FormStatus.Completed 
                || form.formStatus === FormStatus.Withdrawn 
                || form.formStatus === FormStatus.Rejected)
            {
                formView = (
                    <>
                        <CardHeader>{form.formId} | Co-op - Pre-Approval Request</CardHeader>
                        <CardContent>
                            <CoopPreApprovalDetails form={form} />
                        </CardContent>
                    </>
                );
            }
            else 
            {
                formView = <NoActionsStage title="Co-op Pre-Approval Request" />;
            }
        }        
    }

    return (
        <PageContainer type={detailsView ? '3column' : '2column'}>
            <PageHeader>
                <DefaultButton text="Back" onClick={() => nav(AppRoutes.Dashboard())} />
            </PageHeader>
            { detailsView &&
                <Card style={{ alignSelf: 'flex-start' }}>
                    <CardHeader>{formQuery.data?.formId || 'Details'}</CardHeader>
                    <CardContent>{detailsView}</CardContent>
                </Card>
            }
            <Card>{formView}</Card>
            <Card style={{ alignSelf: 'flex-start' }}>
                <CardHeader>History</CardHeader>
                <TaskHistoryList tasks={tasks?.filter(t => t.outcome && t.outcome !== TaskOutcome.InProgress)} isLoading={taskQuery.isFetching} />
            </Card>
        </PageContainer>
    );
};
