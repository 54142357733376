
export interface FlexProps {
    //direction?: 'row' | 'column';
    column?: boolean;
    row?: boolean;
    justify?: 'flex-start' | 'flex-end' | 'space-between' | 'space-around' | 'center' | 'normal';
    align?: 'flex-start' | 'flex-end' | 'space-between' | 'center' | 'stretch' | 'normal';
    gap?: string;
    style?: React.CSSProperties;
    children?: any;
    className?: string;
}

export const Flex = (props: FlexProps) => {
    const s: React.CSSProperties = {
        display: 'flex',
        flexDirection: props.row ? 'row' : props.column ? 'column' : 'row',
        justifyContent: props.justify || 'flex-start',
        alignItems: props.align || 'flex-start',
        gap: props.gap,
        ...props.style
    };

    return (
        <div style={s} className={props.className}>{props.children}</div>
    );
}