import React, { useCallback } from "react";
import * as Yup from "yup";
import { Formik, Form, FormikProps, FormikHelpers } from "formik";
import { FormVpicPreApproval } from "../../data/models/FormVpicPreApproval";
import { VPITextField } from "../../fields/VPITextField";
import { FormSectionHeader } from "../../components/FormSectionHeader";
import { addressToString, attachmentsToAttachmentItems, copyInto, validateWhen } from "../../utilities/FormUtils";
import { DefaultButton, PrimaryButton, TooltipDelay, TooltipHost } from "@fluentui/react";
import { FormStatus } from "../../data/models/Form";
import { GetForm } from "../../data/queries/FormQueries";
import { useUserContext } from "../../hooks/useUserContext";
import { VPIChoiceField } from "../../fields/VPIChoiceField";
import { FormVpicReimbursement } from "../../data/models/FormVpicReimbursement";
import { hasPermission, hasProcessFlow, User, UserRole } from "../../data/models/User";
import { useId } from "@fluentui/react-hooks";
import { useQuery } from "react-query";
import { QueryKeys } from "../../data/QueryKeys";
import { GetAdvisors } from "../../data/queries/UserQueries";
import { VPIDropdownField } from "../../fields/VPIDropdownField";
import { VPICalculatedField } from "../../fields/VPICalculatedField";
import { format } from "date-fns";
import { Constants, FormGridStyles } from "../../constants/Constants";
import { FormShimmer } from "../../helpers/Shimmer";
import { useSearchParams } from "react-router-dom";
import { VPIAttachmentField, VPIFieldAttachment } from "../../fields/VPIAttachmentField";
import { VPICurrencyField } from "../../fields/VPICurrencyField";
import { VPIDisplayTextBox } from "../../fields/VPIDisplayTextBox";
import { Flex } from "../../components/Flex";

export interface VPICReimbursementFormProps
{
    form?: FormVpicReimbursement;
    onSave?: (formData: Partial<FormVpicReimbursement>, action: 'save-draft' | 'submit') => void | Promise<void>;
    allowComments?: boolean;
}

export const VPICReimbursementForm = (props: VPICReimbursementFormProps) => 
{
    const { form = null, onSave, allowComments = false } = props;
    const [searchParams] = useSearchParams();
    const preApprovalFormId = form?.preApprovalFormId || searchParams.get('p') || '';
    const preApprovalFormQuery = useQuery<FormVpicPreApproval>(QueryKeys.VPICPreApproval.Form(preApprovalFormId), () => GetForm('vpic-preapproval', preApprovalFormId), { enabled: !!preApprovalFormId });
    const usersQuery = useQuery(QueryKeys.User.All(), () => GetAdvisors());
    const isLoaded = (preApprovalFormQuery.isSuccess || !preApprovalFormId) && usersQuery.isSuccess;

    const s: React.CSSProperties = Object.assign({}, FormGridStyles, {
        gridTemplateAreas: `
            'advisorUserId advisorUserId advisorUserId'
            'date telephone city'
            'province . .'
            'expenseType expenseType expenseType'
            'expenseDetailsHeader expenseDetailsHeader expenseDetailsHeader'
            'attachments attachments .'
            'amountRequested . .'
            'attendanceInfoHeader attendanceInfoHeader attendanceInfoHeader'
            'attendanceClients attendanceEmployees .'
            'paymentHeader paymentHeader paymentHeader'
            'payableTo mailingAddress mailingAddress'
            'certificationForm certificationForm certificationForm'
        `
    });

    const onValidate = useCallback(validateWhen(Yup.object({
        formStatus: Yup.string().required(),
        advisorUserId: Yup.string().required('Please select an advisor'),
        expenseType: Yup.string().required('Please select an expense type'),
        attachments: Yup.array().ensure()
            .of(Yup.mixed().test('size', 'The file is too large', (value: VPIFieldAttachment) => value.size <= Constants.MAX_FILE_SIZE))
            .of(Yup.mixed().test('inProgress', 'Please wait for all files to finish uploading', (value: VPIFieldAttachment) => value.inProgress === false))
            .min(1, 'Please attach at least 1 file')
            .required('Please attach at least 1 file'),
        amountRequested: Yup.number().required('Amount Requested is required').positive('Amount must be greater than 0').integer(),
        attendanceClients: Yup.number().integer('Must be a whole number').moreThan(-1, 'Number of clients cannot be negative').optional(),
        attendanceEmployees: Yup.number().integer('Must be a whole number').moreThan(-1, 'Number of employees cannot be negative').optional(),

        payableTo: Yup.string().required('Payable To is required'),
        mailingAddress: Yup.string().required('Mailing Address is required'),

        comments: Yup.string().max(2000, 'Comments cannot be more than 2,000 characters'),
        action: Yup.string().required()
    }), values => values.action !== 'save-draft'), []);

    const initialValues = copyInto({
        formStatus: FormStatus.Draft,
        preApprovalFormId,
        advisorUserId: preApprovalFormQuery.data?.advisorUserId || '',
        date: new Date(),
        expenseType: preApprovalFormQuery.data?.expenseType || '',
        attachments: [],
        amountRequested: 0,
        attendanceClients: 0,
        attendanceEmployees: 0,
        payableTo: '',
        mailingAddress: '',
        comments: '', // Only visible when sent back for advisor revision
        action: ''
    }, form);
    initialValues.attachments = attachmentsToAttachmentItems(form?.attachments);

    const onSubmit = useCallback(async (values: any, actions: FormikHelpers<any>) => 
    {
        const { action, ...formData } = values;
        await onSave?.(formData, action);
    }, [form, onSave]);

    const advisorOptions = (usersQuery.data ?? [])
        .filter(user => user.role === UserRole.Advisor && hasProcessFlow(user, 'VPIC'))
        .map(user => { return { key: user.id, text: `${user.name} (${user.accountName})` } });
    const getAdvisor = (advisorUserId: string) => usersQuery.data?.find(u => u.id === advisorUserId);

    return (
        <>
        {
            isLoaded &&
            <Formik 
                initialValues={initialValues} 
                onSubmit={onSubmit}
                validate={onValidate}>
                { formProps => (
                    <Form style={s}>
                        <VPIDropdownField name="advisorUserId" label="Advisor" options={advisorOptions} required placeholder="Select..." disabled={!!preApprovalFormId} noItemsLabel="No advisors found" />

                        <VPIDisplayTextBox name="date" label="Date" value={format(formProps.values.date, 'PP')} />
                        <VPIDisplayTextBox name="telephone" label="Telephone" value={getAdvisor(formProps.values.advisorUserId)?.telephone} />
                        <VPIDisplayTextBox name="city" label="City" value={getAdvisor(formProps.values.advisorUserId)?.city} />
                        <VPIDisplayTextBox name="province" label="Province" value={getAdvisor(formProps.values.advisorUserId)?.province} />

                        <VPIChoiceField name="expenseType" label="Expense Type" allowOther otherLabel="Other (specify below)" required disabled={!!preApprovalFormId} options={[
                            { key: 'Client Events', text: 'Client Events' }
                        ]} />

                        <FormSectionHeader gridArea="expenseDetailsHeader">
                            <h3>Expense Details</h3>
                            <p>Please attach invoices and itemized summary of expenses, as well as a copy of the approved Cost Sharing Request Pre-Approval form or pre-approval email. If this is not attached, requests for reimbursement will be returned.</p>
                        </FormSectionHeader>
                        <VPIAttachmentField name="attachments" multiple buttonLabel="Attach Documents" required formId={form?.id} />
                        <VPICurrencyField name="amountRequested" label="Amount Requested" required />

                        <FormSectionHeader gridArea="attendanceInfoHeader">
                            <h3>Event Attendance (if applicable)</h3>
                        </FormSectionHeader>
                        <VPITextField name="attendanceClients" label="Clients" type="number" placeholder="Number of clients" minimum={0} />
                        <VPITextField name="attendanceEmployees" label="Employees" type="number" placeholder="Number of employees" minimum={0} />

                        <FormSectionHeader gridArea="paymentHeader">
                            <h3>Payment</h3>
                            <p>Make cheque payable to (Please state your name along with your mailing address)</p>
                        </FormSectionHeader>

                        <VPICalculatedField name="payableTo" label="Payable to..." calculation={() => {
                            return getAdvisor(formProps.values.advisorUserId)?.name || '';
                        }} dependencies={[formProps.values.advisorUserId]} required save />
                        <VPICalculatedField name="mailingAddress" label="Mailing Address" calculation={() => {
                            const advisor = getAdvisor(formProps.values.advisorUserId);
                            return addressToString(advisor);
                            //return advisor ? `${advisor.address}, ${advisor.city}, ${advisor.province}, ${advisor.postalCode}` : '';
                        }} dependencies={[formProps.values.advisorUserId]} required save />

                        <CertificationForm formProps={formProps} advisor={getAdvisor(formProps.values.advisorUserId)} allowComments={allowComments} />
                    </Form>
                )}
                
            </Formik>
        }
        { !isLoaded && <FormShimmer /> }
        </>
    );
};

const CertificationForm = (props: { formProps: FormikProps<any>, advisor?: User, allowComments: boolean }) =>
{
    const { formProps, advisor, allowComments } = props;
    const { user } = useUserContext();
    const canSubmit = hasPermission(user, 'vpic.submit');
    const tooltipId = useId('tooltip');

    return (
        <div style={{ gridArea: 'certificationForm' }}>
            <hr />

            <h4>Certification</h4>
            <p>I/We hereby certify that the expense(s) noted above is/are eligible for reimbursement and all the necessary details have been truly and accurately disclosed.</p>

            <Flex gap="10px" align="normal" justify="normal" column>
                <VPIDisplayTextBox name="certificationName" label="Advisor Name/Signature" value={advisor?.name} />
                <VPIDisplayTextBox name="certificationDate" label="Date" value={format(new Date(), 'PP')} />
                {
                    allowComments &&
                    <VPITextField name="comments" label="Comments" description="Please include any changes you have made to your request" disabled={formProps.isSubmitting} multiline rows={4} />
                }
            </Flex>

            <hr />
            <div style={{ display: 'flex', gap: '10px', justifyContent: 'flex-end' }}>
                <DefaultButton text="Save as Draft" disabled={formProps.isSubmitting} onClick={() => {
                    formProps.setFieldValue('action', 'save-draft');
                    // Form fails validation if submitted immediately after setting value
                    setTimeout(() => formProps.submitForm());
                }}  />
                <TooltipHost
                    content={canSubmit ? '' : 'You must be an advisor to submit'}
                    delay={TooltipDelay.zero}
                    id={tooltipId}>
                    <PrimaryButton text="Send to VPI Compliance" disabled={formProps.isSubmitting || !canSubmit} aria-describedby={tooltipId} onClick={() => {
                        formProps.setFieldValue('action', 'submit');
                        // Form fails validation if submitted immediately after setting value
                        setTimeout(() => formProps.submitForm());
                    }} />
                </TooltipHost>
            </div>
        </div>
    );
};