import React, { useCallback, useEffect } from "react";
import * as Yup from "yup";
import { Formik, Form, FormikHelpers, FormikProps } from "formik";
import { VPITextField } from "../../fields/VPITextField";
import { FormCoopReimbursement } from "../../data/models/FormCoopReimbursement";
import { attachmentsToAttachmentItems, copyInto, omit, validateWhen } from "../../utilities/FormUtils";
import { VPICurrencyField } from "../../fields/VPICurrencyField";
import { GetForm } from "../../data/queries/FormQueries";
import { DefaultButton, PrimaryButton, TooltipDelay, TooltipHost } from "@fluentui/react";
import { useId } from "@fluentui/react-hooks";
import { FormStatus } from "../../data/models/Form";
import { useUserContext } from "../../hooks/useUserContext";
import { Constants, FormGridStyles } from "../../constants/Constants";
import { hasPermission, hasProcessFlow, hasRole, User, UserRole } from "../../data/models/User";
import { VPICalculatedField } from "../../fields/VPICalculatedField";
import { format, isDate } from "date-fns";
import { FormShimmer } from "../../helpers/Shimmer";
import { QueryKeys } from "../../data/QueryKeys";
import { useQuery } from "react-query";
import { FormCoopPreApproval } from "../../data/models/FormCoopPreApproval";
import { GetAdvisors } from "../../data/queries/UserQueries";
import { VPIDropdownField } from "../../fields/VPIDropdownField";
import { useSearchParams } from "react-router-dom";
import { VPIDisplayTextBox } from "../../fields/VPIDisplayTextBox";
import { FormSectionHeader } from "../../components/FormSectionHeader";
import { VPIAttachmentField, VPIFieldAttachment } from "../../fields/VPIAttachmentField";

import "./PrintStyles.scss";
import { Flex } from "../../components/Flex";
import { usePrevious } from "../../hooks/usePrevious";

export interface CoopReimbursementFormProps
{
    form?: FormCoopReimbursement;
    onSave?: (formData: Partial<FormCoopReimbursement>, action: 'save-draft' | 'submit') => void | Promise<void>;
    allowComments?: boolean;
}

export const CoopReimbursementForm = (props: CoopReimbursementFormProps) => 
{
    const { form = null, onSave, allowComments = false } = props;
    const [searchParams] = useSearchParams();
    const preApprovalFormId = form?.preApprovalFormId || searchParams.get('p') || '';
    const preApprovalFormQuery = useQuery<FormCoopPreApproval>(QueryKeys.CoopPreApproval.Form(preApprovalFormId), () => GetForm('coop-preapproval', preApprovalFormId), { enabled: !!preApprovalFormId });
    const usersQuery = useQuery<User[]>(QueryKeys.User.All(), () => GetAdvisors());
    const isLoaded = (preApprovalFormQuery.isSuccess || !preApprovalFormId) && usersQuery.isSuccess

    const advisorOptions = (usersQuery.data ?? [])
        .filter(user => user.role === UserRole.Advisor && hasProcessFlow(user, 'Co-op'))
        .map(user => { return { key: user.id, text: `${user.name} (${user.accountName})` } });
    const getAdvisor = (advisorUserId: string) => usersQuery.data?.find(u => u.id === advisorUserId);

    const s: React.CSSProperties = Object.assign({}, FormGridStyles, {
        gridTemplateAreas: `
            'advisorUserId advisorUserId advisorUserId'
            'date dealerName .'
            'repCode location amountRequested'
            'attendanceHeader attendanceHeader attendanceHeader'
            'attendanceClients attendanceEmployees .'
            'invoiceHeader invoiceHeader invoiceHeader'
            'attachments attachments attachments'
            'paymentHeader paymentHeader paymentHeader'
            'payableTo mailingAddress mailingAddress'
            'certificationForm certificationForm certificationForm'
        `
    });

    const onValidate = useCallback(validateWhen(Yup.object({
        formStatus: Yup.string().required(),
        advisorUserId: Yup.string().required(),
        date: Yup.date().required(),

        dealerName: Yup.string(),
        
        location: Yup.string().required('Please enter a location'),
        amountRequested: Yup.number().integer().positive('Amount must be greater than 0').required('Amount is required'),

        attendanceClients: Yup.number().integer('Must be a whole number').moreThan(-1, 'Number of clients cannot be negative').required('Please enter the number of clients'),
        attendanceEmployees: Yup.number().integer('Must be a whole number').moreThan(-1, 'Number of employees cannot be negative').required('Please enter the number of employees'),

        attachments: Yup.array().ensure()
            .of(Yup.mixed().test('size', 'The file is too large', (value: VPIFieldAttachment) => value.size <= Constants.MAX_FILE_SIZE))
            .of(Yup.mixed().test('inProgress', 'Please wait for all files to finish uploading', (value: VPIFieldAttachment) => value.inProgress === false))
            .min(1, 'Please attach at least 1 file')
            .required('Please attach at least 1 file'),

        payableTo: Yup.string().required('Payable To is required'),
        mailingAddress: Yup.string().required('Mailing Address is required'),

        externalDealer: Yup.boolean(),
        comments: Yup.string().max(2000, 'Comments cannot be more than 2,000 characters'),

        action: Yup.string().required()
    }), values => values.action !== 'save-draft'), []);

    const initialValues = copyInto({
        formStatus: FormStatus.Draft,
        preApprovalFormId,
        advisorUserId: preApprovalFormQuery.data?.advisorUserId || '',
        date: new Date(),
        dealerName: '',
        location: '',
        amountRequested: 0,
        attendanceClients: 0,
        attendanceEmployees: 0,
        attachments: [],
        payableTo: '',
        mailingAddress: '',
        dealerForm: [],
        externalDealer: false,
        comments: '', // Only visible when sent back for advisor revision
        action: ''
    }, form);
    initialValues.attachments = attachmentsToAttachmentItems(form?.attachments);
    
    const onSubmit = useCallback(async (values: any, actions: FormikHelpers<any>) => 
    {
        const { action, ...formData } = values;
        await onSave?.(formData, action);
    }, [form, onSave]);

    return (
        <>
        <div className="form-header">
            <div className="form-logo">
                <img src="/images/vpi-logo.png" />
            </div>
            <div className="form-title">
                <div className="form-title-text">Co-op Marketing Request</div>
                <div className="form-title-subtext">Reimbursement Form</div>
            </div>
        </div>
        <hr className="print-only" />
        {
            isLoaded &&
            <Formik 
                initialValues={initialValues} 
                onSubmit={onSubmit}
                validate={onValidate}>
                { formProps => {
                    const advisor = getAdvisor(formProps.values.advisorUserId);
                    // eslint-disable-next-line
                    const prevType = usePrevious(advisor?.dealerType || '');

                    // eslint-disable-next-line
                    useEffect(() => {
                        formProps.setFieldValue('externalDealer', advisor?.dealerType === 'External');

                        if(advisor?.dealerType === 'External' && prevType === 'LP')
                        {
                            //formProps.setFieldValue('dealerEmail', '');
                            formProps.setFieldValue('payableTo', '');
                            formProps.setFieldValue('mailingAddress', '');
                        }
                        else if(advisor?.dealerType === 'LP' && prevType === 'External')
                        {
                            //formProps.setFieldValue('dealerEmail', 'ADudley@lpfinancial.ca');
                            formProps.setFieldValue('payableTo', 'LP Financial');
                            formProps.setFieldValue('mailingAddress', '1000-305 Broadway, Winnipeg, MB R3C 3J7');
                        }
                    }, [formProps.values.advisorUserId]);

                    return (
                    <Form style={s}>
                        <VPIDropdownField name="advisorUserId" label="Advisor" options={advisorOptions} required placeholder="Select..." disabled={!!preApprovalFormId} noItemsLabel="No advisors found" />

                        <VPIDisplayTextBox name="date" label="Date" value={isDate(formProps.values.date) ? format(formProps.values.date, 'PP') : 'No date set'} />
                        <VPICalculatedField name="dealerName" label="Dealer Name" save dependencies={[advisor]} calculation={() => advisor?.dealerName} />

                        <VPICalculatedField name="repCode" label="Rep Code" save dependencies={[preApprovalFormQuery.data]} calculation={() => {
                            return preApprovalFormId ? preApprovalFormQuery.data?.repCode || '(None)' : 'N/A';
                        }} />

                        <VPITextField name="location" label="Location/Branch Office" required />
                        <VPICurrencyField name="amountRequested" label="Amount Requested" required />

                        <FormSectionHeader gridArea="attendanceHeader"><h3>Attendance Information (Actual)</h3></FormSectionHeader>
                        <VPITextField name="attendanceClients" label="Clients" type="number" required minimum={0} />
                        <VPITextField name="attendanceEmployees" label="Employees" type="number" required minimum={0} />

                        <FormSectionHeader gridArea="invoiceHeader">
                            <h3>Invoices</h3>
                            <p>Please attach invoices and itemized summary of expenses.</p>
                        </FormSectionHeader>
                        <VPIAttachmentField name="attachments" multiple buttonLabel="Attach Documents" required formId={form?.id} />

                        <FormSectionHeader gridArea="paymentHeader">
                            <h3>Payment</h3>
                            <p>Make cheque payable to (Please state the name of your Dealer along with the mailing address)</p>
                        </FormSectionHeader>

                        {
                            advisor?.dealerType === 'LP' ?
                            <>
                                <VPICalculatedField name="payableTo" label="Payable to..." required save calculation={() => 'LP Financial'} dependencies={[formProps.values.advisorUserId]} />
                                <VPICalculatedField name="mailingAddress" label="Mailing Address" required save calculation={() => '1000-305 Broadway, Winnipeg, MB R3C 3J7'} dependencies={[formProps.values.advisorUserId]} />
                            </>
                            :
                            <>
                                <VPITextField name="payableTo" label="Payable to..." required />
                                <VPITextField name="mailingAddress" label="Mailing Address" required />
                            </>
                        }

                        {
                            advisor?.dealerType === 'LP' &&
                            <LPDealerCertificationForm formProps={formProps} advisor={advisor} allowComments={allowComments} />
                        }
                        {
                            advisor?.dealerType?.length! > 0 &&
                            advisor?.dealerType !== 'LP' &&
                            <ExternalDealerCertificationForm formProps={formProps} advisor={advisor} allowComments={allowComments} formId={form?.id} />
                        }

                        {
                            !formProps.values.advisorUserId &&
                            <div style={{ gridArea: 'certificationForm', textAlign: 'right' }}>
                                <hr />
                                <p>Please select an advisor to continue</p>
                            </div>
                        }
                    </Form>);
                }}
            </Formik>            
        }
        { !isLoaded && <FormShimmer /> }
        </>
    );
};

const LPDealerCertificationForm = (props: { formProps: FormikProps<any>, advisor?: User, allowComments: boolean }) =>
{
    const { formProps, advisor, allowComments } = props;
    const { user } = useUserContext();
    const canSubmit = hasPermission(user, 'coop.submit');
    const submitToCompliance = hasRole(user, UserRole.Compliance, UserRole.Finance);
    const tooltipId = useId('tooltip');

    return (
        <div className="certification-form" style={{ gridArea: 'certificationForm' }}>
            <hr />

            <h4>Certification</h4>
            <p>I certify that the above request is in compliance with all applicable regulations including National Instrument 81-105 Mutual Fund Sales Practices.</p>

            <Flex gap="15px" align="normal" justify="normal" column>
                <VPIDisplayTextBox name="certificationName" label="Advisor Name/Signature" value={advisor?.name} />
                <VPIDisplayTextBox name="certificationDate" label="Date" value={format(new Date(), 'PP')} />
                {
                    allowComments &&
                    <VPITextField name="comments" label="Comments" description="Please include any changes you have made to your request" disabled={formProps.isSubmitting} multiline rows={4} />
                }
            </Flex>

            <hr />
            <div style={{ display: 'flex', gap: '15px', justifyContent: 'flex-end' }}>
                <DefaultButton text="Save as Draft" disabled={formProps.isSubmitting} onClick={() => {
                    formProps.setFieldValue('action', 'save-draft');
                    // Form fails validation if submitted immediately after setting value
                    setTimeout(() => formProps.submitForm());
                }}  />
                <TooltipHost
                    content={canSubmit ? '' : 'You must be an advisor to submit'}
                    delay={TooltipDelay.zero}
                    id={tooltipId}>
                    <PrimaryButton text={submitToCompliance ? 'Send to VPI Compliance' : 'Send to LP Dealer'} disabled={formProps.isSubmitting || !canSubmit} aria-describedby={tooltipId} onClick={() => {
                        formProps.setFieldValue('action', 'submit');
                        // Form fails validation if submitted immediately after setting value
                        setTimeout(() => formProps.submitForm());
                    }} />
                </TooltipHost>
            </div>
        </div>
    );
};


const ExternalDealerCertificationForm = (props: { formProps: FormikProps<any>, advisor?: User, allowComments: boolean, formId: string | undefined }) =>
{
    const { formProps, advisor, allowComments, formId } = props;
    const { user } = useUserContext();
    const canSubmit = hasPermission(user, 'coop.submit') && formProps.values.dealerForm?.length > 0;
    const tooltipId = useId('tooltip');

    let tooltipMessage = '';
    if(!hasPermission(user, 'coop.submit'))
        tooltipMessage = 'You must be an advisor to submit';
    else if(!(formProps.values.dealerForm?.length > 0))
        tooltipMessage = 'Please attach a signed copy of the form to continue';

    return (
        <div className="certification-form" style={{ gridArea: 'certificationForm' }}>
            <hr />
            <h3>Certification</h3>
            <p>I certify that the above request is in compliance with all applicable regulations including National Instrument 81-105 Mutual Fund Sales Practices.</p>

            <Flex gap="15px" align="normal" justify="normal" column>
                <VPIDisplayTextBox name="certificationName" label="Advisor Name/Signature" value={advisor?.name} />
                <VPIDisplayTextBox name="certificationDate" label="Date" value={format(new Date(), 'PP')} />
                <VPIDisplayTextBox name="dealerName" label="Dealer Name/Signature" value="" />
                <VPIDisplayTextBox name="dealerDate" label="Date" value="" />
            </Flex>

            <div className="hide-print">
                <hr />
                <h3>Send to External Dealer</h3>
                <p>For External Dealer approval, please print and sign this approval form then attach using the button below.</p>
                <Flex gap="15px">
                    <DefaultButton text="Print Approval Form" iconProps={{ iconName: 'Print' }} onClick={() => window.print()} />
                </Flex>
                <Flex gap="15px" style={{ margin: '15px 0' }} column align="normal" justify="normal">
                    <VPIAttachmentField name="dealerForm" buttonLabel="Attach Signed Form" formId={formId} />
                    {
                        allowComments && 
                        <div className="hide-print">
                            <VPITextField name="comments" label="Comments" description="Please include any changes you have made to your request" disabled={formProps.isSubmitting} multiline rows={4} />
                        </div>
                    }
                </Flex>
                <Flex gap="10px" justify="flex-end">
                    <DefaultButton text="Save as Draft" disabled={formProps.isSubmitting} onClick={() => {
                        formProps.setFieldValue('action', 'save-draft');
                        setTimeout(() => formProps.submitForm());
                    }} />

                    <TooltipHost
                        content={tooltipMessage}
                        delay={TooltipDelay.zero}
                        id={tooltipId}>
                        <PrimaryButton text="Send to VPI Compliance" disabled={formProps.isSubmitting || !canSubmit} aria-describedby={tooltipId} onClick={() => {
                            formProps.setFieldValue('action', 'submit');
                            // Form fails validation if submitted immediately after setting value
                            setTimeout(() => formProps.submitForm());
                        }} />
                    </TooltipHost>
                </Flex>
            </div>
        </div>
    );
};
