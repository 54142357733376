import { Navigate, Route, Routes } from 'react-router-dom';
import { Footer } from './components/Footer';
import { Header } from './components/Header';
import { AdvisorDashboard } from './pages/AdvisorDashboard';
import { LoginPage } from './pages/auth/LoginPage';
import { LogoutPage } from './pages/auth/LogoutPage';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import { AppRoutes } from './constants/AppRoutes';
import { NotFound } from './pages/NotFound';
import { useUserContext } from './hooks/useUserContext';

import { CoopPreApprovalWorkflow } from './pages/coopPreApproval/CoopPreApprovalWorkflow';
import { CoopReimbursementWorkflow } from './pages/coopReimbursement/CoopReimbursementWorkflow';
import { VPPWPreApprovalWorkflow } from './pages/vppwPreApproval/VPPWPreApprovalWorkflow';
import { VPPWReimbursementWorkflow } from './pages/vppwReimbursement/VPPWReimbursementWorkflow';
import { VPICPreApprovalWorkflow } from './pages/vpicPreApproval/VPICPreApprovalWorkflow';
import { VPICReimbursementWorkflow } from './pages/vpicReimbursement/VPICReimbursementWorkflow';

import './App.scss';
import { CallbackPage } from './pages/auth/CallbackPage';
import { CardContent } from './components/CardContent';
import { Spinner, SpinnerSize } from '@fluentui/react';
import { Card } from './components/Card';

export const App = () =>
{
    const { user } = useUserContext();

    return (
        <div className="App">
            <Routes>
                <Route path="/auth/login" element={<LoginPage />} />
                <Route path="/auth/logout" element={<LogoutPage />} />
                <Route path="/auth/callback" element={<CallbackPage />} />
                <Route path="*" element={(() => {
                    return (
                        <>
                            <AuthenticatedTemplate>
                                <Header />
                                { user &&
                                    <Routes>
                                        <Route path="/dashboard" element={<AdvisorDashboard />} />
                                        <Route path="/forms/coop-preapproval/:formId" element={<CoopPreApprovalWorkflow />} />
                                        <Route path="/forms/coop-reimbursement/:formId" element={<CoopReimbursementWorkflow />} />
                                        <Route path="/forms/vppw-preapproval/:formId" element={<VPPWPreApprovalWorkflow />} />
                                        <Route path="/forms/vppw-reimbursement/:formId" element={<VPPWReimbursementWorkflow />} />
                                        <Route path="/forms/vpic-preapproval/:formId" element={<VPICPreApprovalWorkflow />} />
                                        <Route path="/forms/vpic-reimbursement/:formId" element={<VPICReimbursementWorkflow />} />
                                        <Route path="/" element={<Navigate to="/dashboard" />} />
                                        <Route path="*" element={<NotFound />} />
                                    </Routes>
                                }
                                { !user && 
                                    <div className="app-loader">
                                        <Card className="app-loader-card">
                                            <CardContent>
                                                <Spinner size={SpinnerSize.large} />
                                                <div style={{ marginTop: '20px' }}>Loading your requests</div>
                                            </CardContent>
                                        </Card>
                                    </div>
                                }
                                <Footer />
                            </AuthenticatedTemplate>
                            <UnauthenticatedTemplate>
                                <Navigate to={AppRoutes.Login(window.location.href.replace(window.location.origin, ''))} />
                            </UnauthenticatedTemplate>
                        </>
                    )
                })()}
                />
            </Routes>
        </div>
    );
}
