import { Form, FormType } from "./Form";

export enum UserRole {
    Advisor = 1,
    Assistant = 2,
    Dealer = 3,
    Compliance = 4,
    Finance = 5,
    Administrator = 6,
    Accounting = 7,
    VPPWCompliance = 8,
    VPPWBusiness = 9,
    VPPWFinance = 10
};

export interface User {
    id: string; // User ID (GUID) in Dataverse
    b2cUserId: string; // User ID (GUID) in Azure AD B2C

    firstName: string;
    lastName: string;
    email: string;
    dealerName: string;

    processFlow: string; // 'Co-op' | 'VPPW' | 'VPIC';
    dealerType: 'LP' | 'External';
    advisorId: string; // Only non-null for advisors

    accountId: string; // team ID
    accountName: string; // team name

    name: string; // full name
    role: UserRole; // set by API?

    address: string;
    telephone: string;
    fax: string;
    city: string;
    province: string;
    postalCode: string;

    createdOn: string;
}

export type UserPermissions =
    'coop.read' |
    'coop.write' |
    'coop.submit' |
    'coop.approveDealer' |
    'coop.approveCompliance' |
    'coop.approveFinance' |
    'vpic.read' |
    'vpic.write' |
    'vpic.submit' |
    'vppw.read' |
    'vppw.write' |
    'vppw.submit'
;

export function hasPermissionToForm(user: User, form: Form, permission: 'read' | 'write' | 'submit')
{
    let retval = false;

    const equals = (type: FormType, processFlow: string) =>
    {
        switch(type)
        {
            case 'coop-preapproval':
            case 'coop-reimbursement':
                return processFlow.toLowerCase().indexOf('co-op') >= 0;
            case 'vpic-preapproval':
            case 'vpic-reimbursement':
                return processFlow.toLowerCase().indexOf('vpic') >= 0;
            case 'vppw-preapproval':
            case 'vppw-reimbursement':
                return processFlow.toLowerCase().indexOf('vppw') >= 0;
            default:
                return false;
        }
    }

    if(user.role === UserRole.Administrator)
        return true;
    else if(user.role === UserRole.Compliance
        || user.role === UserRole.Finance)
        return form.type.startsWith('coop') || form.type.startsWith('vpic');
    else if(user.role === UserRole.VPPWCompliance
        || user.role === UserRole.VPPWFinance)
        return form.type.startsWith('vppw');
    else if(user.role === UserRole.Dealer)
        retval = form.type.startsWith('coop');
    else if(user.role === UserRole.VPPWBusiness)
        retval = form.type.startsWith('vppw');
    else if(user.role === UserRole.Accounting)
        retval = permission === 'read';
    else if(user.role === UserRole.Advisor)
    {
        retval = user.accountId === form.accountId &&
            equals(form.type, user.processFlow) &&
            ['read', 'write', 'submit'].some(p => p === permission);
    }
    else if(user.role === UserRole.Assistant)
    {
        retval = user.accountId === form.accountId &&
            equals(form.type, user.processFlow) &&
            ['read', 'write'].some(p => p === permission);
    }

    return retval;
}

export const hasRole = (user: User | undefined, ...roles: UserRole[]) =>
{
    for(const role of roles)
        if(user?.role === role)
            return true;

    return user?.role === UserRole.Administrator;
};

export const hasPermission = (user: User | undefined, ...permissions: Array<UserPermissions>) =>
{
    if(!user)
        return false;

    if(user.role === UserRole.Administrator)
        return true;

    if(user.role === UserRole.Compliance
        || user.role === UserRole.Finance)
        return permissions.some(p => p.startsWith('coop') || p.startsWith('vpic'));

    if(user.role === UserRole.VPPWCompliance
        || user.role === UserRole.VPPWFinance)
        return permissions.some(p => p.startsWith('vppw'));

    let retval = false;
    retval = hasRole(user, ...permissions.filter(p => typeof p === 'number') as any[]);

    const permissionStrings = permissions.filter(p => typeof p === 'string') as UserPermissions[];

    // Only take ProcessFlow into account if user is an advisor or assistant
    if(user.role === UserRole.Advisor || user.role === UserRole.Assistant)
    {
        for(const permission of permissionStrings)
        {
            if(permission.startsWith('coop'))
            {
                switch(permission)
                {
                    case 'coop.read':
                    case 'coop.write':
                        retval = retval || hasProcessFlow(user, 'Co-op');
                        break;
                    case 'coop.submit':
                        retval = retval || (hasProcessFlow(user, 'Co-op') && user.role === UserRole.Advisor);
                }
            }
            else if(permission.startsWith('vpic'))
            {
                switch(permission)
                {
                    case 'vpic.read':
                    case 'vpic.write':
                        retval = retval || hasProcessFlow(user, 'VPIC');
                        break;
                    case 'vpic.submit':
                        retval = retval || (hasProcessFlow(user, 'VPIC') && user.role === UserRole.Advisor);
                        break;
                }
            }
            else if(permission.startsWith('vppw'))
            {
                switch(permission)
                {
                    case 'vppw.read':
                    case 'vppw.write':
                        retval = retval || hasProcessFlow(user, 'VPPW');
                        break;
                    case 'vppw.submit':
                        retval = retval || (hasProcessFlow(user, 'VPPW') && user.role === UserRole.Advisor);
                        break;
                }
            }
        }
    }

    return retval;
}

export const hasProcessFlow = (user: User, processFlow: 'Co-op' | 'VPPW' | 'VPIC') => 
{
    return !!user.processFlow && user.processFlow.split(',').some(p => {
        return p.indexOf(processFlow) >= 0;
    });
};