import { TextField } from "@fluentui/react";
import { FieldHelperProps, useField, useFormikContext } from "formik";
import React, { useEffect, useState } from "react";

export interface VPICalculatedField
{
    name: string;
    label?: string;
    type?: 'text' | 'email' | 'number';
    multiline?: boolean;
    rows?: number;
    required?: boolean;
    disabled?: boolean;
    placeholder?: string;

    save?: boolean;
    dependencies: any[];
    calculation: () => any;
}

export const VPICalculatedField = (props: VPICalculatedField) => 
{
    const { name, label, type, multiline, rows, required, placeholder, disabled = true, save = false, dependencies, calculation } = props;
    const [ field, meta, helpers ] = useField(name);
    const [value, setValue] = useState('');
    const formikContext = useFormikContext();

    useEffect(() => {
        if(save)
            helpers.setValue(calculation() || '');
        else
            setValue(calculation() || '');
    }, [])

    useEffect(() => {
        if(save)
            helpers.setValue(calculation() || '');
        else
            setValue(calculation() || '');
    }, dependencies);

    return (
        <TextField
            type={type}
            label={label}
            multiline={multiline}
            rows={rows}
            required={required}
            disabled={disabled || formikContext.isSubmitting}
            placeholder={placeholder}
            errorMessage={meta.touched ? meta.error : ''}
            className="vpi-display-field"
            styles={{ root: { gridArea: name }}}
            name={field.name}
            onChange={field.onChange}
            onBlur={field.onBlur}
            value={save ? field.value : value}
        />
    );
};
