import { FunctionComponent } from "react"
import "./Footer.scss";

export const Footer: FunctionComponent = (props) => 
{
    return (
        <footer>
            <div>© Value Partners Investments Inc. All Rights Reserved.</div>
        </footer>
    );
}