import axios, { AxiosResponse } from "axios";
import { User } from "../models/User";
import { Team } from "../models/Team";
import { MakeAPIUrl } from "../Urls";
import { axiosInstance } from "./QueryHelpers";

export async function GetCurrentUser()
{
    return (await axiosInstance.get<User>(MakeAPIUrl(`/users/me`))).data;
};

export async function GetUser(userId: string)
{
    return (await axiosInstance.get<User>(MakeAPIUrl(`/users/${userId}`))).data;
}

export async function GetUsers()
{
    return (await axiosInstance.get<User[]>(MakeAPIUrl(`/users`))).data;
}

export async function GetAdvisors()
{
    return (await axiosInstance.get<User[]>(MakeAPIUrl('/users/advisors'))).data;
}