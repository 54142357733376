import { ContextualMenu, Facepile, IContextualMenuItem } from "@fluentui/react";
import { IFacepilePersona } from "@fluentui/react/lib/Facepile";
import { FunctionComponent, useRef } from "react"
import { Link, useNavigate } from "react-router-dom";
import { AppRoutes } from "../constants/AppRoutes";
import { useUserContext } from "../hooks/useUserContext";
import { useToggle } from "../hooks/useToggle";
import "./Header.scss";
import { UserRole } from "../data/models/User";

export const Header: FunctionComponent = (props) => 
{
    const { user } = useUserContext();
    const userMenu = useToggle(false);
    const nav = useNavigate();
    const userFacepileRef = useRef(null);
    const name = user?.name || user?.email || 'Unnamed Account';
    const persona: IFacepilePersona = {
        personaName: name
    };

    const menuItems: IContextualMenuItem[] = [
        { key: 'logout', text: 'Sign Out', onClick: () => { nav(AppRoutes.Logout()) } }
    ];

    let userDetail;
    switch(user?.role)
    {
        case UserRole.Administrator: userDetail = 'VPI Administrator'; break;
        case UserRole.Finance: userDetail = 'VPI Finance'; break;
        case UserRole.Compliance: userDetail = 'VPI Compliance'; break;
        case UserRole.Accounting: userDetail = 'VPI Accounting'; break;
        case UserRole.Dealer: userDetail = 'LP Financial'; break;
        case UserRole.VPPWBusiness: userDetail = 'VPPW Business'; break;
        case UserRole.VPPWCompliance: userDetail = 'VPPW Compliance'; break;
        case UserRole.VPPWFinance: userDetail = 'VPPW Finance'; break;
        case UserRole.Advisor: 
        case UserRole.Assistant:
            userDetail = user?.accountName || 'No Account Name'; break;
        default:
            userDetail = 'Unknown Role';
    }

    return (
        <header>
            <Link className="header-logo" to="/">
                <div className="logo">
                    <img src="/images/vpi-logo-circle-dark.svg" />
                </div>
                <div>Value Partners Investments</div>
            </Link>
            <div className="header-profile" ref={userFacepileRef} onClick={() => userMenu.open()}>
                { user &&
                <>
                    <Facepile personas={[persona]} />
                    <div className="user-details">
                        <div className="user-name">{name}</div>
                        <div className="user-account">{userDetail}</div>
                    </div>
                    <ContextualMenu 
                        items={menuItems}
                        hidden={!userMenu.isOpen}
                        target={userFacepileRef.current}
                        onDismiss={userMenu.close}
                    />
                </>}
            </div>
        </header>
    );
};