import { MessageBar, MessageBarButton, MessageBarType } from "@fluentui/react";
import { FormStatus } from "../data/models/Form";

export interface FormStatusBarProps
{
    status?: FormStatus;
    message?: string;
}

export const FormStatusBar = (props: FormStatusBarProps) => 
{
    const { status } = props;

    const s = {
        root: { marginBottom: '20px' }
    };

    switch(status)
    {
        case FormStatus.Completed:
            return (
                <MessageBar messageBarType={MessageBarType.success} styles={s}>
                    This form has been fully approved
                </MessageBar>
            );
            break;
        case FormStatus.Paid:
            return (
                <MessageBar messageBarType={MessageBarType.success} styles={s}>
                    This form has been fully approved and paid
                </MessageBar>
            );
            break;
        case FormStatus.Withdrawn:
            return (
                <MessageBar messageBarType={MessageBarType.info} styles={s}>
                    This form has been withdrawn
                </MessageBar>
            );
            break;
        case FormStatus.Rejected:
            return (
                <MessageBar messageBarType={MessageBarType.blocked} styles={s}>
                    This form has been rejected
                </MessageBar>
            );
            break;
        default:
            return null;
    }
};
