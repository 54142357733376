import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import { App } from './App';
import reportWebVitals from './reportWebVitals';
import { acquireTokenSilent, msalApp } from "./hooks/useAuth";
import { MsalProvider } from '@azure/msal-react';
import { BrowserRouter } from 'react-router-dom';
import { QueryClientProvider, QueryClient } from 'react-query';
import { initializeIcons, ThemeProvider, PartialTheme } from "@fluentui/react";
import axios from 'axios';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false
        }
    }
});

const theme: PartialTheme = {
    components: {
        DefaultButton: {
            styles: { root: { borderRadius: '20px' } }
        },
        TextField: {
            styles: { 
                fieldGroup: { borderRadius: '5px' }, 
                prefix: { borderRadius: '5px 0 0 5px' }, 
                description: { fontSize: '12px', display: 'block', marginTop: '5px' } 
            }
        },
        Dropdown: {
            styles: { title: { borderRadius: '5px' } }
        },
        TimePicker: {
            //styles: { root: { borderRadius: '5px' } }
        }
    },
    palette: {
    },
    defaultFontStyle: { fontFamily: `Lato, -apple-system, BlankMacSystemFont, Segoe UI, Helvetica Neue, sans-serif` }
}

initializeIcons();

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

axios.interceptors.request.use(async request => {
    const result = await acquireTokenSilent();
    request.headers = {...request.headers, Authorization: `bearer ${result.accessToken}` };
    return request;
});

root.render(
    <React.StrictMode>
        {/* Provides a context containing an instance of PublicClientApplication used for auth */}
        <MsalProvider instance={msalApp}>
            <BrowserRouter>
                <QueryClientProvider client={queryClient}>
                    <ThemeProvider theme={theme} style={{ backgroundColor: '#F4F9FD' }}>
                        <App />
                    </ThemeProvider>
                </QueryClientProvider>
            </BrowserRouter>
        </MsalProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
