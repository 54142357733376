import { Form, Formik } from "formik";
import { format, isDate } from "date-fns";
import { CSSProperties } from "react";
import { useQuery } from "react-query";
import { FormSectionHeader } from "../../components/FormSectionHeader";
import { GetUser } from "../../data/queries/UserQueries";
import { QueryKeys } from "../../data/QueryKeys";
import { VPIDisplayField } from "../../fields/VPIDisplayField";
import { currencyToString } from "../../utilities/FormUtils";
import { FormVppwPreApproval } from "../../data/models/FormVppwPreApproval";
import { VPIAttachmentsDisplayField } from "../../fields/VPIAttachmentsDisplayField";
import { FormStatusBar } from "../../components/FormStatusBar";
import { DetailsGridStyles2Col, DetailsGridStyles3Col } from "../../constants/Constants";

export interface VPPWPreApprovalDetailsProps
{
    form: FormVppwPreApproval;
    variant?: '3column' | '2column';
}

export const VPPWPreApprovalDetails = (props: VPPWPreApprovalDetailsProps) =>
{
    const { form, variant = '3column' } = props;
    const initialValues = form ?? {};
    const userQuery = useQuery(QueryKeys.User.Id(form.advisorUserId), () => GetUser(form.advisorUserId), { enabled: !!form.advisorUserId });

    let s: CSSProperties = Object.assign({}, DetailsGridStyles3Col, {
        gridTemplateAreas: `
            'advisorUserId date .'
            'repCode telephone .'
            'city province .'
            'email email .'
            'expenseType expenseType .'
            'isStandingApproval isStandingApproval .'
            'expenseEstimatedTotalCost expenseAmountRequested eventDate'
            'expenseDetailsHeader expenseDetailsHeader expenseDetailsHeader'
            'attachments attachments .'
            'expenseDetails expenseDetails expenseDetails'
            'attendanceHeader attendanceHeader attendanceHeader'
            'attendanceClients attendanceEmployees .'
            'attendanceAnalysisHeader attendanceAnalysisHeader attendanceAnalysisHeader'
            'totalAttendance totalCost .'
            'costPerPerson . .'
        `
    });

    if(variant === '2column')
    {
        s = Object.assign({}, DetailsGridStyles2Col, {
            gridTemplateAreas: `
                'advisorUserId date'
                'repCode telephone'
                'city province'
                'email email'
                'expenseType expenseType'
                'isStandingApproval isStandingApproval'
                'expenseEstimatedTotalCost expenseAmountRequested'
                'eventDate .'
                'expenseDetailsHeader expenseDetailsHeader'
                'attachments attachments'
                'expenseDetails expenseDetails'
                'attendanceHeader attendanceHeader'
                'attendanceClients attendanceEmployees'
                'attendanceAnalysisHeader attendanceAnalysisHeader'
                'totalAttendance totalCost'
                'costPerPerson .'
            `
        });
    }

    return (
        <>
        <FormStatusBar status={form?.formStatus} />
        <Formik
            initialValues={initialValues}
            onSubmit={() => {}}
            enableReinitialize>
            { formProps => (
                <Form style={s}>
                    <VPIDisplayField name="advisorUserId" label="Advisor Name" render={() => userQuery.data?.name || ''} />
                    <VPIDisplayField name="date" label="Date" render={(date: Date) => isDate(date) ? format(date, 'PP') : '(No date set)' } />
                    <VPIDisplayField name="repCode" label="Rep Code" />
                    <VPIDisplayField name="telephone" label="Telephone" render={() => userQuery.data?.telephone || ''} />
                    <VPIDisplayField name="city" label="City" render={() => userQuery.data?.city || ''} />
                    <VPIDisplayField name="province" label="Province" render={() => userQuery.data?.province || ''} />
                    <VPIDisplayField name="email" label="Email" render={() => userQuery.data?.email || ''} />
                    <VPIDisplayField name="expenseType" label="Expense Type" />

                    {
                        (formProps.values.expenseType === 'Advisor Marketing'
                        || formProps.values.expenseType === 'Practice Management & Technology')
                        &&
                        <VPIDisplayField name="isStandingApproval" label="Is this a standing pre-approval?" render={(value) => {
                            return value ? 'Yes' : 'No';
                        }} />
                    }
                    
                    <VPIDisplayField name="expenseEstimatedTotalCost" label="Estimated Total Cost" render={(value: number) => currencyToString(value)} />
                    <VPIDisplayField name="expenseAmountRequested" label="Amount Requested" render={(value: number) => currencyToString(value)} />
                    <VPIDisplayField name="eventDate" label="Event Date" render={(date: Date) => isDate(date) ? format(date, 'PP') : '(No date set)'} />

                    <FormSectionHeader gridArea="expenseDetailsHeader">
                        <h3>Expense Details</h3>
                        {/* <p>Please describe the nature of the expense including any relevant information such as dates, number of clients and/or staff attending, benefit to our clients, impact to your business, etc. For example, an Agenda listing activities planned along with time allocated for each; copy of presentation(s) and details of topics being discussed at an event.</p> */}
                    </FormSectionHeader>
                    <VPIAttachmentsDisplayField name="attachments" label="Attachments" />
                    <VPIDisplayField name="expenseDetails" label="Details" />

                    <FormSectionHeader gridArea="attendanceHeader">
                        <h3>Attendance Information (Estimated)</h3>
                    </FormSectionHeader>                    
                    <VPIDisplayField name="attendanceClients" label="Clients" />
                    <VPIDisplayField name="attendanceEmployees" label="Employees" />

                    <FormSectionHeader gridArea="attendanceAnalysisHeader">
                        <h3>Attendance Information Analysis</h3>
                    </FormSectionHeader>       
                    <VPIDisplayField name="totalAttendance" label="Total Attendance (Estimate)" render={() => (
                        form.attendanceClients + form.attendanceEmployees).toString()
                    } />
                    <VPIDisplayField name="totalCost" label="Total Cost (Estimate)" render={() => (
                        currencyToString(form.expenseEstimatedTotalCost)
                    )} />
                    <VPIDisplayField name="costPerPerson" label="Cost Per Person (Estimate)" render={() => (
                        (form.attendanceClients + form.attendanceEmployees) > 0 ? currencyToString(form.expenseEstimatedTotalCost / (form.attendanceClients + form.attendanceEmployees)) : 'N/A'
                    )} />
                </Form>
            )}
        </Formik>
        </>
    )
}