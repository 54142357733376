import { Label, TextField } from "@fluentui/react";

export interface VPIDisplayTextBoxProps 
{
    name?: string;
    label?: string;
    value?: any;
}

export const VPIDisplayTextBox = (props: VPIDisplayTextBoxProps) => 
{
    const { name, label = '', value = '' } = props;
    const s = { 
        root: { flex: 1 } 
    };

    return (
        <div style={{ flex: 1, gridArea: name || undefined }}>
            <Label>{label}</Label>
            <div style={{
                color: '#777',
                backgroundColor: 'rgb(243, 242, 241)',
                padding: '8px',
                border: '1px solid #F3F2F1',
                borderRadius: '5px',
                height: '32px'
            }}>
                {value ? value.toString() : value}
            </div>
        </div>
    )
};