import { ReactNode, useCallback } from "react";
import { DefaultButton } from "@fluentui/react";
import { useQuery, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { Card } from "../../components/Card"
import { CardContent } from "../../components/CardContent";
import { CardHeader } from "../../components/CardHeader"
import { Instructions } from "../../components/Instructions";
import { PageContainer } from "../../components/PageContainer";
import { PageHeader } from "../../components/PageHeader";
import { TaskHistoryList } from "../../components/TaskHistoryList";
import { AppRoutes } from "../../constants/AppRoutes";
import { FormStatus } from "../../data/models/Form";
import { FormCoopReimbursement } from "../../data/models/FormCoopReimbursement";
import { TaskOutcome } from "../../data/models/Task";
import { TaskCoopReimbursement } from "../../data/models/TaskCoopReimbursement";
import { GetForm } from "../../data/queries/FormQueries";
import { GetTasks } from "../../data/queries/TaskQueries";
import { QueryKeys } from "../../data/QueryKeys";
import { CoopReimbursementForm } from "./CoopReimbursementForm";
import { FormCardShimmer, DetailsCardShimmer } from "../../helpers/Shimmer";
import { AccountingPaymentStage, ClarificationStage, ComplianceApprovalStage, DealerApprovalStage, FinanceApprovalStage, NoActionsStage, submitForm } from "../../forms/DefaultStages";
import { hasPermission, hasRole, UserRole } from "../../data/models/User";
import { useUserContext } from "../../hooks/useUserContext";
import { Flex } from "../../components/Flex";
import { FormCoopPreApproval } from "../../data/models/FormCoopPreApproval";
import { currencyToString } from "../../utilities/FormUtils";
import { VPIDisplayTextBox } from "../../fields/VPIDisplayTextBox";
import { CoopReimbursementDetails } from "./CoopReimbursementDetails";
import { CoopReimbursementComplianceForm } from "./CoopReimbursementComplianceForm";

const FORM_TYPE = 'coop-reimbursement';
const INSTRUCTIONS_URL = '/instructions/Co-op Marketing - Reimbursement Instructions.pdf';

export const CoopReimbursementWorkflow = () => 
{
    const params = useParams();
    const nav = useNavigate();
    const queryClient = useQueryClient();
    const { user } = useUserContext();
    const formId = params.formId ?? 'new'; // formId is set to the ID or 'new' the user is creating a new form
    const isNewForm = formId === 'new';

    const formQuery = useQuery(QueryKeys.CoopReimbursement.Form(formId), async () => GetForm<FormCoopReimbursement>(FORM_TYPE, formId), { enabled: !isNewForm });
    const taskQuery = useQuery(QueryKeys.CoopReimbursement.Tasks(formId), async () => GetTasks<TaskCoopReimbursement>(FORM_TYPE, formId), { enabled: !isNewForm });
    const preApprovalFormQuery = useQuery(QueryKeys.CoopPreApproval.Form(formQuery.data?.preApprovalFormId || ''), () => GetForm<FormCoopPreApproval>('coop-preapproval', formQuery.data?.preApprovalFormId || ''), { enabled: formQuery.isSuccess && !!formQuery.data.preApprovalFormId });
    const isLoaded = isNewForm || (formQuery.isSuccess && taskQuery.isSuccess && (preApprovalFormQuery.isSuccess || !formQuery.data?.preApprovalFormId));

    let detailsView: ReactNode = <DetailsCardShimmer />;
    let formView: ReactNode = <FormCardShimmer />;

    const form = formQuery.data;
    const tasks = taskQuery.data;
    const preApprovalForm = preApprovalFormQuery.data;

    const refreshPage = useCallback(() => { 
        queryClient.invalidateQueries(QueryKeys.CoopReimbursement.Form(formId)); 
        nav(AppRoutes.Dashboard()); 
    }, [queryClient, formId]);

    const onSaveForm = useCallback(async (formData: Partial<FormCoopReimbursement>, action: 'save-draft' | 'submit') => {
        try
        {
            await submitForm(FORM_TYPE, form?.id, formData, action);
            refreshPage();
        }
        catch(error: any)
        {
            console.log(error);
            alert(JSON.stringify(error.response.data));
        }
    }, [form, refreshPage]);

    if(isLoaded)
    {
        if(isNewForm)
        {
            detailsView = <Instructions url={INSTRUCTIONS_URL} />;
            formView = (
                <>
                    <CardHeader>Co-op - Reimbursement Request</CardHeader>
                    <CardContent>
                        <CoopReimbursementForm onSave={onSaveForm} />
                    </CardContent>
                </>
            );
        }
        // If a form item has already been created and is fetched (i.e. not new)
        else if(form && tasks)
        {
            const currentTask = tasks.find(t => !t.outcome || t.outcome === TaskOutcome.InProgress);
    
            // Set content for left pane / details pane
            switch(form.formStatus)
            {
                case FormStatus.Draft:
                case FormStatus.AdvisorRevision:
                    detailsView = <Instructions url={INSTRUCTIONS_URL} />;
                    break;
                case FormStatus.AdvisorClarification:
                case FormStatus.DealerApproval:
                case FormStatus.DealerClarification:
                case FormStatus.ComplianceApproval:
                case FormStatus.ComplianceClarification:
                case FormStatus.FinanceApproval:
                case FormStatus.Completed:
                    detailsView = <CoopReimbursementDetails form={form} preApprovalForm={preApprovalForm} variant="2column" />
                    break;
                default:
                    detailsView = null;
                    break;
            }
    
            // Set the form view based on form status and user role
            if((form.formStatus === FormStatus.Draft || form.formStatus === FormStatus.AdvisorRevision) && hasPermission(user, 'coop.write'))
                formView = (
                    <>
                        <CardHeader>Co-op - Reimbursement Request</CardHeader>
                        <CardContent>
                            <CoopReimbursementForm form={form} onSave={onSaveForm} allowComments={!!currentTask} />
                        </CardContent>
                    </>
                );
            else if(form.formStatus === FormStatus.AdvisorClarification && hasPermission(user, 'coop.write'))
                formView = <ClarificationStage form={form} task={currentTask} onSave={refreshPage} title="Advisor Clarification Requested" />;
            else if(form.formStatus === FormStatus.DealerClarification && hasRole(user, UserRole.Dealer))
                formView = <ClarificationStage form={form} task={currentTask} onSave={refreshPage} title="Dealer Clarification Requested" />;
            else if(form.formStatus === FormStatus.ComplianceClarification && hasRole(user, UserRole.Compliance))
                formView = <ClarificationStage form={form} task={currentTask} onSave={refreshPage} title="Compliance Clarification Requested" />;
            else if(form.formStatus === FormStatus.DealerApproval && hasRole(user, UserRole.Dealer))
                formView = <CoopReimbursementDealerStage form={form} task={currentTask} preApprovalForm={preApprovalForm} onSave={refreshPage} />
            else if(form.formStatus === FormStatus.ComplianceApproval && hasRole(user, UserRole.Compliance) && currentTask)
                formView = <CoopReimbursementComplianceForm form={form} task={currentTask} preApprovalForm={preApprovalForm} onSave={refreshPage} allowDealerClarification={form.externalDealer === false} />;
            else if(form.formStatus === FormStatus.FinanceApproval && hasRole(user, UserRole.Finance))
                formView = <FinanceApprovalStage form={form} task={currentTask} onSave={refreshPage} allowDealerClarification={form.externalDealer === false} />
            else if(form.formStatus === FormStatus.Completed && hasRole(user, UserRole.Accounting))
                formView = <AccountingPaymentStage form={form} task={currentTask} onSave={refreshPage} />;
            else if(form.formStatus === FormStatus.Paid
                || form.formStatus === FormStatus.Withdrawn 
                || form.formStatus === FormStatus.Rejected)
            {
                formView = (
                    <>
                        <CardHeader>{form.formId} | Co-op - Reimbursement Request</CardHeader>
                        <CardContent>
                            <CoopReimbursementDetails form={form} preApprovalForm={preApprovalForm} />
                        </CardContent>
                    </>
                );
            }
            else 
            {
                formView = <NoActionsStage title="Co-op Reimbursement Request" />;
            }
        }
    }

    return (
        <PageContainer type={detailsView ? '3column' : '2column'}>
            <PageHeader>
                <DefaultButton text="Back" onClick={() => nav(AppRoutes.Dashboard())} />
            </PageHeader>
            { detailsView &&
                <Card style={{ alignSelf: 'flex-start' }}>
                    <CardHeader>{formQuery.data?.formId || 'Details'}</CardHeader>
                    <CardContent>{detailsView}</CardContent>
                </Card>
            }
            <Card>{formView}</Card>
            <Card style={{ alignSelf: 'flex-start' }}>
                <CardHeader>History</CardHeader>
                <TaskHistoryList tasks={tasks?.filter(t => t.outcome && t.outcome !== TaskOutcome.InProgress)} isLoading={taskQuery.isFetching} />
            </Card>
        </PageContainer>
    );
};

interface CoopReimbursementDealerStageProps
{
    form: FormCoopReimbursement;
    task?: TaskCoopReimbursement;
    preApprovalForm?: FormCoopPreApproval;
    onSave: () => void;
}

const CoopReimbursementDealerStage = ({ form, task, preApprovalForm, onSave }: CoopReimbursementDealerStageProps) => 
{
    const s = { root: { flex: 1 } };

    const estimatedAttendance = preApprovalForm ? preApprovalForm.attendanceClients + preApprovalForm.attendanceEmployees : 0;
    const actualAttendance = form.attendanceClients + form.attendanceEmployees;

    return (
        <DealerApprovalStage form={form} task={task} onSave={onSave}>
            <CardHeader>Attendance Information Analysis</CardHeader>
            <CardContent style={{ display: 'flex', gap: '15px', flexDirection: 'column' }}>
                <h3 style={{ margin: '0' }}>Cost</h3>
                <Flex gap="15px">
                    <VPIDisplayTextBox label="Budgeted Cost (Estimate)" value={
                        preApprovalForm ? currencyToString(preApprovalForm.eventEstimatedTotalCost) : 'N/A'
                    } />
                    <VPIDisplayTextBox label="Actual Cost" value={
                        currencyToString(form.amountRequested)
                    } />
                    <VPIDisplayTextBox label="Variance" value={
                        preApprovalForm ? currencyToString(form.amountRequested - preApprovalForm.eventEstimatedTotalCost) : 'N/A'
                    } />
                </Flex>
                <h3 style={{ margin: '15px 0 0 0' }}>Attendance</h3>
                <Flex gap="15px">
                    <VPIDisplayTextBox label="Total Attendance (Estimate)" value={
                         preApprovalForm ? estimatedAttendance.toString() : 'N/A'
                    } />
                    <VPIDisplayTextBox label="Total Attendance (Actual)" value={
                        actualAttendance.toString()
                    } />
                    <VPIDisplayTextBox label="Variance" value={
                        preApprovalForm ? (actualAttendance - estimatedAttendance).toString() : 'N/A'
                    } />
                </Flex>
                <h3 style={{ margin: '15px 0 0 0' }}>Cost Per Person</h3>
                <Flex gap="15px">
                    <VPIDisplayTextBox label="Cost Per Person (Estimate)" value={
                        preApprovalForm && estimatedAttendance > 0 ? currencyToString(preApprovalForm.eventEstimatedTotalCost / estimatedAttendance) : 'N/A'
                    } />
                    <VPIDisplayTextBox label="Cost Per Person (Actual)" value={
                        actualAttendance > 0 ? currencyToString(form.amountRequested / actualAttendance) : 'N/A'
                    } />
                    <VPIDisplayTextBox label="Variance" value={
                        preApprovalForm && estimatedAttendance > 0 && actualAttendance > 0 ? 
                            currencyToString((form.amountRequested / actualAttendance) - (preApprovalForm.eventEstimatedTotalCost / estimatedAttendance)) : 'N/A'
                    } />
                </Flex>
            </CardContent>
        </DealerApprovalStage>
    );
};
