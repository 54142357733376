import React, { useCallback } from "react";
import * as Yup from "yup";
import { PrimaryButton, TooltipDelay, TooltipHost } from "@fluentui/react";
import { Formik, Form, Field, FormikProps, useFormikContext, FormikHelpers } from "formik";
import { FormVppwPreApproval } from "../../data/models/FormVppwPreApproval";
import { VPITextField } from "../../fields/VPITextField";
import { VPIDropdownField } from "../../fields/VPIDropdownField";
import { VPIDateField } from "../../fields/VPIDateField";
import { VPICurrencyField } from "../../fields/VPICurrencyField";
import { FormSectionHeader } from "../../components/FormSectionHeader";
import { attachmentsToAttachmentItems, copyInto, validateWhen } from "../../utilities/FormUtils";
import { DefaultButton } from "@fluentui/react";
import { FormStatus } from "../../data/models/Form";
import { useUserContext } from "../../hooks/useUserContext";
import { VPIChoiceField } from "../../fields/VPIChoiceField";
import { hasPermission, hasProcessFlow, hasRole, User, UserRole } from "../../data/models/User";
import { useId } from "@fluentui/react-hooks";
import { useQuery } from "react-query";
import { QueryKeys } from "../../data/QueryKeys";
import { GetAdvisors } from "../../data/queries/UserQueries";
import { FormShimmer } from "../../helpers/Shimmer";
import { format, isDate, sub } from "date-fns";
import { Constants, FormGridStyles } from "../../constants/Constants";
import { VPIDisplayTextBox } from "../../fields/VPIDisplayTextBox";
import { VPIAttachmentField, VPIFieldAttachment } from "../../fields/VPIAttachmentField";
import { Flex } from "../../components/Flex";
import { VPICheckboxField } from "../../fields/VPICheckboxField";

export interface VPPWPreApprovalFormProps
{
    form?: FormVppwPreApproval;
    onSave?: (formData: Partial<FormVppwPreApproval>, action: 'save-draft' | 'submit') => void | Promise<void>;
    allowComments?: boolean;
}

export const VPPWPreApprovalForm = (props: VPPWPreApprovalFormProps) => 
{
    const { form = null, onSave, allowComments = false } = props;
    const usersQuery = useQuery(QueryKeys.User.All(), () => GetAdvisors());
    const isLoading = usersQuery.isFetching;

    const s: React.CSSProperties = Object.assign({}, FormGridStyles, {
        gridTemplateAreas: `
            'advisorUserId advisorUserId advisorUserId'
            'date repCode telephone'
            'city province .'
            'email email .'
            'expenseType expenseType expenseType'
            'isStandingApproval isStandingApproval .'
            'expenseEstimatedTotalCost expenseAmountRequested eventDate'
            'expenseDetailsHeader expenseDetailsHeader expenseDetailsHeader'
            'expenseDetails expenseDetails expenseDetails'
            'attachments attachments .'
            'attachmentsHeader attachmentsHeader attachmentsHeader'
            'attendanceInfoHeader attendanceInfoHeader attendanceInfoHeader'
            'attendanceClients attendanceEmployees .'
            'certificationForm certificationForm certificationForm'
        `
    });

    const onValidate = useCallback(validateWhen(Yup.object({
        formStatus: Yup.string().required(),
        advisorUserId: Yup.string().required('Please select an advisor'),
        date: Yup.date().required(),
        repCode: Yup.string(),
        
        expenseType: Yup.string().required('Please select an expense type'),
        isStandingApproval: Yup.boolean(),
        expenseEstimatedTotalCost: Yup.number().required('Estimated cost is required').positive('Amount must be greater than 0').integer(),
        expenseAmountRequested: Yup.number().required('Amount Requested is required').positive('Amount must be greater than 0').integer(),
        eventDate: Yup.date()
            .min(sub(new Date(), { days: 1 }), 'Event Date must be in the future')
            .when('expenseType', { is: 'Client Events', then: schema => schema.required('Event Date is required') }),        

        expenseDetails: Yup.string().optional().max(2000, 'Expense Details cannot be longer than 2,000 characters'),

        attachments: Yup.array().ensure()
            .of(Yup.mixed().test('size', 'The file is too large', (value: VPIFieldAttachment) => value.size <= Constants.MAX_FILE_SIZE))
            .of(Yup.mixed().test('inProgress', 'Please wait for all files to finish uploading', (value: VPIFieldAttachment) => value.inProgress === false))
            .min(1, 'Please attach at least 1 file')
            .required('Please attach at least 1 file'),

        attendanceClients: Yup.number().integer('Must be a whole number').moreThan(-1, 'Number of clients cannot be negative').required('Please enter the number of clients'),
        attendanceEmployees: Yup.number().integer('Must be a whole number').moreThan(-1, 'Number of employees cannot be negative').required('Please enter the number of employees'),

        certificationName: Yup.string(),
        certificationDate: Yup.date(),
        comments: Yup.string().max(2000, 'Comments cannot be more than 2,000 characters'),
        action: Yup.string().required()
    }), values => values.action !== 'save-draft'), []);

    const initialValues = copyInto({
        formStatus: FormStatus.Draft,
        advisorUserId: '',
        date: new Date(),
        repCode: '',

        expenseType: '',
        isStandingApproval: false,
        expenseEstimatedTotalCost: 0,
        expenseAmountRequested: 0,
        eventDate: new Date(),

        expenseDetails: '',
        attachments: [],
        attendanceClients: 0,
        attendanceEmployees: 0,

        comments: '', // Only visible when sent back for advisor revision
        action: ''
    }, form);
    initialValues.attachments = attachmentsToAttachmentItems(form?.attachments);

    const onSubmit = useCallback(async (values: any, actions: FormikHelpers<any>) => 
    {
        const { action, ...formData } = values;
        await onSave?.(formData, action);
    }, [form, onSave]);

    const advisorOptions = (usersQuery.data ?? [])
        .filter(user => user.role === UserRole.Advisor && hasProcessFlow(user, 'VPPW'))
        .map(user => { return { key: user.id, text: `${user.name} (${user.accountName})` } });
    const getAdvisor = (advisorUserId: string) => usersQuery.data?.find(u => u.id === advisorUserId);

    return (
        <>
        {
            !isLoading &&
            <Formik 
                initialValues={initialValues} 
                onSubmit={onSubmit}
                validate={onValidate}>
                { formProps => (
                    <Form style={s}>
                        <VPIDropdownField name="advisorUserId" label="Advisor" options={advisorOptions} required placeholder="Select..." noItemsLabel="No advisors found" />
                        <VPIDisplayTextBox label="Date" value={isDate(formProps.values.date) ? format(formProps.values.date, 'PP') : 'No date set'} />
                        <VPITextField name="repCode" label="Rep Code" />

                        <VPIDisplayTextBox name="telephone" label="Telephone" value={getAdvisor(formProps.values.advisorUserId)?.telephone} />
                        <VPIDisplayTextBox name="city" label="City" value={getAdvisor(formProps.values.advisorUserId)?.city} />
                        <VPIDisplayTextBox name="province" label="Province" value={getAdvisor(formProps.values.advisorUserId)?.province} />
                        <VPIDisplayTextBox name="email" label="Email" value={getAdvisor(formProps.values.advisorUserId)?.email} />

                        <VPIChoiceField  name="expenseType" label="Expense Type" required options={[
                            { key: 'Advisor Development & Travel', text: 'Advisor Development & Travel' },
                            { key: 'Advisor Marketing', text: 'Advisor Marketing' },
                            { key: 'Client Events', text: 'Client Events' },
                            { key: 'Practice Management & Technology', text: 'Practice Management & Technology' }
                        ]} />
                        {/* {
                            (formProps.values.expenseType === 'Advisor Marketing'
                            || formProps.values.expenseType === 'Practice Management & Technology')
                            &&
                            <VPIChoiceField name="isStandingApproval" label="Is this a standing pre-approval?" required options={[
                                { key: true, text: 'Yes' },
                                { key: false, text: 'No' },
                            ]} />
                        } */}

                        <VPICurrencyField name="expenseEstimatedTotalCost" label="Estimated Total Cost" placeholder="Estimated cost" required />
                        <VPICurrencyField name="expenseAmountRequested" label="Amount Requested" placeholder="Amount Requested" required />
                        <VPIDateField name="eventDate" label="Event Date" required={formProps.values.expenseType === 'Client Events'} />

                        <FormSectionHeader gridArea="expenseDetailsHeader">
                            <h3>Expense Details</h3>
                            <p>Please describe the nature of the expense including any relevant information such as dates, number of clients and/or staff attending, benefit to our clients, impact to your business, etc.</p>
                        </FormSectionHeader>
                        <VPITextField name="expenseDetails" multiline rows={4} placeholder="Enter expense details" />
                        <VPIAttachmentField name="attachments" multiple buttonLabel="Attach Documents" required formId={form?.id} />
                        <p style={{ gridArea: 'attachmentsHeader' }}>Please attach any supporting documentation. For example, an Agenda listing activities planned along with time allocated for each; copy of presentation(s) and details of topics being discussed at an event.</p>
                        
                        <FormSectionHeader gridArea="attendanceInfoHeader">
                            <h3>Attendance Information (estimate):</h3>
                        </FormSectionHeader>
                        <VPITextField name="attendanceClients" label="Clients" type="number" placeholder="Enter clients" required minimum={0} />
                        <VPITextField name="attendanceEmployees" label="Employees" type="number" placeholder="Enter employees" required minimum={0} />

                        <CertificationForm formProps={formProps} advisor={getAdvisor(formProps.values.advisorUserId)} allowComments={allowComments} />
                    </Form>
                )}
            </Formik>
        }
        { isLoading && <FormShimmer /> }
        </>
    );
};

const CertificationForm = (props: { formProps: FormikProps<any>, advisor?: User, allowComments: boolean }) =>
{
    const { formProps, advisor, allowComments } = props;
    const { user } = useUserContext();
    const canSubmit = hasPermission(user, 'vppw.submit');
    const submitToCompliance = hasRole(user, UserRole.VPPWCompliance, UserRole.VPPWFinance);
    const tooltipId = useId('tooltip');

    return (
        <div style={{ gridArea: 'certificationForm' }}>
            <hr />

            <h4>Certification</h4>
            <p>We hereby certify that the expenses noted above are eligible for reimbursement under the standards outlined in Principal Distributorship Agreement with respects to National Instrument 81-105, Mutual Fund Sales Practices ("The National Instrument").</p>

            <Flex gap="10px" align="normal" justify="normal" column>
                <VPIDisplayTextBox name="certificationName" label="Advisor Name/Signature" value={advisor?.name} />
                <VPIDisplayTextBox name="certificationDate" label="Date" value={format(new Date(), 'PP')} />
                {
                    allowComments && 
                    <div className="hide-print">
                        <VPITextField name="comments" label="Comments" description="Please include any changes you have made to your request" disabled={formProps.isSubmitting} multiline rows={4} />
                    </div>
                }
            </Flex>

            <hr />
            <div style={{ display: 'flex', gap: '10px', justifyContent: 'flex-end' }}>
                <DefaultButton text="Save as Draft" disabled={formProps.isSubmitting} onClick={() => {
                    formProps.setFieldValue('action', 'save-draft');
                    // Form fails validation if submitted immediately after setting value
                    setTimeout(() => formProps.submitForm());
                }}  />
                <TooltipHost
                    content={canSubmit ? '' : 'You must be an advisor to submit'}
                    delay={TooltipDelay.zero}
                    id={tooltipId}>
                    <PrimaryButton text={submitToCompliance ? 'Send to Compliance' : 'Send to Business'} disabled={formProps.isSubmitting || !canSubmit} aria-describedby={tooltipId} onClick={() => {
                        formProps.setFieldValue('action', 'submit');
                        // Form fails validation if submitted immediately after setting value
                        setTimeout(() => formProps.submitForm());
                    }} />
                </TooltipHost>
            </div>
        </div>
    );
};