import { Checkbox, TextField, Text, useTheme, ChoiceGroup, IChoiceGroupOption, IChoiceGroupStyles } from "@fluentui/react";
import { useField, useFormikContext } from "formik";
import React, { useCallback, useEffect, useState } from "react";

export interface Option
{
    key: string | boolean;
    text: string;
    disabled?: boolean;
}

export interface VPIChoiceFieldProps
{
    name: string;
    label?: string;
    otherLabel?: string;
    required?: boolean;
    disabled?: boolean;
    options?: Option[];
    allowOther?: boolean;
    direction?: 'horizontal' | 'vertical';
}

// TODO: fix form reset behaviour

export const VPIChoiceField = (props: VPIChoiceFieldProps) => 
{
    const { name, label, required, disabled, options = [], allowOther = false, otherLabel = 'Other', direction = 'vertical' } = props;
    const [otherValue, setOtherValue] = useState('');
    const [useOtherValue, setUseOtherValue] = useState(false);
    const [field, meta, helpers] = useField(name);
    const formikContext = useFormikContext();
    const theme = useTheme();

    const horizontalStyles: IChoiceGroupStyles = {
        flexContainer: {
            display: 'flex',
            gap: '15px'
        }
    };

    useEffect(() => 
    {
        if(options.find(o => o.key === field.value))
            setUseOtherValue(false);
        else if(allowOther && typeof field.value === 'string' && field.value.length > 0)
        {
            setUseOtherValue(true);
            setOtherValue(field.value);
        }
    }, [field.value]);

    const onChange = useCallback((e: any, key?: string) =>
    {
        if(!key)
        {
            helpers.setValue('');
        }
        else if(options.find(o => o.key.toString() === key))
        {
            setUseOtherValue(false);
            helpers.setValue(options.find(o => o.key.toString() === key)?.key);
        }
        else if(allowOther && key === 'other')
        {
            setUseOtherValue(true);
            helpers.setValue(otherValue);
        }
    }, [helpers, options, allowOther, otherValue]);

    let choiceOptions: any[] = options.map(o => Object.assign({}, o, { key: o.key.toString() }));
    if(allowOther)
        choiceOptions = [...choiceOptions, { key: 'other', text: otherLabel }];
    
    let choiceValue = '';
    if(options.find(o => o.key === field.value))
        choiceValue = field.value.toString();
    else if(allowOther && useOtherValue)
        choiceValue = 'other';

    return (
        <div style={{ gridArea: name }}>
            <ChoiceGroup 
                label={label}
                options={choiceOptions}
                required={required}
                disabled={disabled || formikContext.isSubmitting}
                name={field.name}
                selectedKey={choiceValue}
                onChange={(e, option) => onChange(e, option?.key)}
                onBlur={field.onBlur}
                styles={direction === 'horizontal' ? horizontalStyles : undefined}
            />
            {
                allowOther &&
                <TextField
                    value={otherValue}
                    disabled={disabled || formikContext.isSubmitting || !useOtherValue}
                    onChange={(e, newValue) => setOtherValue(newValue as string)}
                    onBlur={e => onChange(e, 'other')}
                    styles={{ root: { marginTop: '10px' } }}
                />
            }
            { meta.touched && meta.error &&
                <Text style={{ display: 'block', marginTop: '5px' }}  variant="small" styles={{ root: { color: theme.semanticColors.errorText } }}>{meta.error}</Text>
            }
        </div>
    );
}

