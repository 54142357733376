import { Card } from "../components/Card";
import { CardContent } from "../components/CardContent";

export const NotFound = () =>
{
    return (
        <div>
            <Card style={{ width: '320px', margin: '40px auto', textAlign: 'center' }}>
                <CardContent>
                    <div style={{ marginTop: '20px' }}>Page Not Found</div>
                </CardContent>
            </Card>
        </div>
    );
}