
import "./FormSectionHeader.scss";

interface FormSectionHeaderProps
{
    children?: any;
    gridArea: string;
}

export const FormSectionHeader = (props: FormSectionHeaderProps) =>
{
    const { children, gridArea, ...otherProps } = props;

    return (
        <div className="form-section-header" style={{ gridArea }} {...otherProps}>
            <hr />
            {children}
        </div>
    )
};