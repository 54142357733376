import { Attachment } from "../data/models/Attachment";
import { User } from "../data/models/User";
import { VPIFieldAttachment } from "../fields/VPIAttachmentField";
import * as Yup from "yup";
import { yupToFormErrors } from "formik";

export const copyInto = (to: any, from: any) => 
{
    if(to && typeof to === 'object' && from && typeof from === 'object')
    {
        for(const key in to)
            to[key] = from[key] ?? to[key];
    }

    return to;
};

export const omit = (obj: any, keysToOmit: string[]) =>
{
    const retval = Object.assign({}, obj)
    for(const key in retval)
        if(keysToOmit.indexOf(key) >= 0)
            delete retval[key];
    return retval;
};

/**
 * Performs validation using provided Yup schema. Skips validation if shouldValidate() returns false
 */
export const validateWhen = (schema: Yup.ObjectSchema<any>, shouldValidate?: (values: any) => boolean) => 
{
    return async (values: any) => {
        if(shouldValidate === undefined || shouldValidate?.(values))
        {
            try { await schema.validate(values, { abortEarly: false }) }
            catch(e) { return { ...yupToFormErrors(e) } }
        }
        else
        {
            return {};
        }
    };
}

export const currencyToString = (value: number) =>
{
    const formatter = new Intl.NumberFormat('en-CA', { style: 'currency', currency: 'CAD' });
    if(typeof value === 'number' && isFinite(value))
        return formatter.format(value / 100);
    else
        return '';
};

export const attachmentsToAttachmentItems = (attachments?: Attachment[]): VPIFieldAttachment[] =>
{
    return attachments?.map(a => {
        return { id: a.id, filename: a.filename, size: a.size, inProgress: false }
    }) || [];
};

export const addressToString = (user?: User) =>
{
    const notNull = (str: string | undefined) => { return typeof str === 'string' && str.toLowerCase() !== 'null'; };

    return [
        ...(notNull(user?.address) ? [user?.address] : []),
        ...(notNull(user?.city) ? [user?.city] : []),
        ...(notNull(user?.province) ? [user?.province] : []),
        ...(notNull(user?.postalCode) ? [user?.postalCode] : []),
    ].join(', ')
}