
export const Instructions = (props: { url: string }) => 
{
    const { url } = props;

    return (
        <>
            <p style={{ color: 'red' }}>Note: Please view the link below for instructions on how to fill out the form.</p>
            <p><a href={url} target="_blank">View Instructions</a></p>
        </>
    );
};