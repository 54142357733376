
export interface PageContainerProps {
    children: any;
    style?: React.CSSProperties;
    type: '2column' | '3column'
}

export const PageContainer = (props: PageContainerProps) => 
{
    const s: React.CSSProperties = {
        gridTemplateColumns: props.type === '2column' ? '3fr 1fr' : '1fr 2fr 1fr',
        gridTemplateRows: 'auto 1fr',
        gridTemplateAreas: props.type === '2column' ?
        `
            'header header'
            'main right'
        ` :
        `
            'header header header'
            'left main right'
        `,
        ...props.style
    };

    return (
        <main style={s}>
            {props.children}
        </main>
    );
}