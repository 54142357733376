import { FormStatus } from "./models/Form";

export const QueryKeys = {
    User: {
        Current: () => ['user', 'user-current'],
        Id: (userId: string) => ['user', `user-${userId}`],
        All: () => ['user', 'user-all']
    },
    Team: {
        Current: () => ['team', 'team-current'],
        Id: (teamId: string) => ['team', `team-${teamId}`]
    },
    AllForms: (filter?: any) => ['all', 'forms', `filter-${filter ?? 'all'}`],
    AssignedToMe: () => ['forms', 'assigned-to-me'],
    CoopPreApproval: {
        Form: (formId: string) => ['coop-preapproval', `form-${formId}`],
        Forms: () => ['coop-preapproval', `forms`],
        Task: (taskId: string) => ['coop-preapproval', `task-${taskId}`],
        Tasks: (formId: string) => ['coop-preapproval', `form-${formId}`, 'tasks']
    },
    CoopReimbursement: {
        Form: (formId: string) => ['coop-reimbursement', `form-${formId}`],
        Task: (taskId: string) => ['coop-reimbursement', `task-${taskId}`],
        Tasks: (formId: string) => ['coop-reimbursement', `form-${formId}`, 'tasks']
    },
    VPPWPreApproval: {
        Form: (formId: string) => ['vppw-preapproval', `form-${formId}`],
        Task: (taskId: string) => ['vppw-preapproval', `task-${taskId}`],
        Tasks: (formId: string) => ['vppw-preapproval', `form-${formId}`, 'tasks']
    },
    VPPWReimbursement: {
        Form: (formId: string) => ['vppw-reimbursement', `form-${formId}`],
        Task: (taskId: string) => ['vppw-reimbursement', `task-${taskId}`],
        Tasks: (formId: string) => ['vppw-reimbursement', `form-${formId}`, 'tasks']
    },
    VPICPreApproval: {
        Form: (formId: string) => ['vpic-preapproval', `form-${formId}`],
        Task: (taskId: string) => ['vpic-preapproval',`task-${taskId}`],
        Tasks: (formId: string) => ['vpic-preapproval',`form-${formId}`, 'tasks']
    },
    VPICReimbursement: {
        Form: (formId: string) => ['vpic-reimbursement', `form-${formId}`],
        Task: (taskId: string) => ['vpic-reimbursement', `task-${taskId}`],
        Tasks: (formId: string) => ['vpic-reimbursement', `form-${formId}`, 'tasks']
    },
    Form: (formType: string) => {
        return {
            Form: (formId: string) => [formType, `form-${formId}`],
            Task: (taskId: string) => [formType, `task-${taskId}`],
            Tasks: (formId: string) => [formType, `form-${formId}`, 'tasks']
        }
    }
};
