import axios, { AxiosResponse } from "axios";
import { Attachment } from "../models/Attachment";
import { MakeAPIUrl } from "../Urls";
import { axiosInstance } from "./QueryHelpers";

export async function GetAttachments<Attachment>(formType: string, formId: string)
{
    return (await axiosInstance.get<Attachment[]>(MakeAPIUrl(`/forms/${formType}/${formId}/attachments`))).data;
};

export function GetAttachmentContentUrl(attachmentId: string)
{
    return `${MakeAPIUrl(`/attachments/${attachmentId}`)}`;
}

export async function UploadAttachment(fieldName: string, file: File, formId?: string,)
{
    return (await axiosInstance.post<Attachment>(MakeAPIUrl(`/attachments`), await file.arrayBuffer(), {
        params: { 
            filename: file.name, 
            fieldName, 
            formId 
        },
        headers: {
            'content-type': 'application/octet-stream'
        }
    })).data;
}

export async function UpdateAttachment(attachmentId: string, data: Partial<Attachment>)
{
    await axiosInstance.patch(MakeAPIUrl(`/attachments/${attachmentId}`), data);
}

export async function DeleteAttachment(attachmentId: string)
{
    await axiosInstance.delete(MakeAPIUrl(`/attachments/${attachmentId}`));
}
