import { Attachment } from "./Attachment";
import { FormVppwPreApproval } from "./FormVppwPreApproval";
import { hasPermissionToForm, hasRole, User, UserRole } from "./User";

export type FormType = 
    'coop-preapproval'
    | 'coop-reimbursement'
    | 'vppw-preapproval'
    | 'vppw-reimbursement'
    | 'vpic-preapproval'
    | 'vpic-reimbursement'
    | 'invalid';

export enum FormStatus 
{
    Draft = 1,
    DealerApproval = 2,
    ComplianceApproval = 3,
    FinanceApproval = 4,
    Completed = 5,
    Withdrawn = 6,
    Invalid = 7,
    Rejected = 8,
    AdvisorClarification = 9,
    DealerClarification = 10,
    ComplianceClarification = 11,
    Paid = 12,
    AdvisorRevision = 13,
    BusinessApproval = 14,
    BusinessClarification = 15
}

export interface Form {
    id: string;
    formId: string;
    type: FormType;
    formStatus: FormStatus;
    teamId?: string;
    createdOn: Date;
    modifiedOn: Date;
    createdByUserId: string;
    accountId: string;
    advisorUserId: string;
    attachments: Attachment[];
    hasReimbursement: boolean;
    comments: string;
}

export interface ReimbursementForm extends Form {
    preApprovalFormId?: string;
}

export interface FromJson {
    fromJson(data: any): this;
}

export interface ToJson {
    toJson(): any;
}

export function canWithdraw(user: User, form?: Form)
{
    // Reimbursement forms cannot be withdrawn
    // Only advisors can withdraw, and only when form has not been fully approved
    // VPI staff (compliance and finance) can withdraw at any time

    return (
        form?.type.endsWith('preapproval') &&
        (
            (
                hasRole(user, UserRole.Advisor) &&
                hasPermissionToForm(user, form, 'submit') &&
                (
                    form?.formStatus === FormStatus.AdvisorClarification ||
                    form?.formStatus === FormStatus.AdvisorRevision ||
                    form?.formStatus === FormStatus.DealerClarification ||
                    form?.formStatus === FormStatus.ComplianceClarification ||
                    form?.formStatus === FormStatus.BusinessClarification ||
                    form?.formStatus === FormStatus.DealerApproval ||
                    form?.formStatus === FormStatus.BusinessApproval ||
                    form?.formStatus === FormStatus.ComplianceApproval ||
                    form?.formStatus === FormStatus.FinanceApproval
                )
            ) 
            ||
            (
                hasRole(user, UserRole.Compliance, UserRole.Finance, UserRole.Administrator, UserRole.VPPWCompliance, UserRole.VPPWFinance) &&
                (
                    form?.formStatus === FormStatus.AdvisorClarification ||
                    form?.formStatus === FormStatus.AdvisorRevision ||
                    form?.formStatus === FormStatus.DealerClarification ||
                    form?.formStatus === FormStatus.BusinessClarification ||
                    form?.formStatus === FormStatus.ComplianceClarification ||
                    form?.formStatus === FormStatus.DealerApproval ||
                    form?.formStatus === FormStatus.BusinessApproval ||
                    form?.formStatus === FormStatus.ComplianceApproval ||
                    form?.formStatus === FormStatus.FinanceApproval ||
                    form?.formStatus === FormStatus.Completed
                )
            )
        )
    );
}

export function canRequestReimbursement(user: User, form?: Form)
{
    return (
        form?.type.endsWith('preapproval') &&
        form?.formStatus === FormStatus.Completed &&
        (form?.hasReimbursement === false || (form as FormVppwPreApproval)?.isStandingApproval) &&
        hasPermissionToForm(user, form, 'write')
    );
}

export function formTypeToString(formType: FormType)
{
    const mappings = {
        'coop-preapproval': 'Co-op Pre-Approval',
        'coop-reimbursement': 'Co-op Reimbursement',
        'vppw-preapproval': 'VPPW Pre-Approval',
        'vppw-reimbursement': 'VPPW Reimbursement',
        'vpic-preapproval': 'VPIC Pre-Approval',
        'vpic-reimbursement': 'VPIC Reimbursement',
        'invalid': 'Invalid'
    };

    return mappings[formType] ?? formType;
}

export function formStatusToString(formStatus: FormStatus)
{
    const mappings = {
        [FormStatus.Draft]: 'Draft',
        [FormStatus.DealerApproval]: 'Dealer Approval',
        [FormStatus.ComplianceApproval]: 'Compliance Approval',
        [FormStatus.FinanceApproval]: 'Finance Approval',
        [FormStatus.Completed]: 'Approved',
        [FormStatus.Withdrawn]: 'Withdrawn',
        [FormStatus.Invalid]: 'Invalid',
        [FormStatus.Rejected]: 'Rejected',
        [FormStatus.AdvisorClarification]: 'Advisor Clarification',
        [FormStatus.DealerClarification]: 'Dealer Clarification',
        [FormStatus.ComplianceClarification]: 'Compliance Clarification',
        [FormStatus.Paid]: 'Paid',
        [FormStatus.AdvisorRevision]: 'Advisor Revision',
        [FormStatus.BusinessApproval]: 'Business Approval',
        [FormStatus.BusinessClarification]: 'Business Clarification'
    };

    return mappings[formStatus] ?? formStatus?.toString();
}

// export function formStatusToStatusString(formStatus: FormStatus)
// {
//     const mappings = {
//         [FormStatus.Draft]: 'Draft',
//         [FormStatus.DealerApproval]: 'Submitted',
//         [FormStatus.ComplianceApproval]: 'Submitted',
//         [FormStatus.FinanceApproval]: 'Submitted',
//         [FormStatus.Completed]: 'Approved',
//         [FormStatus.Withdrawn]: 'Cancelled',
//         [FormStatus.Invalid]: 'Invalid',
//         [FormStatus.Rejected]: 'Rejected',
//         [FormStatus.AdvisorClarification]: 'Submitted',
//         [FormStatus.DealerClarification]: 'Submitted',
//         [FormStatus.ComplianceClarification]: 'Submitted',
//         [FormStatus.Paid]: 'Paid',
//         [FormStatus.AdvisorRevision]: 'Submitted'
//     };

//     return mappings[formStatus] ?? formStatus?.toString();
// }
