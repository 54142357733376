import { Shimmer, ShimmerElementType } from "@fluentui/react";

import { formatDistance } from "date-fns";
import React, { useCallback } from "react";
import { AppRoutes } from "../constants/AppRoutes";
import { Form, formTypeToString } from "../data/models/Form";
import { Flex } from "./Flex";

import "./AssignedToList.scss";
import { sortByProperty } from "../utilities/Utils";

export interface FormListProps
{
    items?: Form[];
    isLoading?: boolean;
    onRowClick?: (e: React.MouseEvent<HTMLAnchorElement>, form: Form) => void;
    onRowAction?: (form: Form, action: string) => void;
}

export const AssignedToList = (props: FormListProps) => 
{
    const { items, isLoading, onRowClick } = props;
    const shimmerElements = [
        { type: ShimmerElementType.line, height: 41 },
    ];

    const sortedItems = [...(items || [])].sort(sortByProperty('modifiedOn', false));

    return (
        <Flex column className="assignedToList">
            {
                isLoading &&
                <div style={{ width: '100%' }}>
                    <div style={{ padding: '15px 20px', borderBottom: '1px solid #ddd' }}><Shimmer shimmerElements={shimmerElements} /></div>
                    <div style={{ padding: '15px 20px', borderBottom: '1px solid #ddd' }}><Shimmer shimmerElements={shimmerElements} /></div>
                    <div style={{ padding: '15px 20px', borderBottom: '1px solid #ddd' }}><Shimmer shimmerElements={shimmerElements} /></div>
                </div>
            }
            {
                !isLoading && sortedItems?.map(i => {
                    return (
                        <FormListItem form={i} url={AppRoutes.byFormType(i.type, i.id)} key={i.id} onClick={onRowClick} />
                    );
                })
            }
            {
                !isLoading && sortedItems?.length === 0  &&
                <div className="no-forms">
                    <h3>No forms</h3>
                    <p style={{ color: '#777' }}>There are no forms that require your attention.</p>
                </div>
            }
        </Flex>
    );
};

export interface FormListItemProps 
{
    url: string;
    onClick?: (e: React.MouseEvent<HTMLAnchorElement>, form: Form) => void;
    form: Form;
};

export const FormListItem = (props: FormListItemProps) => 
{
    const { form, onClick } = props;

    const onRowClick = useCallback((e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        onClick?.(e, form);
    }, [onClick, form]);

    return (
        <a href={props.url} className="assignedTo-item" onClick={onRowClick} style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
            <div style={{ display: 'flex', gap: '15px', alignItems: 'center' }}>
                <div className="assignedTo-title">
                    <div>{form?.formId}</div>
                    <div style={{ fontSize: '12px', marginTop: '3px', color: 'var(--color-text-grey)' }}>{formTypeToString(form?.type)}</div>
                </div>
                <div className="assignedTo-info">
                    <div>{form?.modifiedOn ? formatDistance(new Date(form?.modifiedOn), new Date(), { addSuffix: true }) : 'No Date'}</div>
                </div>

                {/* <FormListItem.Column header="Form ID">{form?.formId}</FormListItem.Column>
                <FormListItem.Column header="Type of Form">{formTypeToString(form?.type ?? 'invalid')}</FormListItem.Column>
                <FormListItem.Column header="Status">{formStatusToString(form?.formStatus)}</FormListItem.Column>
                <FormListItem.Column header="Date">{format(new Date(form?.createdOn ?? new Date()), 'P')}</FormListItem.Column> */}
            </div>
        </a>
    );
};

export interface FormListItemColumnProps 
{
    children: any;
    header?: string;
    style?: React.CSSProperties;
}

FormListItem.Column = (props: FormListItemColumnProps) => 
{
    return (
        <div className="formlist-column" style={props.style}>
            {
                props.header ?
                <div className="column-header">{props.header}</div>
                : null
            }
            
            <div className="column-value">{props.children}</div>
        </div>
    );
};