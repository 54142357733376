import { DefaultButton, filteredAssign, FontIcon, IconButton, ProgressIndicator, Text, useTheme } from "@fluentui/react";
import { useField, useFormikContext } from "formik";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Flex } from "../components/Flex";
import { DeleteAttachment, GetAttachmentContentUrl, UploadAttachment } from "../data/queries/AttachmentQueries";
import { bytesToString } from "../utilities/FileUtilities";

export interface VPIAttachmentFieldProps
{
    formId?: string;
    name: string;
    label?: string;
    buttonLabel?: string;
    rows?: number;
    required?: boolean;
    disabled?: boolean;
    multiple?: boolean;
    maxSize?: number; // In bytes
}

export interface VPIFieldAttachment
{
    id?: string;
    filename: string;
    size: number;
    inProgress: boolean;
}

export const VPIAttachmentField = (props: VPIAttachmentFieldProps) => 
{
    const { name, label, required, disabled, multiple, buttonLabel = 'Attach Document', formId, maxSize = 10000000 } = props;
    const [field, meta, helpers] = useField<VPIFieldAttachment[]>(name);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const formikContext = useFormikContext();
    const theme = useTheme();
    const fieldValue = field.value || [];

    const onAddFiles = useCallback(async (files: FileList) =>
    {
        const newAttachments: VPIFieldAttachment[] = [];
        for(let i = 0; i < files.length; i++)
        {
            const file = files[i];
            if(field.value.some(f => f.filename === file.name) === false)
            {
                newAttachments.push({
                    filename: file.name,
                    size: file.size,
                    inProgress: true
                });
            }
            else
            {
                helpers.setTouched(true, false);
                //helpers.setError(`A file with the name '${file.name}' has already been attached`);
                helpers.setError(`A file with the same name already exists. Please delete the attached existing file with the same name and then upload the revised/updated document.`);
                return;
            }
        }
        helpers.setValue([...field.value, ...newAttachments]);

        for(let i = 0; i < files.length; i++)
        {
            const uploadedAttachment = await UploadAttachment(name, files[i], formId);
            const a = newAttachments.find(a => a.filename === files[i].name);
            if(a) { a.id = uploadedAttachment.id; a.inProgress = false; }
            helpers.setValue([...field.value, ...newAttachments]);
        }
    }, [helpers, field.value])

    const onRemoveFile = useCallback(async (attachment: VPIFieldAttachment) =>
    {
        if(attachment.id && window.confirm(`Are you sure you want to delete "${attachment.filename}". This action cannot be undone.`))
        {
            helpers.setValue([...field.value.filter(a => a.filename !== attachment.filename)]);
            await DeleteAttachment(attachment.id);
        }
    }, [helpers, field.value]);

    const onChange = useCallback(async (e: React.ChangeEvent<HTMLInputElement>) => 
    {
        if(e.target.files)
        {
            await onAddFiles(e.target.files);
            // Reset the file input so user can select the same file again, if deleted
            e.target.value = '';
        }
    }, [onAddFiles]);

    return (
        <div style={{ gridArea: name }}>

            { label && 
                <Text style={{ display: 'block', padding: '5px 0', fontWeight: '600' }}>{label}</Text>
            }

            <Flex column style={{ gap: '10px', marginBottom: '10px' }}>
                { fieldValue.map(attachment => (
                    <AttachmentItem key={attachment.id} attachment={attachment} disabled={disabled || formikContext.isSubmitting} inProgress={attachment.inProgress} onRemoveClick={onRemoveFile} />
                ))}
                { fieldValue.length === 0 &&
                    <Flex row style={{ width: '100%', padding: '10px', gap: '10px', alignItems: 'center', border: '1px solid #ddd', borderRadius: '5px', cursor: 'default' }}>
                        <div style={{ color: 'var(--color-text-grey)' }}>No attachments</div>
                    </Flex>
                }
            </Flex>

            <DefaultButton 
                text={buttonLabel} 
                iconProps={{ iconName: 'Attach' }} 
                onClick={e => fileInputRef.current?.click()}
                disabled={disabled || formikContext.isSubmitting}
                styles={{ root: { borderColor: meta.touched && meta.error ? theme.semanticColors.errorText : undefined } }}
            />

            { meta.touched && meta.error &&
                <Text style={{ display: 'block', marginTop: '5px' }}  variant="small" styles={{ root: { color: theme.semanticColors.errorText } }}>{meta.error}</Text>
            }

            <input
                type="file"
                name={field.name}
                required={required}
                disabled={disabled || formikContext.isSubmitting}
                multiple={multiple}
                onChange={onChange}
                ref={fileInputRef}
                style={{ display: 'none' }}
            />
        </div>
    );
};

interface AttachmentItemProps
{
    attachment: VPIFieldAttachment;
    inProgress: boolean;
    disabled: boolean;
    onRemoveClick: (attachment: VPIFieldAttachment) => void;
}

const AttachmentItem = (props: AttachmentItemProps) => 
{
    const { attachment, inProgress, onRemoveClick, disabled } = props;

    if(inProgress)
    {
        return (
            <div key={attachment.filename} style={{ width: '100%', padding: '10px', gap: '10px', alignItems: 'center', border: '1px solid #ddd', borderRadius: '5px' }}>
                <div>Uploading {attachment.filename}...</div>
                <ProgressIndicator />
            </div>
        )
    }
    else
    {
        return (
            <Flex key={attachment.id} row style={{ width: '100%', padding: '10px', gap: '10px', alignItems: 'center', border: '1px solid #ddd', borderRadius: '5px' }}>
                <FontIcon iconName="TextDocument" />
                <div style={{ flex: 1 }}>
                    <a href={GetAttachmentContentUrl(attachment.id || '')} target="_blank">{attachment.filename || attachment.filename}</a>
                    <div style={{ fontSize: '12px', color: '#7D7D7D' }}>{bytesToString(attachment.size)}</div>
                </div>
                <IconButton iconProps={{ iconName: 'Delete' }} onClick={() => { onRemoveClick(attachment) }} disabled={disabled} />
            </Flex>
        );
    }
}