
export const Constants = {
    MAX_FILE_SIZE: 10000000 // Max file attachment size in bytes (10MB)
}

export const FormGridStyles: React.CSSProperties = {
    display: 'grid',
    gap: '15px',
    gridTemplateColumns: 'repeat(3, minmax(0, 1fr))'
};

export const DetailsGridStyles3Col: React.CSSProperties = {
    display: 'grid',
    gap: '15px',
    maxWidth: '640px',
    gridTemplateColumns: 'repeat(3, minmax(0, 1fr))'
};

export const DetailsGridStyles2Col: React.CSSProperties = {
    display: 'grid',
    gap: '15px',
    maxWidth: '640px',
    gridTemplateColumns: 'repeat(2, minmax(0, 1fr))'
};