import { useState } from "react";

export const useToggle = (initialState: boolean) =>
{
    const [value, setValue] = useState(initialState);

    return {
        open: () => setValue(true),
        close: () => setValue(false),
        set: setValue,
        isOpen: value
    };

}