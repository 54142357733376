import axios, { AxiosResponse } from "axios";
import { isValid } from "date-fns";
import isDate from "date-fns/isDate";
import { acquireTokenSilent } from "../../hooks/useAuth";

export const axiosInstance = axios.create({
    headers: {
        'accept': 'application/json',
        'content-type': 'application/json'
    }
});

axiosInstance.interceptors.request.use(async config =>
{
    const result = await acquireTokenSilent();
    config.headers = {...config.headers, Authorization: `bearer ${result.accessToken}` };
    config.withCredentials = true;
    return config;
});

axiosInstance.interceptors.response.use(response => 
{
    if(Array.isArray(response.data))
    {
        response.data = response.data.map(obj => {
            for(const key in obj)
                if(typeof obj[key] === 'string' 
                    && obj[key].endsWith('Z')
                    && isValid(new Date(obj[key])))
                    obj[key] = new Date(obj[key]);
            return obj;
        });
    }
    else if(typeof response.data === 'object')
    {
        const obj = response.data;
        for(const key in obj)
            if(typeof obj[key] === 'string' 
                && obj[key].endsWith('Z')
                && isValid(new Date(obj[key])))
                obj[key] = new Date(obj[key]);
    }

    return response;
});
