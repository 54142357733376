
export const AppRoutes =
{
    Login: (redirectAfterLoginUrl: string) => `/auth/login?r=${redirectAfterLoginUrl}`,
    Logout: () => '/auth/logout',
    Dashboard: () => '/dashboard',
    Coop: {
        PreApproval: (id?: string) => `/forms/coop-preapproval/${id || 'new'}`,
        Reimbursement: (id?: string, preApprovalFormId?: string) => `/forms/coop-reimbursement/${id || 'new'}${preApprovalFormId ? `?p=${preApprovalFormId}` : ''}`
    },
    VPPW: {
        PreApproval: (id?: string) => `/forms/vppw-preapproval/${id || 'new'}`,
        Reimbursement: (id?: string, preApprovalFormId?: string) => `/forms/vppw-reimbursement/${id || 'new'}${preApprovalFormId ? `?p=${preApprovalFormId}` : ''}`
    },
    VPIC: {
        PreApproval: (id?: string) => `/forms/vpic-preapproval/${id || 'new'}`,
        Reimbursement: (id?: string, preApprovalFormId?: string) => `/forms/vpic-reimbursement/${id || 'new'}${preApprovalFormId ? `?p=${preApprovalFormId}` : ''}`
    },
    byFormType: (formType: string, id?: string, preApprovalFormId?: string) => {
        switch(formType)
        {
            case 'coop-preapproval': return AppRoutes.Coop.PreApproval(id);
            case 'coop-reimbursement': return AppRoutes.Coop.Reimbursement(id, preApprovalFormId);

            case 'vppw-preapproval': return AppRoutes.VPPW.PreApproval(id);
            case 'vppw-reimbursement': return AppRoutes.VPPW.Reimbursement(id, preApprovalFormId);

            case 'vpic-preapproval': return AppRoutes.VPIC.PreApproval(id);
            case 'vpic-reimbursement': return AppRoutes.VPIC.Reimbursement(id, preApprovalFormId);

            default: return '';
        }
    }
};