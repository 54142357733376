import { Dropdown, DropdownMenuItemType, IDropdownOption } from "@fluentui/react";
import { useField, useFormikContext } from "formik";

export interface VPIDropdownField
{
    name: string;
    label?: string;
    placeholder?: string;
    options?: IDropdownOption<{ key: string, text: string, disabled?: boolean }>[];
    required?: boolean;
    disabled?: boolean;
    noItemsLabel?: string;

    onChange?: (e: any, option?: IDropdownOption<{ key: string, text: string }>) => void;
}

export const VPIDropdownField = (props: VPIDropdownField) => 
{
    const { name, label, options, placeholder, required, disabled, onChange, noItemsLabel = 'No items' } = props;
    const [ field, meta, helpers ] = useField(name);
    const formikContext = useFormikContext();

    return (
        <Dropdown
            options={options && options.length > 0 ? options : [{ key: '', text: noItemsLabel, disabled: true }]} 
            label={label}
            placeholder={placeholder}
            errorMessage={meta.touched ? meta.error : ''}
            styles={{ root: { gridArea: name } }}
            required={required}
            disabled={disabled || formikContext.isSubmitting}
            selectedKey={field.value}
            onChange={(e, selected) => { helpers.setValue(selected?.key); onChange?.(e, selected) }}
            onBlur={field.onBlur}
        />
    );
}