import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import { AppRoutes } from '../../constants/AppRoutes';
import { Card } from '../../components/Card';
import { CardContent } from '../../components/CardContent';
import { Spinner, SpinnerSize } from '@fluentui/react';

import "./AuthPage.scss";

function ErrorComponent(props: any) {
    return <p>Error: {props.error.message}</p>;
}

export const CallbackPage: React.FunctionComponent = () => {
    
    const auth = useAuth();
    const nav = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => 
    {
        if(auth.isReady)
        {
            if(auth.isAuthenticated)
                nav(searchParams.get('r') ?? AppRoutes.Dashboard());
            else
                console.error('Error logging in');
        }
    }, [auth.isReady, auth.isAuthenticated]);

    return (
        <div className="auth-page">
            <Card style={{ width: '320px', margin: '40px auto', textAlign: 'center' }}>
                <CardContent>
                    <Spinner size={SpinnerSize.large} />
                    <div style={{ marginTop: '20px' }}>Logging in</div>
                </CardContent>
            </Card>
        </div>
    );
}
