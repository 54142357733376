import React, { useCallback } from "react";
import * as Yup from "yup";
import { Formik, Form, FormikProps, FormikHelpers } from "formik";
import { useId } from "@fluentui/react-hooks";
import { FormVpicPreApproval } from "../../data/models/FormVpicPreApproval";
import { VPITextField } from "../../fields/VPITextField";
import { VPIDropdownField } from "../../fields/VPIDropdownField";
import { VPIDateField } from "../../fields/VPIDateField";
import { VPICurrencyField } from "../../fields/VPICurrencyField";
import { FormSectionHeader } from "../../components/FormSectionHeader";
import { attachmentsToAttachmentItems, copyInto, validateWhen } from "../../utilities/FormUtils";
import { DefaultButton, PrimaryButton, TooltipDelay, TooltipHost } from "@fluentui/react";
import { FormStatus } from "../../data/models/Form";
import { useUserContext } from "../../hooks/useUserContext";
import { VPIChoiceField } from "../../fields/VPIChoiceField";
import { hasPermission, hasProcessFlow, User, UserRole } from "../../data/models/User";
import { useQuery } from "react-query";
import { QueryKeys } from "../../data/QueryKeys";
import { GetAdvisors } from "../../data/queries/UserQueries";
import { FormShimmer } from "../../helpers/Shimmer";
import { format, sub } from "date-fns";
import { Constants, FormGridStyles } from "../../constants/Constants";
import { VPIAttachmentField, VPIFieldAttachment } from "../../fields/VPIAttachmentField";
import { VPIDisplayTextBox } from "../../fields/VPIDisplayTextBox";
import { Flex } from "../../components/Flex";

export interface VPICPreApprovalFormProps
{
    form?: FormVpicPreApproval;
    onSave?: (formData: Partial<FormVpicPreApproval>, action: 'save-draft' | 'submit') => void | Promise<void>;
    allowComments?: boolean;
}

export const VPICPreApprovalForm = (props: VPICPreApprovalFormProps) => 
{
    const { form = null, onSave, allowComments = false } = props;
    const usersQuery = useQuery(QueryKeys.User.All(), () => GetAdvisors());
    const isLoading = usersQuery.isFetching;

    const s: React.CSSProperties = Object.assign({}, FormGridStyles, {
        gridTemplateAreas: `
            'advisorUserId advisorUserId advisorUserId'
            'date telephone city'
            'province . .'
            'email email .'
            'expenseType expenseType expenseType'
            'expenseEstimatedTotalCost expenseAmountRequested eventDate'
            'expenseDetailsHeader expenseDetailsHeader expenseDetailsHeader'
            'expenseDetails expenseDetails expenseDetails'
            'supportingDocsHeader supportingDocsHeader supportingDocsHeader'
            'attachments attachments .'
            'attendanceInfoHeader attendanceInfoHeader attendanceInfoHeader'
            'attendanceClients attendanceEmployees .'
            'certificationForm certificationForm certificationForm'
        `
    });

    const onValidate = useCallback(validateWhen(Yup.object({
        formStatus: Yup.string().required(),
        advisorUserId: Yup.string().required('Please select an advisor'),
        date: Yup.date().required(),
        
        expenseType: Yup.string().required('Please select or enter an expense type'),
        expenseEstimatedTotalCost: Yup.number().required('Estimated cost is required').positive('Amount must be greater than 0').integer(),
        expenseAmountRequested: Yup.number().required('Amount Requested is required').positive('Amount must be greater than 0').integer(),
        eventDate: Yup.date()
            .min(sub(new Date(), { days: 1 }), 'Event Date must be in the future')
            .when('expenseType', { is: 'Client Events', then: schema => schema.required('Event Date is required') }),

        expenseDetails: Yup.string().optional().max(2000, 'Expense Details cannot be longer than 2,000 characters'),
        
        attendanceClients: Yup.number().integer('Must be a whole number').moreThan(-1, 'Number of clients cannot be negative').required('Please enter the number of clients'),
        attendanceEmployees: Yup.number().integer('Must be a whole number').moreThan(-1, 'Number of employees cannot be negative').required('Please enter the number of employees'),

        attachments: Yup.array().ensure()
            .of(Yup.mixed().test('size', 'The file is too large', (value: VPIFieldAttachment) => value.size <= Constants.MAX_FILE_SIZE))
            .of(Yup.mixed().test('inProgress', 'Please wait for all files to finish uploading', (value: VPIFieldAttachment) => value.inProgress === false))
            .min(1, 'Please attach at least 1 file')
            .required('Please attach at least 1 file'),

        comments: Yup.string().max(2000, 'Comments cannot be more than 2,000 characters'),
        action: Yup.string().required()
    }), values => values.action !== 'save-draft'), []);

    const initialValues = copyInto({
        formStatus: FormStatus.Draft,
        advisorUserId: form?.advisorUserId,
        date: new Date(),

        expenseType: '',
        eventDate: new Date(),
        expenseEstimatedTotalCost: 0,
        expenseAmountRequested: 0,

        expenseDetails: '',
        attendanceClients: 0,
        attendanceEmployees: 0,

        attachments: [],
        comments: '', // Only visible when sent back for advisor revision
        action: ''
    }, form);
    initialValues.attachments = attachmentsToAttachmentItems(form?.attachments);

    const onSubmit = useCallback(async (values: any, actions: FormikHelpers<any>) => 
    {
        const { action, ...formData } = values;
        await onSave?.(formData, action);
    }, [form, onSave]);

    const advisorOptions = (usersQuery.data ?? [])
        .filter(user => user.role === UserRole.Advisor && hasProcessFlow(user, 'VPIC'))
        .map(user => { return { key: user.id, text: `${user.name} (${user.accountName})` } });
    const getAdvisor = (advisorUserId: string) => usersQuery.data?.find(u => u.id === advisorUserId);

    return (
        <>
        {
            !isLoading &&
            <Formik 
                initialValues={initialValues} 
                onSubmit={onSubmit}
                validate={onValidate}>
                { formProps => (
                    <Form style={s}>
                        <VPIDropdownField name="advisorUserId" label="Advisor" options={advisorOptions} required placeholder="Select..." noItemsLabel="No advisors found" />

                        <VPIDateField name="date" label="Date" />

                        <VPIDisplayTextBox name="telephone" label="Telephone" value={getAdvisor(formProps.values.advisorUserId)?.telephone} />
                        <VPIDisplayTextBox name="city" label="City" value={getAdvisor(formProps.values.advisorUserId)?.city} />
                        <VPIDisplayTextBox name="province" label="Province" value={getAdvisor(formProps.values.advisorUserId)?.province} />

                        <VPIChoiceField name="expenseType" label="Expense Type" allowOther otherLabel="Other (specify below)" required options={[
                            { key: 'Client Events', text: 'Client Events' }
                        ]} />

                        <VPICurrencyField name="expenseEstimatedTotalCost" label="Estimated Total Cost" placeholder="Estimated cost" required />
                        <VPICurrencyField name="expenseAmountRequested" label="Amount Requested" placeholder="Amount Requested" required />
                        <VPIDateField name="eventDate" label="Event Date" required={formProps.values.expenseType === 'Client Events'} />

                        <FormSectionHeader gridArea="expenseDetailsHeader">
                            <h3>Expense Details</h3>
                            <p>Please describe the nature of the expense including any relevant information such as dates, number of clients and/or staff attending, benefit to our clients, impact to your business, etc.</p>
                        </FormSectionHeader>
                        <VPITextField name="expenseDetails" multiline rows={4} placeholder="Enter expense details" />
                        
                        <FormSectionHeader gridArea="supportingDocsHeader">
                            <h3>Supporting Documents</h3>
                            <p>All attachments should be uploaded here. (An agenda activity planned along with time allocated for each activity)<br />(Please include a budget or estimate for the expense)</p>
                        </FormSectionHeader>
                        <VPIAttachmentField name="attachments" multiple buttonLabel="Attach Documents" required formId={form?.id} />

                        <FormSectionHeader gridArea="attendanceInfoHeader">
                            <h3>Attendance Information</h3>
                        </FormSectionHeader>
                        <VPITextField name="attendanceClients" label="Clients" type="number" placeholder="Number of clients" required minimum={0} />
                        <VPITextField name="attendanceEmployees" label="Employees" type="number" placeholder="Number of employees" required minimum={0} />

                        <CertificationForm formProps={formProps} advisor={getAdvisor(formProps.values.advisorUserId)} allowComments={allowComments} />
                    </Form>
                )}
            </Formik>
        }
        { isLoading && <FormShimmer /> }
        </>
    );
};

const CertificationForm = (props: { formProps: FormikProps<any>, advisor?: User, allowComments: boolean }) =>
{
    const { formProps, advisor, allowComments } = props;
    const { user } = useUserContext();
    const canSubmit = hasPermission(user, 'vpic.submit');
    const tooltipId = useId('tooltip');

    return (
        <div style={{ gridArea: 'certificationForm' }}>
            <hr />

            <h4>Certification</h4>
            <p>I/We hereby certify that all necessary details of the proposed expense(s) have been truly and accurately disclosed.</p>

            <Flex gap="10px" align="normal" justify="normal" column>
                <VPIDisplayTextBox name="certificationName" label="Advisor Name/Signature" value={advisor?.name} />
                <VPIDisplayTextBox name="certificationDate" label="Date" value={format(new Date(), 'PP')} />
                {
                    allowComments &&
                    <VPITextField name="comments" label="Comments" description="Please include any changes you have made to your request" disabled={formProps.isSubmitting} multiline rows={4} />
                }
            </Flex>

            <hr />
            <div style={{ display: 'flex', gap: '10px', justifyContent: 'flex-end' }}>
                <DefaultButton text="Save as Draft" disabled={formProps.isSubmitting} onClick={() => {
                    formProps.setFieldValue('action', 'save-draft');
                    // Form fails validation if submitted immediately after setting value
                    setTimeout(() => formProps.submitForm());
                }}  />
                <TooltipHost
                    content={canSubmit ? '' : 'You must be an advisor to submit'}
                    delay={TooltipDelay.zero}
                    id={tooltipId}>
                    <PrimaryButton text="Send to VPI Compliance" disabled={formProps.isSubmitting || !canSubmit} aria-describedby={tooltipId} onClick={() => {
                        formProps.setFieldValue('action', 'submit');
                        // Form fails validation if submitted immediately after setting value
                        setTimeout(() => formProps.submitForm());
                    }} />
                </TooltipHost>
            </div>
        </div>
    );
};