import { useCallback, useState } from "react";
import { AdvisorClarificationDialog, StageProps, handleSelectedOutcome } from "../../forms/DefaultStages";
import { useToggle } from "../../hooks/useToggle";
import { useUserContext } from "../../hooks/useUserContext";
import { TaskOutcome } from "../../data/models/Task";
import { CardHeader } from "../../components/CardHeader";
import { CardContent } from "../../components/CardContent";
import { TaskForm } from "../../forms/TaskForm";
import { Formik, Form as FormikForm } from "formik";
import { VPIDateField } from "../../fields/VPIDateField";
import { DatePicker, PrimaryButton } from "@fluentui/react";
import * as Yup from "yup";
import { format, isValid } from "date-fns";
import { UpdateForm } from "../../data/queries/FormQueries";

export interface FinanceStageProps extends StageProps
{
    allowDealerClarification?: boolean;
    allowBusinessClarification?: boolean;
    vpiLabels?: boolean;
}

export const VPPWFinanceApprovalStage = ({ form, task, title, children, onSave }: FinanceStageProps) => 
{
    const { user } = useUserContext();
    const advisorDialog = useToggle(false);
    const [comments, setComments] = useState('');
    const [paidDate, setPaidDate] = useState<Date>(new Date());
    const [loading, setLoading] = useState(false);

    const schema = Yup.object({
        paymentDate: Yup.date().required('Payment date is required')
    });

    const s: React.CSSProperties = {
        display: 'grid',
        gap: '15px',
        gridTemplateAreas: `
            'paymentDate'
            'actions'
        `
    };

    const onSelect = useCallback(async (selectedOutcome: TaskOutcome | 'show-dialog', comments: string) =>
    {
        if(selectedOutcome === 'show-dialog')
        {
            advisorDialog.open();
            setComments(comments);
        }
        else
        {
            setLoading(true);

            // Send the selected action to the API
            await handleSelectedOutcome(form, task, selectedOutcome, comments, paidDate);
            onSave?.();

            setLoading(false);
        }
    }, [onSave, advisorDialog, form, task, paidDate]);

    if(!task)
        return (<><CardHeader>Error</CardHeader><CardContent>No active task found</CardContent></>);

    const formatDate = (date?: Date) => {
        return date && isValid(date) ? format(new Date(date), 'PP') : 'Invalid Date';
    };

    return (
        <>
            {children}
            <CardHeader>Accounting</CardHeader>
            <CardContent>
                <div>
                    {/* <Formik
                        initialValues={{ paymentDate: new Date() }}
                        onSubmit={values => {}}
                        validationSchema={schema}>
                        { formProps => (
                            <FormikForm style={s} onChange={s => console.log(s)}>
                                <VPIDateField name="paymentDate" label="Payment Date" />
                            </FormikForm>
                        )}
                    </Formik> */}
                    <DatePicker
                        label="Payment Date"
                        styles={{ root: { flex: 3 }, statusMessage: { display: 'none' } }}
                        onSelectDate={date => { if(date) setPaidDate(date); }}
                        value={paidDate}
                        disabled={loading}
                        formatDate={formatDate}
                        textField={{ 
                            errorMessage: '',
                            required: false
                        }}
                    />
                </div>
            </CardContent>
            <CardHeader>{title ?? 'Certification'}</CardHeader>
            <CardContent>
                <TaskForm 
                    nameLabel={'Finance Name/Signature'}
                    name={user?.name}
                    message="The undersigned has reviewed all relevant documentation pertaining to the reimbursement referenced above and authorizes same."
                    showComments
                    primaryActions={[
                        { key: TaskOutcome.Rejected, text: 'Reject' },
                        { key: TaskOutcome.Paid, text: 'Approve & Mark as Paid', buttonType: 'primary' }
                    ]}
                    secondaryActions={[
                        { key: 'show-dialog', text: 'Advisor Clarification...', buttonType: 'secondary' },
                        //...(allowDealerClarification ? [{ key: TaskOutcome.DealerClarification, text: 'LP Dealer Clarification', buttonType: 'secondary' as 'secondary' }] : []),
                        { key: TaskOutcome.BusinessClarification, text: 'Business Clarification', buttonType: 'secondary' as 'secondary' },
                        { key: TaskOutcome.ComplianceClarification, text: 'Compliance Clarification', buttonType: 'secondary' }
                    ]}
                    onSelect={onSelect}
                />
                <AdvisorClarificationDialog open={advisorDialog.isOpen} onDismiss={advisorDialog.close} onSelect={async (action) => { 
                    await onSelect(action === 'clarification' ? TaskOutcome.AdvisorClarification : TaskOutcome.AdvisorRevision, comments);
                }} />
            </CardContent>
        </>
    );
};