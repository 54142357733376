import { PublicClientApplication, Configuration, SilentRequest, EventType } from "@azure/msal-browser"
import { useIsAuthenticated, useMsal } from "@azure/msal-react";

const config: Configuration = {
    auth: {
        clientId: process.env.REACT_APP_AUTH_CLIENT_ID as string,
        authority: process.env.REACT_APP_AUTH_AUTHORITY as string,
        knownAuthorities: [(process.env.REACT_APP_AUTH_KNOWN_AUTHORITIES as string)],
    },
    cache: {
        cacheLocation: 'localStorage'
    }
};

const scopes = [
    'openid',
    'profile',
    (process.env.REACT_APP_AUTH_SCOPE as string) //'https://bloomvpidev.onmicrosoft.com/api/access_as_user'
];

export const msalApp = new PublicClientApplication(config);
msalApp.addEventCallback(event => {
    if(event.eventType === EventType.ACQUIRE_TOKEN_FAILURE
        && event.interactionType === 'silent')
    {
        msalApp.loginRedirect({ 
            scopes,
            redirectUri: `${window.location.origin}/auth/callback`,
            redirectStartPage: `${window.location.href}` });
    }
});

export const acquireTokenSilent = async () => 
{
    const account = msalApp.getActiveAccount() || msalApp.getAllAccounts()?.[0];

    if(account)
    {
        return msalApp.acquireTokenSilent({ 
            scopes, 
            account,
            redirectUri: `${window.location.origin}/auth/callback`
        });
    }
    else
    {
        const redirectUrl = window.location.href.replace(window.location.origin, '');
        msalApp.loginRedirect({ scopes, redirectUri: `${window.location.origin}/auth/callback`, redirectStartPage: redirectUrl });
        throw new Error('Auth.acquireTokenSilent() error: User is not logged in');
    }
};

export const useAuth = () => 
{
    const { instance, accounts, inProgress } = useMsal();
    const isAuthenticated = useIsAuthenticated();

    return {
        scopes,
        app: msalApp,
        status: inProgress,
        isReady: inProgress === 'none',
        isAuthenticated,
        login: (redirectUrl: string) => { msalApp.loginRedirect({ scopes, redirectUri: `${window.location.origin}/auth/callback`, redirectStartPage: redirectUrl }); },
        logout: () => { msalApp.logoutRedirect({ postLogoutRedirectUri: '/' }); }
    };
};