import { Shimmer, ShimmerElementType } from "@fluentui/react";
import { AxiosResponse } from "axios";
import { format, isDate } from "date-fns";
import { useQuery, UseQueryResult } from "react-query";
import { taskOutcomeToString, Task, TaskOutcome, taskStageToString } from "../data/models/Task";
import { GetTasks } from "../data/queries/TaskQueries";
import { GetUser } from "../data/queries/UserQueries";
import { QueryKeys } from "../data/QueryKeys";
import { useAsyncEffect } from "../hooks/useAsyncEffect";

import "./TaskHistoryList.scss";

export interface TaskHistoryListProps 
{
    // formType: string;
    // formId?: string;

    tasks?: Task[];
    isLoading?: boolean;
}

export const TaskHistoryList = (props: TaskHistoryListProps) =>
{
    //const { formType, formId = '' } = props;

    // const query = useQuery(QueryKeys.Form(props.formType).Tasks(formId), () => GetTasks(formType, formId), { enabled: !!formId });
    // const tasks = query.data?.data;
    // const isLoading = !formId || query.isLoading;

    const { tasks = [], isLoading = false } = props;

    return (
        <div className="history-list">
            {
                !isLoading && 
                tasks?.map(task => {
                    return (
                        <TaskHistoryItem task={task} key={task.id} />
                    );
                })
            }

            {
                (!isLoading && (!tasks || tasks.length === 0)) &&
                <div className="no-history">No history</div>
            }

            {
                isLoading &&
                <div style={{ borderBottom: '1px solid #ddd', width: '100%', padding: '0 20px' }}>
                    <div style={{ paddingTop: '20px' }}><Shimmer /></div>
                    <div style={{ paddingTop: '20px' }}><Shimmer shimmerElements={[{ type: ShimmerElementType.line, height: 120 }]} /></div>
                    <div style={{ padding: '20px 0' }}><Shimmer shimmerElements={[
                        { type: ShimmerElementType.line, height: 16 },
                        { type: ShimmerElementType.gap, width: 180 }
                    ]}  /></div>
                </div>
            }

        </div>
    );
};

export interface TaskHistoryItemProps 
{
    task: Task;
}

export const TaskHistoryItem = (props: TaskHistoryItemProps) =>
{
    const { task } = props;
    const userQuery = useQuery(QueryKeys.User.Id(task.completedByUserId), () => GetUser(task.completedByUserId));

    return (
        <div className="history-item">
            <div className={[`history-outcome`, taskOutcomeToClass(task.outcome)].join(' ')}>
                <span>{taskOutcomeToString(task.outcome)}</span>
            </div>
            <div className="history-user">
                {userQuery.data?.name}
            </div>
            <div className="history-stage">{taskStageToString(task.stage)}</div>
            <div className="history-comments">{task.comments ? task.comments : 'No comments'}</div>
            <div className="history-date">{isDate(task.completed) ? format(new Date(task.completed), 'PP h:mm a') : 'Not complete'}</div>
        </div>
    );
};

const taskOutcomeToClass = (outcome: TaskOutcome) =>
{
    let retval = '';

    switch(outcome)
    {
        case TaskOutcome.Completed:
        case TaskOutcome.Paid:
            retval = 'completed';
            break;
        case TaskOutcome.Approved: 
            retval = 'approved'; 
            break;
        case TaskOutcome.AdvisorClarification:
        case TaskOutcome.ComplianceClarification:
        case TaskOutcome.DealerClarification:
        case TaskOutcome.BusinessClarification:
        case TaskOutcome.Resubmitted:
            retval = 'clarification';
            break;
        case TaskOutcome.Rejected:
        case TaskOutcome.AdvisorRevision:
            retval = 'rejected';
    }

    return retval;
}