import { TextField } from "@fluentui/react";
import { useField, useFormikContext } from "formik";
import React, { CSSProperties, useEffect, useState } from "react";

export interface VPICurrencyFieldProps
{
    name: string;
    label?: string;
    rows?: number;
    required?: boolean;
    disabled?: boolean;
    placeholder?: string;
    style?: CSSProperties;
}

const parseValue = (value: string) =>
{
    return parseInt((parseFloat(value.replace(/[^\d\.]/g, '')) * 100).toString());
};

const formatValue = (value: number) => 
{
    const formatter = new Intl.NumberFormat('en-CA', { style: 'currency', currency: 'CAD' });
    if(typeof value === 'number' && isFinite(value))
        return formatter.format(value / 100).replace(/\$/g, '');
    else
        return '';
};

export const VPICurrencyField = (props: VPICurrencyFieldProps) => 
{
    const { name, label, required, disabled, placeholder, style } = props;
    const [field, meta, helpers] = useField(name);
    const [textValue, setTextValue] = useState(formatValue(meta.initialValue));
    const [focused, setFocused] = useState(false);
    const formikContext = useFormikContext();

    useEffect(() => {
        if(!focused)
            setTextValue(formatValue(field.value));
    }, [field.value]);

    const onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        setFocused(false);

        const numberValue = parseValue(e.target.value);
        helpers.setValue(isFinite(numberValue) ? numberValue : 0, true);
        setTextValue(formatValue(field.value));
        field.onBlur(e);
    };

    const onChange = (e: any, newValue: string | undefined) => {
        const numberValue = parseValue(newValue!);
        helpers.setValue(isFinite(numberValue) ? numberValue : 0, true);
        setTextValue(newValue!);
    };

    return (
        <TextField
            type="text"
            prefix="$"
            name={field.name}
            label={label}
            required={required}
            disabled={disabled || formikContext.isSubmitting}
            placeholder={placeholder}
            errorMessage={meta.touched ? meta.error : ''}
            styles={{ root: { gridArea: name, ...(style as any) } }}
            onFocus={() => setFocused(true)}
            value={textValue}
            onChange={onChange}
            onBlur={onBlur}
        />
    );
};
