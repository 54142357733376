import { CardHeader } from "../components/CardHeader";
import { Task, TaskOutcome } from "../data/models/Task";
import { Form, FormStatus, FormType } from "../data/models/Form";
import { CardContent } from "../components/CardContent";
import { TaskForm } from "./TaskForm";
import { ApproveForm, CancelForm, CreateForm, MarkFormAsPaid, RejectForm, RequestClarification, RequestRevision, ResubmitForm, SubmitForm, UpdateForm } from "../data/queries/FormQueries";
import { useUserContext } from "../hooks/useUserContext";
import { User } from "../data/models/User";
import { Attachment } from "../data/models/Attachment";
import { UpdateAttachment } from "../data/queries/AttachmentQueries";
import { Form as FormikForm, Formik, FormikHelpers } from "formik";
import React, { Children, useCallback, useState } from "react";
import * as Yup from "yup";
import { VPIDateField } from "../fields/VPIDateField";
import { DefaultButton, Dialog, DialogContent, DialogFooter, DialogType, PrimaryButton } from "@fluentui/react";
import { useToggle } from "../hooks/useToggle";

export interface StageProps {
    form: Form;
    task?: Task;
    onSave?: () => void;
    title?: string;
    children?: React.ReactNode;
}

export const ClarificationStage = ({ form, task, children, title = "Clarification Requested", onSave }: StageProps) => 
{
    const { user } = useUserContext();

    const onSelect = async (selectedOutcome: TaskOutcome, comments: string) =>
    {
        // Send the selected action to the API
        await handleSelectedOutcome(form, task, selectedOutcome, comments);
        onSave?.();
    };

    if(!task)
        return (<><CardHeader>Error</CardHeader><CardContent>No active task found</CardContent></>);

    return (
        <>
            {children}
            <CardHeader>{title}</CardHeader>
            <CardContent>
                <TaskForm 
                    name={user?.name}
                    showComments
                    primaryActions={[
                        { key: TaskOutcome.Resubmitted, text: 'Send Clarification', buttonType: 'primary' },
                    ]}
                    onSelect={onSelect}
                /> 
            </CardContent>
        </>
    );
};

export const DealerApprovalStage = ({ form, task, title, children, onSave }: StageProps) => 
{
    const { user } = useUserContext();
    const advisorDialog = useToggle(false);
    const [comments, setComments] = useState('');

    const onSelect = useCallback(async (selectedOutcome: TaskOutcome | 'show-dialog', comments: string) =>
    {
        if(selectedOutcome === 'show-dialog')
        {
            advisorDialog.open();
            setComments(comments);
        }
        else
        {
            // Send the selected action to the API
            await handleSelectedOutcome(form, task, selectedOutcome, comments);
            onSave?.();
        }
    }, [onSave]);

    if(!task)
        return (<><CardHeader>Error</CardHeader><CardContent>No active task found</CardContent></>);

    return (
        <>
            {children}
            <CardHeader>{title ?? 'Certification'}</CardHeader>
            <CardContent>
                <TaskForm 
                    nameLabel="Dealer Name/Signature"
                    name={user?.name}
                    showComments
                    primaryActions={[
                        { key: TaskOutcome.Rejected, text: 'Reject' },
                        { key: TaskOutcome.Approved, text: 'Approve', buttonType: 'primary' }
                    ]}
                    secondaryActions={[
                        { key: 'show-dialog', text: 'Advisor Clarification...', buttonType: 'secondary' },
                    ]}
                    onSelect={onSelect}
                />
                <AdvisorClarificationDialog open={advisorDialog.isOpen} onDismiss={advisorDialog.close} onSelect={async (action) => { 
                    await onSelect(action === 'clarification' ? TaskOutcome.AdvisorClarification : TaskOutcome.AdvisorRevision, comments);
                }} />
            </CardContent>
        </>
    );
};

export interface ComplianceStageProps extends StageProps
{
    allowDealerClarification?: boolean;
    allowBusinessClarification?: boolean;
    vpiLabels?: boolean;
}

export const ComplianceApprovalStage = ({ form, task, title, children, allowDealerClarification = true, onSave, allowBusinessClarification = false, vpiLabels = true }: ComplianceStageProps) => 
{
    const { user } = useUserContext();
    const advisorDialog = useToggle(false);
    const [comments, setComments] = useState('');

    const onSelect = useCallback(async (selectedOutcome: TaskOutcome | 'show-dialog', comments: string) =>
    {
        if(selectedOutcome === 'show-dialog')
        {
            advisorDialog.open();
            setComments(comments);
        }
        else
        {
            // Send the selected action to the API
            await handleSelectedOutcome(form, task, selectedOutcome, comments);
            onSave?.();
        }
    }, [onSave]);

    if(!task)
        return (<><CardHeader>Error</CardHeader><CardContent>No active task found</CardContent></>);

    return (
        <>
            {children}
            <CardHeader>{title ?? 'Compliance Approval'}</CardHeader>
            <CardContent>
                <TaskForm 
                    nameLabel={(vpiLabels ? 'VPI ' : '') + 'Compliance Name/Signature'}
                    name={user?.name}
                    message="The undersigned has reviewed the information provided."
                    showComments
                    primaryActions={[
                        { key: TaskOutcome.Rejected, text: 'Reject' },
                        { key: TaskOutcome.Approved, text: 'Approve', buttonType: 'primary' }
                    ]}
                    secondaryActions={[
                        { key: 'show-dialog', text: 'Advisor Clarification...', buttonType: 'secondary' },
                        ...(allowDealerClarification ? [{ key: TaskOutcome.DealerClarification, text: 'LP Dealer Clarification', buttonType: 'secondary' as 'secondary' }] : []),
                        ...(allowBusinessClarification ? [{ key: TaskOutcome.BusinessClarification, text: 'Business Clarification', buttonType: 'secondary' as 'secondary' }] : [])
                    ]}
                    onSelect={onSelect}
                /> 
                <AdvisorClarificationDialog open={advisorDialog.isOpen} onDismiss={advisorDialog.close} onSelect={async (action) => { 
                    await onSelect(action === 'clarification' ? TaskOutcome.AdvisorClarification : TaskOutcome.AdvisorRevision, comments);
                }} />
            </CardContent>
        </>
    );
};

export interface FinanceStageProps extends StageProps
{
    allowDealerClarification?: boolean;
    allowBusinessClarification?: boolean;
    vpiLabels?: boolean;
}

export const FinanceApprovalStage = ({ form, task, title, children, allowDealerClarification = true, onSave, allowBusinessClarification = false, vpiLabels = true }: FinanceStageProps) => 
{
    const { user } = useUserContext();
    const advisorDialog = useToggle(false);
    const [comments, setComments] = useState('');

    const onSelect = useCallback(async (selectedOutcome: TaskOutcome | 'show-dialog', comments: string) =>
    {
        if(selectedOutcome === 'show-dialog')
        {
            advisorDialog.open();
            setComments(comments);
        }
        else
        {
            // Send the selected action to the API
            await handleSelectedOutcome(form, task, selectedOutcome, comments);
            onSave?.();
        }
    }, [onSave]);

    if(!task)
        return (<><CardHeader>Error</CardHeader><CardContent>No active task found</CardContent></>);

    return (
        <>
            {children}
            <CardHeader>{title ?? 'Certification'}</CardHeader>
            <CardContent>
                <TaskForm 
                    nameLabel={(vpiLabels ? 'VPI ' : '') + 'Finance Name/Signature'}
                    name={user?.name}
                    message="The undersigned has reviewed all relevant documentation pertaining to the reimbursement referenced above and authorizes same."
                    showComments
                    primaryActions={[
                        { key: TaskOutcome.Rejected, text: 'Reject' },
                        { key: TaskOutcome.Completed, text: 'Approve', buttonType: 'primary' }
                    ]}
                    secondaryActions={[
                        { key: 'show-dialog', text: 'Advisor Clarification...', buttonType: 'secondary' },
                        ...(allowDealerClarification ? [{ key: TaskOutcome.DealerClarification, text: 'LP Dealer Clarification', buttonType: 'secondary' as 'secondary' }] : []),
                        ...(allowBusinessClarification ? [{ key: TaskOutcome.BusinessClarification, text: 'Business Clarification', buttonType: 'secondary' as 'secondary' }] : []),
                        { key: TaskOutcome.ComplianceClarification, text: (vpiLabels ? 'VPI ' : '') + 'Compliance Clarification', buttonType: 'secondary' }
                    ]}
                    onSelect={onSelect}
                />
                <AdvisorClarificationDialog open={advisorDialog.isOpen} onDismiss={advisorDialog.close} onSelect={async (action) => { 
                    await onSelect(action === 'clarification' ? TaskOutcome.AdvisorClarification : TaskOutcome.AdvisorRevision, comments);
                }} />
            </CardContent>
        </>
    );
};

export const AccountingPaymentStage = ({ form, task, title = 'Payment Date', children, onSave }: StageProps) =>
{
    const schema = Yup.object({
        paymentDate: Yup.date().required('Payment date is required')
    });

    const onSelect = useCallback(async (values: { paymentDate: Date }, actions: FormikHelpers<{ paymentDate: Date }>) => 
    {
        await MarkFormAsPaid(form.type, form.id, { paymentDate: values.paymentDate });

        onSave?.();
    }, []);

    const s: React.CSSProperties = {
        display: 'grid',
        gap: '15px',
        gridTemplateAreas: `
            'paymentDate'
            'actions'
        `
    };

    return (
        <>
            {children}
            <CardHeader>{title}</CardHeader>
            <CardContent>
                <div>
                    <Formik
                        initialValues={{ paymentDate: new Date() }}
                        onSubmit={onSelect}
                        validationSchema={schema}>
                        { formProps => (
                            <FormikForm style={s}>
                                <VPIDateField name="paymentDate" label="Payment Date" />

                                <div style={{ gridArea: 'actions', textAlign: 'right' }}>
                                    <hr />
                                    <div style={{ display: 'flex', gap: '10px', justifyContent: 'flex-end' }}>
                                        <PrimaryButton 
                                            type="submit"
                                            text={'Mark as Paid'}
                                            disabled={formProps.isSubmitting}
                                        />
                                    </div>
                                </div>
                            </FormikForm>
                        )}
                    </Formik>
                </div>
            </CardContent>
        </>
    )
}

export const NoActionsStage = ({ title }: { title: string }) => 
{
    return (
        <>
            <CardHeader>{title}</CardHeader>
            <CardContent>
                <div style={{ display: 'flex', flexDirection: 'column', height: '200px', justifyContent: 'center', alignItems: 'center' }}>
                    <h3>No actions</h3>
                    <p>There are no actions for you to perform on this request.</p>
                </div>
            </CardContent>
        </>
    );
}

export const AdvisorClarificationDialog = (props: { open: boolean, onDismiss: () => void, onSelect: (action: 'clarification' | 'revision') => Promise<void> }) => 
{
    const { open, onDismiss, onSelect } = props;
    const [loading, setLoading] = useState(false);

    const onClick = useCallback(async (action: 'clarification' | 'revision') => 
    {
        try
        {
            setLoading(true);
            await onSelect(action);
            setLoading(false);
        }
        catch(error: any) { setLoading(false); }
    }, [onSelect]);

    return (
        <Dialog
            hidden={!open}
            onDismiss={onDismiss}
            modalProps={{
                isBlocking: false
            }}
            dialogContentProps={{
                type: DialogType.close,
                title: 'Request Clarification',
                subText: 'Do you require advisor clarification or revision?'
            }}>
            <ul style={{ marginBottom: '30px', paddingLeft: '20px' }}>
                <li style={{ marginBottom: '20px' }}><b>Clarification: </b>Request comments from the advsior to clarify information on the form. The advisor cannot edit the form during this process.</li>
                <li><b>Revision: </b>  Send the form back to the advisor for revision. The form will be unlocked for editing and will restart the approval process.</li>
            </ul>
            <DialogFooter>
                <DefaultButton text="Request Clarification" onClick={() => onClick('clarification')} disabled={loading} />
                <DefaultButton text="Request Revision" onClick={() => onClick('revision')} disabled={loading} />
            </DialogFooter>
        </Dialog>
    );
}

export const handleSelectedOutcome = async (form: Form, task: Task | undefined, outcome: TaskOutcome, comments: string, paymentDate?: Date) =>
{
    if(!task)
    {
        console.error('No active task');
        return;
    }

    switch(outcome)
    {
        case TaskOutcome.AdvisorClarification:
        case TaskOutcome.DealerClarification:
        case TaskOutcome.ComplianceClarification:
            await RequestClarification(form.type, form.id, { outcome, comments })
            break;
        case TaskOutcome.AdvisorRevision:
            await RequestRevision(form.type, form.id, { comments });
            break;
        case TaskOutcome.Approved:
        case TaskOutcome.Completed:
            await ApproveForm(form.type, form.id, { comments });
            break;  
        case TaskOutcome.Resubmitted:
            await ResubmitForm(form.type, form.id, { comments });
            break;
        case TaskOutcome.Rejected:
            await RejectForm(form.type, form.id, { comments });
            break;
        case TaskOutcome.Cancelled:
            await CancelForm(form.type, form.id, { comments });
            break;
        case TaskOutcome.Paid:
            await MarkFormAsPaid(form.type, form.id, { comments, paymentDate: paymentDate! });
            break;
    }
};

export const submitForm = async <T extends Form>(formType: FormType, formId: string | undefined | null, formData: Partial<T>, action: 'save-draft' | 'submit') => 
{
    // Pull attachments, comments and dealerForm (co-op only) out of formData to process later
    const attachments = (formData as any).attachments;
    const dealerForm = (formData as any).dealerForm;
    // We don't delete comments, these are stored on the form in case the user wants to save their comments as draft during revision
    const comments = (formData as any).comments;
    delete (formData as any).attachments;
    delete (formData as any).dealerForm;

    // Dataverse will error if we pass an empty string
    // This isn't pretty, the forms calling this function should ensure they only pass a guid or undefined
    (formData as any).preApprovalFormId = (formData as any).preApprovalFormId || undefined;

    let updatedForm: T;
    if(formId)
        updatedForm = await UpdateForm<T>(formType, formId, formData);
    else
    {
        // Set form status to Invalid if we're about to submit it
        if(action === 'submit')
            formData.formStatus = FormStatus.Invalid;
            
        updatedForm = await CreateForm<T>(formType, formData);
        await Promise.all(
            [...attachments, ...(dealerForm ? dealerForm : [])].map(a => {
                UpdateAttachment(a.id, { formId: updatedForm.id })
            })
        );
    }

    if(action === 'submit')
        updatedForm = await SubmitForm<T>(formType, updatedForm.id, { comments });
}

// export const submitForm = async <T extends Form>(formType: FormType, form: T | undefined | null, values: Partial<T> & { [key: string]: any } & { action: string, attachments: Attachment[], dealerForm: Attachment[] | undefined }, actions: FormikHelpers<any>) => 
// {
//     const { action, attachments, dealerForm, ...payload } = values;

//     // Dataverse will error if we pass an empty string
//     // This isn't pretty, the forms calling this function should ensure they only pass a guid or undefined
//     (payload as any).preApprovalFormId = payload.preApprovalFormId || undefined;

//     let updatedForm: T;
//     if(form)
//         updatedForm = await UpdateForm<T>(formType, form.id, payload as Partial<T>);
//     else
//     {
//         // Set form status to Invalid if we're about to submit it
//         if(action === 'submit')
//             (payload as any).formStatus = FormStatus.Invalid;
            
//         updatedForm = await CreateForm<T>(formType, payload as Partial<T>);
//         await Promise.all(
//             [...attachments, ...(dealerForm ? dealerForm : [])].map(a => {
//                 UpdateAttachment(a.id, { formId: updatedForm.id })
//             })
//         );
//     }

//     if(action === 'submit')
//         updatedForm = await SubmitForm<T>(formType, updatedForm.id);
// };
