import { useMsal } from "@azure/msal-react";
import { GetCurrentUser } from "../data/queries/UserQueries";
import { User } from "../data/models/User";
import { useState, useEffect } from "react";
import { QueryKeys } from "../data/QueryKeys";
import { useQuery } from "react-query";
import { AxiosError } from "axios";

export const useUserContext = () => 
{
    const { instance, accounts, inProgress } = useMsal();
    const [user, setUser] = useState<User>();
    const account = instance.getActiveAccount() || instance.getAllAccounts()?.[0];
    const userQuery = useQuery(QueryKeys.User.Current(), () => GetCurrentUser(), { staleTime: 1000 * 10 })

    // useEffect(() => {
    //     (async () => {
    //         console.log(account?.localAccountId);
    //         if(account?.localAccountId)
    //             setUser(await GetCurrentUser());
    //     })()
    // }, [userQuery.status]);

    useEffect(() => 
    {
        if(userQuery.status === 'error')
        {
            const error: AxiosError<any, any> = userQuery.error as any;
            if(error.response)
            {
                alert(`There was an error loading your account.\n\n[${error.response.status} ${error.response.statusText}] ${error.response.data?.code}: ${error.response.data?.message}`);
            }
        }
    }, [userQuery.status]);

    return {
        user: userQuery.data
    };
};
