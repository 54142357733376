import { DefaultButton, IconButton, PrimaryButton, Shimmer, ShimmerElementType, TooltipDelay, TooltipHost } from "@fluentui/react";
import { useId } from '@fluentui/react-hooks';

import { format } from "date-fns";
import React, { ReactFragment, useCallback } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { AppRoutes } from "../constants/AppRoutes";
import { canRequestReimbursement, canWithdraw, Form, FormStatus, formStatusToString, FormType, formTypeToString } from "../data/models/Form";
import { hasPermission, hasRole, UserRole } from "../data/models/User";
import { DeleteForm, UpdateForm } from "../data/queries/FormQueries";
import { GetUser } from "../data/queries/UserQueries";
import { QueryKeys } from "../data/QueryKeys";
import { useUserContext } from "../hooks/useUserContext";
import { Flex } from "./Flex";

import "./FormList.scss";
import { FormVppwPreApproval } from "../data/models/FormVppwPreApproval";

export interface FormListProps
{
    items?: Form[];
    isLoading?: boolean;
    onRowClick?: (e: React.MouseEvent<HTMLAnchorElement>, item: Form) => void;
    onRowAction?: (form: Form, action: string) => void;
}

export const FormList = (props: FormListProps) => 
{
    const { onRowClick, onRowAction } = props;
    const { user } = useUserContext();

    const shimmerElements = [
        { type: ShimmerElementType.line, height: 41 },
    ];

    return (
        <Flex column className="formlist">
            {
                props.isLoading &&
                <div style={{ borderTop: '1px solid #ddd', width: '100%' }}>
                    <div style={{ padding: '15px 20px', borderBottom: '1px solid #ddd' }}><Shimmer shimmerElements={shimmerElements} /></div>
                    <div style={{ padding: '15px 20px', borderBottom: '1px solid #ddd' }}><Shimmer shimmerElements={shimmerElements} /></div>
                    <div style={{ padding: '15px 20px', borderBottom: '1px solid #ddd' }}><Shimmer shimmerElements={shimmerElements} /></div>
                </div>
            }
            {
                !props.isLoading && props.items?.map(i => {
                    return (
                        <FormListItem form={i} url={AppRoutes.byFormType(i.type, i.id)} key={i.id} onClick={onRowClick} onActionClick={(form, action) => props.onRowAction?.(form, action)} />
                    );
                })
            }
            {
                !props.isLoading && props.items?.length === 0  &&
                <div className="no-forms">
                    <h3>No forms found</h3>
                    <p style={{ color: '#777' }}>There were no forms found. Please try another status filter or create a new form by using the buttons on the right.</p>
                    { hasRole(user, UserRole.Accounting) &&
                        <p style={{ color: '#777' }}>Note: Some forms may be hidden due to your role.</p>
                    }
                    <p></p>
                </div>
            }
        </Flex>
    );
};

export interface FormListItemProps 
{
    url: string;
    onClick?: (e: React.MouseEvent<HTMLAnchorElement>, form: Form) => void;
    onActionClick?: (form: Form, action: string) => void;
    form: Form;
};

export const FormListItem = (props: FormListItemProps) => 
{
    const { form, onClick, onActionClick } = props;
    const { user } = useUserContext();
    const userId = form?.advisorUserId ?? '';
    const userQuery = useQuery(QueryKeys.User.Id(userId), () => GetUser(userId), { enabled: !!userId });

    const onRowClick = useCallback((e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        onClick?.(e, form);
    }, [onClick, form]);

    const onRequestReimbursementClick = useCallback((e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        e.stopPropagation();
        onActionClick?.(form, 'reimbursement');
    }, [onActionClick, form]);

    const showWithdraw = canWithdraw(user!, form);
    const showAdminActions = hasRole(user, UserRole.Administrator);

    return (
        <a href={props.url} className="formlist-item" onClick={onRowClick} style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
            <div style={{ display: 'flex', gap: '15px' }}>
            <FormListItem.Column header="Form ID">{form?.formId}</FormListItem.Column>
            <FormListItem.Column header="Advisor Name">{userQuery.data?.name}</FormListItem.Column>
            <FormListItem.Column header="Type of Form">{formTypeToString(form?.type ?? 'invalid')}</FormListItem.Column>
            <FormListItem.Column header="Status">{formStatusToString(form?.formStatus ?? FormStatus.Invalid)}</FormListItem.Column>
            <FormListItem.Column header="Created">{format(new Date(form?.createdOn ?? new Date()), 'P')}</FormListItem.Column>

            {/* <DefaultButton text="Withdraw Request" styles={{ root: { alignSelf: 'center', visibility: canWithdraw(user!, form) ? 'visible' : 'hidden' } }} onClick={(e) => withdrawRequest(e, form)} /> */}
            <PrimaryButton text="Request Reimbursement" styles={{ root: { alignSelf: 'center', visibility: canRequestReimbursement(user!, form) ? 'visible' : 'hidden' } }} onClick={onRequestReimbursementClick} />
            {/* <DefaultButton text="Delete (DEBUG)" styles={{ root: { alignSelf: 'center' } }} onClick={(e) => deleteRequest(e, form)} /> */}

            <IconButton iconProps={{ iconName: 'MoreVertical' }} styles={{ root: { alignSelf: 'center' } }} menuProps={{
                items: [
                    ...(showWithdraw ? [{ key: 'withdraw', text: 'Withdraw Request', iconProps: { iconName: 'Cancel' } }] : []),
                    ...(showAdminActions ? [{ key: 'delete', text: 'Delete Request (Admin only)', iconProps: { iconName: 'Delete' }}] : []),
                    ...(showAdminActions && (form as FormVppwPreApproval).isStandingApproval ? [{ key: 'revoke-approval', text: 'Revoke standing approval (Admin only)', iconProps: { iconName: 'PageRemove' }}] : []),
                    ...(!showWithdraw && !showAdminActions ? [{ key: 'none', text: 'No actions', disabled: true }] : [])
                ],
                onItemClick: (ev, item) => {
                    if(item)
                        onActionClick?.(form, item.key);
                }
            }} menuIconProps={{ style: { display: 'none' } }} />

            {/* <TooltipHost
                content="Request Reimbursement"
                //id={tooltipId}
                delay={TooltipDelay.zero}
                styles={{ root: { alignSelf: 'center' } }}
            >
                <IconButton iconProps={{ iconName: 'EntitlementRedemption' }} styles={{ root: { alignSelf: 'center', visibility: canRequestReimbursement(form) ? 'visible' : 'hidden' } }} onClick={(e) => {
                    requestReimbursement(form?.type, form?.id);
                    e.preventDefault();
                    e.stopPropagation();
                }} />
            </TooltipHost> */}
            {/* <IconButton iconProps={{ iconName: 'MoreVertical' }} styles={{ root: { alignSelf: 'center', visibility: canWithdraw(form) ? 'visible': 'hidden' } }} /> */}
            </div>

        </a>
    );
};

export interface FormListItemColumnProps 
{
    children: any;
    header?: string;
    style?: React.CSSProperties;
}

FormListItem.Column = (props: FormListItemColumnProps) => 
{
    return (
        <div className="formlist-column" style={props.style}>
            {
                props.header ?
                <div className="column-header">{props.header}</div>
                : null
            }
            
            <div className="column-value">{props.children}</div>
        </div>
    );
};