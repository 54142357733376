import { Task } from "../models/Task";
import { MakeAPIUrl } from "../Urls";
import { axiosInstance } from "./QueryHelpers";

export async function GetTasks<T extends Task>(formType: string, formId: string)
{
    return (await axiosInstance.get<T[]>(MakeAPIUrl(`/forms/${formType}/${formId}/tasks`))).data;
};

export async function UpdateTask<T extends Task>(formType: string, formId: string, taskId: string, data: Partial<T>)
{
    return (await axiosInstance.patch(MakeAPIUrl(`/forms/${formType}/${formId}/tasks/${taskId}`), data)).data;
}
