import { Form, Formik } from "formik";
import { format, isDate } from "date-fns";
import { CSSProperties } from "react";
import { useQuery } from "react-query";
import { FormSectionHeader } from "../../components/FormSectionHeader";
import { FormCoopPreApproval } from "../../data/models/FormCoopPreApproval"
import { GetUser } from "../../data/queries/UserQueries";
import { QueryKeys } from "../../data/QueryKeys";
import { VPIDisplayField } from "../../fields/VPIDisplayField";
import { currencyToString } from "../../utilities/FormUtils";
import { GetTasks } from "../../data/queries/TaskQueries";
import { sortByProperty } from "../../utilities/Utils";
import { TaskOutcome } from "../../data/models/Task";
import { FormStatus } from "../../data/models/Form";
import { FormCoopReimbursement } from "../../data/models/FormCoopReimbursement";
import { VPIAttachmentsDisplayField } from "../../fields/VPIAttachmentsDisplayField";
import { FormStatusBar } from "../../components/FormStatusBar";
import { useUserContext } from "../../hooks/useUserContext";
import { hasRole, UserRole } from "../../data/models/User";
import { AppRoutes } from "../../constants/AppRoutes";
import { FontIcon } from "@fluentui/react";
import { DetailsGridStyles2Col, DetailsGridStyles3Col } from "../../constants/Constants";
import { TaskCoopReimbursement } from "../../data/models/TaskCoopReimbursement";

export interface CoopReimbursementDetailsProps
{
    form: FormCoopReimbursement;
    preApprovalForm?: FormCoopPreApproval;
    variant?: '3column' | '2column';
}

export const CoopReimbursementDetails = (props: CoopReimbursementDetailsProps) =>
{
    const { form, preApprovalForm, variant = '3column' } = props;
    const { user } = useUserContext();
    const initialValues = form ?? {};
    const userQuery = useQuery(QueryKeys.User.Id(form.advisorUserId), () => GetUser(form.advisorUserId), { enabled: !!form.advisorUserId });
    const tasksQuery = useQuery(QueryKeys.CoopReimbursement.Tasks(form.id), () => GetTasks<TaskCoopReimbursement>(form.type, form.id))

    const latestComplianceTask = [...(tasksQuery.data || [])].sort(sortByProperty('createdOn', false)).find(task => task.stage === 'compliance-review' && task.outcome === TaskOutcome.Approved);

    let s: CSSProperties = Object.assign({}, DetailsGridStyles3Col, {
        gridTemplateAreas: `
            'preApprovalFormId preApprovalFormId .'
            'date dealerName .'
            'repCode . .'
            'location . .'
            'amountRequested . .'
            'attachments attachments .'
            'payableTo mailingAddress .'
            'attendanceHeader attendanceHeader attendanceHeader'
            'attendanceClients attendanceEmployees .'
            'certificationHeader certificationHeader certificationHeader'
            'dealerForm dealerForm .'
            'attendanceAnalysisHeader attendanceAnalysisHeader attendanceAnalysisHeader'
            'sr1 sr1 sr1'
            'estimatedCost actualCost costVariance'
            'sr2 sr2 sr2'
            'estimatedAttendance actualAttendance attendanceVariance'
            'sr3 sr3 sr3'
            'estimatedCostPerPerson actualCostPerPerson costPerPersonVariance'
        `
    });

    if(variant === '2column')
    {
        s = Object.assign({}, DetailsGridStyles2Col, {
            gridTemplateAreas: `
                'preApprovalFormId preApprovalFormId'
                'date dealerName'
                'repCode .'
                'location amountRequested'
                'attachments attachments'
                'payableTo payableTo'
                'mailingAddress mailingAddress'
                'attendanceHeader attendanceHeader'
                'attendanceClients attendanceEmployees'
                'certificationHeader certificationHeader'
                'dealerForm dealerForm'
                'attendanceAnalysisHeader attendanceAnalysisHeader'
                'sr1 sr1'
                'estimatedCost actualCost'
                'costVariance .'
                'sr2 sr2'
                'estimatedAttendance actualAttendance'
                'attendanceVariance .'
                'sr3 sr3'
                'estimatedCostPerPerson actualCostPerPerson'
                'costPerPersonVariance .'
            `
        });
    }

    return (
        <>
        <FormStatusBar status={form?.formStatus} />
        <Formik
            initialValues={initialValues}
            onSubmit={() => {}}
            enableReinitialize>
            { formProps => (
                <Form style={s}>
                    <VPIDisplayField name="preApprovalFormId" label="Related Pre-Approval" render={() => {
                        if(preApprovalForm)
                            return <a target="_blank" href={AppRoutes.byFormType(preApprovalForm.type, preApprovalForm.id)}>{preApprovalForm.formId} <FontIcon iconName="NavigateExternalInline" /></a>;
                        else
                            return 'N/A';
                    }} />
                    <VPIDisplayField name="date" label="Date" render={(date: Date) => isDate(date) ? format(date, 'PP') : 'No date set' } />
                    <VPIDisplayField name="dealerName" label="Dealer Name" />
                    <VPIDisplayField name="repCode" label="Rep Code" />
                    <VPIDisplayField name="location" label="Location" />
                    <VPIDisplayField name="amountRequested" label="Amount Requested" render={value => currencyToString(value)} />

                    <VPIAttachmentsDisplayField name="attachments" label="Attachments" />

                    <VPIDisplayField name="payableTo" label="Payable To" />
                    <VPIDisplayField name="mailingAddress" label="Mailing Address" />

                    <FormSectionHeader gridArea="attendanceHeader">
                        <h3>Attendance Information (Actual)</h3>
                    </FormSectionHeader>
                    <VPIDisplayField name="attendanceClients" label="Clients" />
                    <VPIDisplayField name="attendanceEmployees" label="Employees" />

                    <FormSectionHeader gridArea="certificationHeader">
                        <h3>Signed Dealer Certification</h3>
                    </FormSectionHeader>
                    <VPIAttachmentsDisplayField name="dealerForm" />

                    <FormSectionHeader gridArea="attendanceAnalysisHeader">
                        <h3>Attendance Information Analysis</h3>
                    </FormSectionHeader>

                    <h3 style={{ gridArea: 'sr1' }}>Cost</h3>
                    <VPIDisplayField name="estimatedCost" label="Budgeted Cost (Estimate)" render={() =>
                        preApprovalForm ? currencyToString(preApprovalForm.eventEstimatedTotalCost) : 'N/A'
                    } />
                    <VPIDisplayField name="actualCost" label="Actual Cost" render={() => currencyToString(form.amountRequested) } />
                    <VPIDisplayField name="costVariance" label="Variance" render={() =>
                        preApprovalForm ? currencyToString(form.amountRequested - preApprovalForm.eventEstimatedTotalCost) : 'N/A'
                    } />
                    <h3 style={{ gridArea: 'sr2', margin: '15px 0 0 0' }}>Attendance</h3>
                    <VPIDisplayField name="estimatedAttendance" label="Total Attendance (Estimate)" render={() =>
                         preApprovalForm ? (preApprovalForm.attendanceClients + preApprovalForm.attendanceEmployees).toString() : 'N/A'
                    } />
                    <VPIDisplayField name="actualAttendance" label="Total Attendance (Actual)" render={() =>
                        (form.attendanceClients + form.attendanceEmployees).toString()
                    } />
                    <VPIDisplayField name="attendanceVariance" label="Variance" render={() =>
                        preApprovalForm ? (
                            (form.attendanceClients + form.attendanceEmployees) -
                            (preApprovalForm.attendanceClients + preApprovalForm.attendanceEmployees)
                        ).toString() : 'N/A'
                    } />
                    <h3 style={{ gridArea: 'sr3', margin: '15px 0 0 0' }}>Cost Per Person</h3>
                    <VPIDisplayField name="estimatedCostPerPerson" label="Cost Per Person (Estimate)" render={() =>
                        preApprovalForm && (preApprovalForm.attendanceClients + preApprovalForm.attendanceEmployees > 0) ? 
                            currencyToString(preApprovalForm.eventEstimatedTotalCost / (preApprovalForm.attendanceClients + preApprovalForm.attendanceEmployees)) : 'N/A'
                    } />
                    <VPIDisplayField name="actualCostPerPerson" label="Cost Per Person (Actual)" render={() => 
                        (form.attendanceClients + form.attendanceEmployees > 0) ? currencyToString(form.amountRequested / (form.attendanceClients + form.attendanceEmployees)) : 'N/A'
                    } />
                    <VPIDisplayField name="costPerPersonVariance" label="Variance" render={() =>
                        preApprovalForm && (preApprovalForm.attendanceClients + preApprovalForm.attendanceEmployees > 0) && (form.attendanceClients + form.attendanceEmployees > 0) ? 
                            currencyToString(
                                (form.amountRequested / (form.attendanceClients + form.attendanceEmployees)) -
                                (preApprovalForm.eventEstimatedTotalCost / (preApprovalForm.attendanceClients + preApprovalForm.attendanceEmployees)) 
                            ) 
                        : 'N/A'
                    } />
                </Form>
            )}
        </Formik>
        { 
            latestComplianceTask && 
            (form.formStatus === FormStatus.FinanceApproval || form.formStatus === FormStatus.Completed || form.formStatus === FormStatus.Paid) && 
            hasRole(user, UserRole.Compliance, UserRole.Finance) &&
            <CoopComplianceChecklist task={latestComplianceTask} variant={variant} form={form} preApprovalForm={preApprovalForm} /> 
        }
        </>
    )
}

interface CoopComplianceChecklistProps
{
    task: TaskCoopReimbursement;
    preApprovalForm?: FormCoopPreApproval;
    form: FormCoopReimbursement;
    variant: '2column' | '3column';
}

const CoopComplianceChecklist = (props: CoopComplianceChecklistProps) =>
{
    const { task, form, preApprovalForm, variant } = props;

    let s: CSSProperties = Object.assign({}, DetailsGridStyles3Col, {
        gridTemplateAreas: `
            'assessmentHeader assessmentHeader assessmentHeader'
            'providedInvoiceCopiesComment providedInvoiceCopiesComment providedInvoiceCopiesComment'
            'reimbursementPaidDealerMemberComment reimbursementPaidDealerMemberComment reimbursementPaidDealerMemberComment'
            'analysisHeader analysisHeader analysisHeader'
            'sr1 sr1 sr1'
            'budgetedCost actualCost costVariance'
            'budgetedCostVarianceComment budgetedCostVarianceComment .'
            'sr2 sr2 sr2'
            'budgetedAttendance totalAttendance attendanceVariance'
            'numberParticipantsVarianceComment numberParticipantsVarianceComment .'
            'sr3 sr3 sr3'
            'budgetedCostPerPerson actualCostPerPerson variancePerPerson'
            'budgetedCostPerPersonVarianceComment budgetedCostPerPersonVarianceComment .'
        `
    });

    if(variant === '2column')
    {
        s = Object.assign({}, DetailsGridStyles2Col, {
            gridTemplateAreas: `
                'assessmentHeader assessmentHeader'
                'providedInvoiceCopiesComment providedInvoiceCopiesComment'
                'reimbursementPaidDealerMemberComment reimbursementPaidDealerMemberComment'
                'analysisHeader analysisHeader'
                'sr1 sr1'
                'budgetedCost actualCost'
                'costVariance .'
                'budgetedCostVarianceComment budgetedCostVarianceComment'
                'sr2 sr2'
                'budgetedAttendance totalAttendance'
                'attendanceVariance .'
                'numberParticipantsVarianceComment numberParticipantsVarianceComment'
                'sr3 sr3'
                'budgetedCostPerPerson actualCostPerPerson'
                'variancePerPerson .'
                'budgetedCostPerPersonVarianceComment budgetedCostPerPersonVarianceComment'
            `
        });
    }

    return (
        <Formik
            initialValues={task}
            onSubmit={() => {}}
            enableReinitialize>
            { formProps => (
                <Form style={s}>
                    <FormSectionHeader gridArea="assessmentHeader">
                        <hr style={{ display: 'block' }} />
                        <h3>Assessment</h3>
                    </FormSectionHeader>
                    <VPIDisplayField name="providedInvoiceCopiesComment" label="1. Has the dealer provided copies of invoices? If so, are there any ineligible expenses, for example, gifts, in invoice(s)?" />
                    <VPIDisplayField name="reimbursementPaidDealerMemberComment" label="2. Is the reimbursement being paid out to Dealer member? Note: Reimbursements to dealing representatives is not allowed." />

                    <FormSectionHeader gridArea="analysisHeader">
                        <h3>Quantitative Analysis</h3>
                    </FormSectionHeader>
                    <h4 style={{ gridArea: 'sr1' }}>Cost</h4>
                    <VPIDisplayField name="budgetedCost" label="Budgeted Cost" value={preApprovalForm ? currencyToString(preApprovalForm.eventEstimatedTotalCost) : 'N/A'} />
                    <VPIDisplayField name="actualCost" label="Actual Cost" render={() => currencyToString(task.actualCost)} />
                    <VPIDisplayField name="costVariance" label="Variance" value={preApprovalForm ? currencyToString(task.actualCost - preApprovalForm.eventEstimatedTotalCost) : 'N/A'} />
                    <VPIDisplayField name="budgetedCostVarianceComment" label="Notes" />

                    <h4 style={{ gridArea: 'sr2' }}>Attendance</h4>
                    <VPIDisplayField name="budgetedAttendance" label="Budgeted Attendance" value={preApprovalForm ? preApprovalForm.attendanceClients + preApprovalForm.attendanceEmployees : 'N/A'} />
                    <VPIDisplayField name="totalAttendance" label="Total Attendance" value={task.totalAttendance} />
                    <VPIDisplayField name="attendanceVariance" label="Variance" value={preApprovalForm ? task.totalAttendance - (preApprovalForm.attendanceClients + preApprovalForm.attendanceEmployees) : 'N/A' } />
                    <VPIDisplayField name="numberParticipantsVarianceComment" label="Notes" />

                    <h4 style={{ gridArea: 'sr3' }}>Cost Per Person</h4>
                    <VPIDisplayField name="budgetedCostPerPerson" label="Budgeted Cost Per Person" value={preApprovalForm && (preApprovalForm.attendanceClients + preApprovalForm.attendanceEmployees > 0) ? 
                        currencyToString(preApprovalForm.eventEstimatedTotalCost / (preApprovalForm.attendanceClients + preApprovalForm.attendanceEmployees)) : 'N/A'} />
                    <VPIDisplayField name="actualCostPerPerson" label="Actual Cost Per Person" value={formProps.values.totalAttendance > 0 ? currencyToString(formProps.values.actualCost / formProps.values.totalAttendance) : 'N/A'} />
                    <VPIDisplayField name="variancePerPerson" label="Variance" value={
                        preApprovalForm && (preApprovalForm.attendanceClients + preApprovalForm.attendanceEmployees > 0) && formProps.values.totalAttendance > 0 ?
                        currencyToString((formProps.values.actualCost / formProps.values.totalAttendance) - (preApprovalForm.eventEstimatedTotalCost / (preApprovalForm.attendanceClients + preApprovalForm.attendanceEmployees)))
                        : 'N/A'
                    } />
                    <VPIDisplayField name="budgetedCostPerPersonVarianceComment" label="Notes" />
                </Form>
            )}
        </Formik>
    )
};