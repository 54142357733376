import axios, { AxiosResponse } from "axios";
import { Form, FormStatus, FormType } from "../models/Form";
import { TaskOutcome } from "../models/Task";
import { MakeAPIUrl } from "../Urls";
import { axiosInstance } from "./QueryHelpers";

interface SubmitFormPayload
{
    comments?: string;
}

interface ApproveFormPayload
{
    comments?: string;
}

interface RejectFormPayload
{
    comments?: string;
}

interface RequestClarificationPayload
{
    outcome: TaskOutcome;
    comments: string;
}

interface RequestRevisionPayload
{
    comments?: string;
}

interface CancelFormPayload
{
    comments?: string;
}

interface ResubmitFormPayload
{
    comments?: string;
}

interface MarkAsPaidPayload
{
    comments?: string;
    paymentDate: Date;
}

export async function GetForms(status?: FormStatus)
{
    return (await axiosInstance.get<Form[]>(MakeAPIUrl(`/forms${ status ? `?status=${status}` : '' }`))).data;
}

// export async function GetFormsByType<T extends Form>(formType: FormType)
// {
//     return (await axiosInstance.get<T[]>(MakeAPIUrl(`/forms/${formType}`))).data
// }

export async function GetFormsAssignedToMe<T extends Form>()
{
    return (await axiosInstance.get<T[]>(MakeAPIUrl(`/forms/assignedToMe`))).data;
}

export async function GetForm<T extends Form>(formType: FormType, formId: string)
{
    return (await axiosInstance.get<T>(MakeAPIUrl(`/forms/${formType}/${formId}`))).data;
}

export async function CreateForm<T extends Form>(formType: FormType, data: Partial<T>)
{
    return (await axiosInstance.post<T>(MakeAPIUrl(`/forms/${formType}`), data)).data
}

export async function UpdateForm<T extends Form>(formType: FormType, formId: string, data: Partial<T>)
{
    return (await axiosInstance.patch<T>(MakeAPIUrl(`/forms/${formType}/${formId}`), data)).data;
}

export async function DeleteForm<T extends Form>(formType: FormType, formId: string)
{
    return (await axiosInstance.delete<T>(MakeAPIUrl(`/forms/${formType}/${formId}`))).data;
}

export async function ApproveForm<T extends Form>(formType: FormType, formId: string, data: ApproveFormPayload)
{
    return (await axiosInstance.post<T>(MakeAPIUrl(`/forms/${formType}/${formId}/actions/approve`), data)).data;
}

export async function SubmitForm<T extends Form>(formType: FormType, formId: string, data?: SubmitFormPayload)
{
    return (await axiosInstance.post<T>(MakeAPIUrl(`/forms/${formType}/${formId}/actions/submit`), data)).data;
}

export async function MarkFormAsPaid<T extends Form>(formType: FormType, formId: string, data: MarkAsPaidPayload)
{
    const { comments, paymentDate } = data;
    return (await axiosInstance.post<T>(MakeAPIUrl(`/forms/${formType}/${formId}/actions/paid`), { comments, paymentDate })).data;
}

export async function RejectForm<T extends Form>(formType: FormType, formId: string, data: RejectFormPayload)
{
    return (await axiosInstance.post<T>(MakeAPIUrl(`/forms/${formType}/${formId}/actions/reject`), data)).data;
}

export async function RequestClarification<T extends Form>(formType: FormType, formId: string, data: RequestClarificationPayload)
{
    return (await axiosInstance.post<T>(MakeAPIUrl(`/forms/${formType}/${formId}/actions/requestinfo`), data)).data;
}

export async function RequestRevision<T extends Form>(formType: FormType, formId: string, data: RequestRevisionPayload)
{
    return (await axiosInstance.post<T>(MakeAPIUrl(`/forms/${formType}/${formId}/actions/requestedit`), data)).data;
}

export async function ResubmitForm<T extends Form>(formType: FormType, formId: string, data: ResubmitFormPayload)
{
    return (await axiosInstance.post<T>(MakeAPIUrl(`/forms/${formType}/${formId}/actions/resubmit`), data)).data;
}

export async function CancelForm<T extends Form>(formType: FormType, formId: string, payload: CancelFormPayload)
{
    return (await axiosInstance.post<T>(MakeAPIUrl(`/forms/${formType}/${formId}/actions/cancel`), payload)).data;
}