import { ReactNode, useCallback } from "react";
import { DefaultButton } from "@fluentui/react";
import { useQuery, useQueryClient } from "react-query";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Card } from "../../components/Card"
import { CardContent } from "../../components/CardContent";
import { CardHeader } from "../../components/CardHeader"
import { Instructions } from "../../components/Instructions";
import { PageContainer } from "../../components/PageContainer";
import { PageHeader } from "../../components/PageHeader";
import { AppRoutes } from "../../constants/AppRoutes";
import { FormStatus } from "../../data/models/Form";
import { TaskOutcome } from "../../data/models/Task";
import { GetForm } from "../../data/queries/FormQueries";
import { GetTasks } from "../../data/queries/TaskQueries";
import { QueryKeys } from "../../data/QueryKeys";
import { VPPWReimbursementForm } from "./VPPWReimbursementForm"
import { FormVppwReimbursement } from "../../data/models/FormVppwReimbursement";
import { TaskVppwReimbursement } from "../../data/models/TaskVppwReimbursement";
import { DetailsCardShimmer, FormCardShimmer } from "../../helpers/Shimmer";
import { TaskHistoryList } from "../../components/TaskHistoryList";
import { ClarificationStage, ComplianceApprovalStage, DealerApprovalStage, FinanceApprovalStage, NoActionsStage, submitForm } from "../../forms/DefaultStages";
import { useUserContext } from "../../hooks/useUserContext";
import { hasPermission, hasRole, UserRole } from "../../data/models/User";
import { VPPWReimbursementDetails } from "./VPPWReimbursementDetails";
import { FormVppwPreApproval } from "../../data/models/FormVppwPreApproval";
import { Flex } from "../../components/Flex";
import { VPIDisplayTextBox } from "../../fields/VPIDisplayTextBox";
import { currencyToString } from "../../utilities/FormUtils";
import { VPPWFinanceApprovalStage } from "./VPPWFinanceApprovalStage";

const FORM_TYPE = 'vppw-reimbursement';
const INSTRUCTIONS_URL = '/instructions/VPPW - Reimbursement Instructions.pdf';

export const VPPWReimbursementWorkflow = (props: any) => 
{
    const { formId } = useParams();
    const { user } = useUserContext();
    const queryClient = useQueryClient();
    const [searchParams] = useSearchParams();
    const nav = useNavigate();
    const isNewForm = formId === 'new';

    const formQuery = useQuery(QueryKeys.VPPWReimbursement.Form(formId || ''), async () => GetForm<FormVppwReimbursement>(FORM_TYPE, formId || ''), { enabled: !isNewForm });
    const taskQuery = useQuery(QueryKeys.VPPWReimbursement.Tasks(formId || ''), async () => GetTasks<TaskVppwReimbursement>(FORM_TYPE, formId || ''), { enabled: !isNewForm });
    const preApprovalFormId = formQuery.data?.preApprovalFormId || searchParams.get('p') || '';
    const preApprovalFormQuery = useQuery(QueryKeys.VPPWPreApproval.Form(preApprovalFormId), () => GetForm<FormVppwPreApproval>('vppw-preapproval', preApprovalFormId), { enabled: formQuery.isSuccess && !!preApprovalFormId });
    const isLoaded = isNewForm || (formQuery.isSuccess && taskQuery.isSuccess && (preApprovalFormQuery.isSuccess || !preApprovalFormId));

    let detailsView: ReactNode = <DetailsCardShimmer />;
    let formView: ReactNode = <FormCardShimmer />

    const form = formQuery.data;
    const tasks = taskQuery.data;
    const preApprovalForm = preApprovalFormQuery.data;

    const refreshPage = useCallback(() => { 
        queryClient.invalidateQueries(QueryKeys.VPPWReimbursement.Form(formId || '')); 
        nav(AppRoutes.Dashboard()); 
    }, [queryClient, formId]);

    const onSaveForm = useCallback(async (formData: Partial<FormVppwReimbursement>, action: 'save-draft' | 'submit') => {
        try
        {
            await submitForm(FORM_TYPE, form?.id, formData, action);
            refreshPage();
        }
        catch(error: any)
        {
            console.log(error);
            alert(JSON.stringify(error.response.data));
        }
    }, [form, refreshPage]);

    if(isLoaded)
    {
        if(isNewForm)
        {
            detailsView = <Instructions url={INSTRUCTIONS_URL} />;
            formView = (
                <>
                    <CardHeader>VPPW - Reimbursement Request</CardHeader>
                    <CardContent>
                        <VPPWReimbursementForm onSave={onSaveForm}/>
                    </CardContent>
                </>
            );
        }
        // If a form item has already been created and is fetched (i.e. not new)
        else if(form && tasks)
        {
            const currentTask = tasks.find(t => !t.outcome || t.outcome === TaskOutcome.InProgress);

            switch(form.formStatus)
            {
                case FormStatus.Draft:
                case FormStatus.AdvisorRevision:
                    detailsView = <Instructions url={INSTRUCTIONS_URL} />;
                    break;
                case FormStatus.AdvisorClarification:
                //case FormStatus.DealerApproval:
                //case FormStatus.DealerClarification:
                case FormStatus.BusinessApproval:
                case FormStatus.BusinessClarification:
                case FormStatus.ComplianceApproval:
                case FormStatus.ComplianceClarification:
                case FormStatus.FinanceApproval:
                case FormStatus.Completed:
                    detailsView = <VPPWReimbursementDetails form={form} preApprovalForm={preApprovalForm} variant="2column" />
                    break;
                default:
                    detailsView = null;
                    break;
            }

            // Set the form view based on form status and user role
            if((form.formStatus === FormStatus.Draft || form.formStatus === FormStatus.AdvisorRevision) && hasPermission(user, 'vppw.write'))
                formView = (
                    <>
                        <CardHeader>VPPW - Reimbursement Request | {form.formId}</CardHeader>
                        <CardContent>
                            <VPPWReimbursementForm form={form} onSave={onSaveForm} allowComments={!!currentTask} />
                        </CardContent>
                    </>
                );
            else if(form.formStatus === FormStatus.AdvisorClarification && hasPermission(user, 'vppw.write'))
                formView = <ClarificationStage form={form} task={currentTask} onSave={refreshPage} title="Advisor Clarification Requested" />;
            // else if(form.formStatus === FormStatus.DealerClarification && hasRole(user, UserRole.Dealer))
            //     formView = <ClarificationStage form={form} task={currentTask} onSave={refreshPage} title="Dealer Clarification Requested" />;
            else if(form.formStatus === FormStatus.BusinessClarification && hasRole(user, UserRole.VPPWBusiness))
                formView = <ClarificationStage form={form} task={currentTask} onSave={refreshPage} title="Business Clarification Requested" />;
            else if(form.formStatus === FormStatus.ComplianceClarification && hasRole(user, UserRole.VPPWCompliance))
                formView = <ClarificationStage form={form} task={currentTask} onSave={refreshPage} title="Compliance Clarification Requested" />;
            //else if(form.formStatus === FormStatus.DealerApproval && hasRole(user, UserRole.Dealer))
            else if(form.formStatus === FormStatus.BusinessApproval && hasRole(user, UserRole.VPPWBusiness))
                formView = <VPPWReimbursementDealerStage form={form} preApprovalForm={preApprovalForm} task={currentTask} onSave={refreshPage} />
            else if(form.formStatus === FormStatus.ComplianceApproval && hasRole(user, UserRole.VPPWCompliance))
                formView = <ComplianceApprovalStage form={form} task={currentTask} onSave={refreshPage} allowDealerClarification={false} allowBusinessClarification vpiLabels={false} />;
            else if(form.formStatus === FormStatus.FinanceApproval && hasRole(user, UserRole.VPPWFinance))
                formView = <VPPWFinanceApprovalStage form={form} task={currentTask} onSave={refreshPage} allowDealerClarification={false} allowBusinessClarification vpiLabels={false} />;
            // else if(form.formStatus === FormStatus.Completed && hasRole(user, UserRole.Accounting))
            //     formView = <AccountingPaymentStage form={form} task={currentTask} onSave={refreshPage} />
            else if(form.formStatus === FormStatus.Paid
                || form.formStatus === FormStatus.Withdrawn 
                || form.formStatus === FormStatus.Rejected)
            {
                formView = (
                    <>
                        <CardHeader>{form.formId} | VPPW - Reimbursement Request</CardHeader>
                        <CardContent>
                            <VPPWReimbursementDetails form={form} preApprovalForm={preApprovalForm} />
                        </CardContent>
                    </>
                );
            }
            else 
            {
                formView = <NoActionsStage title="VPPW Reimbursement Form" />;
            }
        }
    }

    return (
        <PageContainer type={detailsView ? '3column' : '2column'}>
            <PageHeader>
                <DefaultButton text="Back" onClick={() => nav(AppRoutes.Dashboard())} />
            </PageHeader>
            { detailsView &&
                <Card style={{ alignSelf: 'flex-start' }}>
                    <CardHeader>{formQuery.data?.formId || 'Details'}</CardHeader>
                    <CardContent>{detailsView}</CardContent>
                </Card>
            }
            <Card>{formView}</Card>
            <Card style={{ alignSelf: 'flex-start' }}>
                <CardHeader>History</CardHeader>
                <TaskHistoryList tasks={taskQuery.data?.filter(t => t.outcome && t.outcome !== TaskOutcome.InProgress)} isLoading={taskQuery.isFetching} />
            </Card>
        </PageContainer>
    );
};


interface VPPWReimbursementDealerStage
{
    form: FormVppwReimbursement;
    task?: TaskVppwReimbursement;
    preApprovalForm?: FormVppwPreApproval;
    onSave: () => void;
}

const VPPWReimbursementDealerStage = ({ form, task, preApprovalForm, onSave }: VPPWReimbursementDealerStage) => 
{
    const estimatedAttendance = preApprovalForm ? preApprovalForm.attendanceClients + preApprovalForm.attendanceEmployees : 0;
    const actualAttendance = form.attendanceClients + form.attendanceEmployees;

    return (
        <DealerApprovalStage form={form} task={task} onSave={onSave}>
            <CardHeader>Attendance Information Analysis</CardHeader>
            <CardContent style={{ display: 'flex', gap: '15px', flexDirection: 'column' }}>
                <h3 style={{ margin: '15px 0 0 0' }}>Attendance</h3>
                <Flex gap="15px">
                    <VPIDisplayTextBox label="Total Attendance (Estimate)" value={
                         preApprovalForm ? estimatedAttendance.toString() : 'N/A'
                    } />
                    <VPIDisplayTextBox label="Total Attendance (Actual)" value={
                        actualAttendance.toString()
                    } />
                    <VPIDisplayTextBox label="Variance" value={
                        preApprovalForm ? (actualAttendance - estimatedAttendance).toString() : 'N/A'
                    } />
                </Flex>
                <h3 style={{ margin: '0' }}>Cost</h3>
                <Flex gap="15px">
                    <VPIDisplayTextBox label="Total Cost (Estimate)" value={
                        preApprovalForm ? currencyToString(preApprovalForm.expenseEstimatedTotalCost) : 'N/A'
                    } />
                    <VPIDisplayTextBox label="Total Cost (Actual)" value={
                        currencyToString(form.amountRequested)
                    } />
                    <VPIDisplayTextBox label="Variance" value={
                        preApprovalForm ? currencyToString(form.amountRequested - preApprovalForm.expenseEstimatedTotalCost) : 'N/A'
                    } />
                </Flex>
                <h3 style={{ margin: '15px 0 0 0' }}>Cost Per Person</h3>
                <Flex gap="15px">
                    <VPIDisplayTextBox label="Cost Per Person (Estimate)" value={
                        preApprovalForm && estimatedAttendance > 0 ? currencyToString(preApprovalForm.expenseEstimatedTotalCost / estimatedAttendance) : 'N/A'
                    } />
                    <VPIDisplayTextBox label="Cost Per Person (Actual)" value={
                        actualAttendance > 0 ? currencyToString(form.amountRequested / actualAttendance) : 'N/A'
                    } />
                    <VPIDisplayTextBox label="Variance" value={
                        preApprovalForm && estimatedAttendance > 0 && actualAttendance > 0 ? 
                            currencyToString((form.amountRequested / actualAttendance) - (preApprovalForm.expenseEstimatedTotalCost / estimatedAttendance)) : 'N/A'
                    } />
                </Flex>
            </CardContent>
        </DealerApprovalStage>
    );
};
