
import { Label } from "@fluentui/react";
import { useField } from "formik";

export interface VPIDisplayFieldProps
{
    name: string;
    label?: string;
    render?: (value: any) => React.ReactNode;
    value?: React.ReactNode;
}

export const VPIDisplayField = (props: VPIDisplayFieldProps) => 
{
    const { name, label, render, value } = props;
    const [ field, meta, helpers ] = useField(name);

    let val: React.ReactNode = field.value || value;
    if(render)
        val = render(val);
    if(val === null || val === undefined)
        val = '(None)';

    return (
        <div style={{ gridArea: name }}>
            <Label>{label}</Label>
            {/* <div style={{ color: '#7C7C7C' }}>{render ? render(field.value) : (field.value ? field.value.toString() : '(none)')}</div> */}
            <div style={{ color: '#7C7C7C' }}>{val}</div>
        </div>
    );
};
