import { DefaultButton, Button, PrimaryButton } from "@fluentui/react";
import { Formik, Form, FormikProps } from "formik";
import * as Yup from "yup";
import { VPIDateField } from "../fields/VPIDateField";
import { VPITextField } from "../fields/VPITextField";
import { Task } from "../data/models/Task";
import { VPIDisplayTextBox } from "../fields/VPIDisplayTextBox";
import { format } from "date-fns";
import { VPICalculatedField } from "../fields/VPICalculatedField";

export interface Action
{
    key: string | number;
    text: string;
    buttonType?: 'default' | 'primary' | 'secondary';
}

export interface TaskFormProps
{
    title?: string;
    nameLabel?: string;
    name?: string;
    message?: string;
    showComments?: boolean;

    primaryActions?: Action[];
    secondaryActions?: Action[];
    onSelect?: (key: any, comments: string) => Promise<void>;
}

export const TaskForm = (props: TaskFormProps) => 
{
    const { title, name, message, showComments = false, primaryActions = [], secondaryActions = [], onSelect, nameLabel = 'Name' } = props;
    const date = new Date();
    const schema = Yup.object({
        name: Yup.string().required('Name is required'),
        date: Yup.date().required('Date is required'),
        comments: Yup.string().optional().max(2000, 'Comments cannot be longer than 2,000 characters'),
        action: Yup.string().required()
    });

    const s: React.CSSProperties = {
        display: 'grid',
        gap: '15px',
        gridTemplateAreas: `
            'name'
            'date'
            'comments'
            'actions'
        `
    };

    const buttonFromAction = (formProps: FormikProps<any>, action: Action) => 
    {
        switch(action.buttonType)
        {
            case 'primary': 
                return <PrimaryButton key={action.key || action.text} text={action.text} disabled={formProps.isSubmitting} onClick={(e) => {
                    formProps.setFieldValue('action', action.key);
                    // Form fails validation if submitted immediately after setting value
                    setTimeout(() => formProps.submitForm())
                }} />;
            case 'secondary':
                return <DefaultButton styles={{ root: { backgroundColor: '#333', color: '#fff' } }} key={action.key || action.text} text={action.text} disabled={formProps.isSubmitting} onClick={(e) => {
                    formProps.setFieldValue('action', action.key);
                    // Form fails validation if submitted immediately after setting value
                    setTimeout(() => formProps.submitForm())
                }} />;
            case 'default':
            default:
                return <DefaultButton key={action.key || action.text} text={action.text} disabled={formProps.isSubmitting} onClick={(e) => {
                    formProps.setFieldValue('action', action.key);
                    // Form fails validation if submitted immediately after setting value
                    setTimeout(() => formProps.submitForm())
                }} />;
        }
    }

    return (
        <div>
            { title ? <h4>{title}</h4> : null }
            { message ? <p>{message}</p> : null }
            <Formik
                initialValues={{ name: name ?? '', date: date ?? new Date(), comments: '', action: '' }}
                onSubmit={async (values, actions) => {
                    await onSelect?.(values.action, values.comments);
                }}
                validationSchema={schema}>
                { formProps => (
                    <Form style={s}>
                        <VPIDisplayTextBox label={nameLabel} value={name || ''} />
                        <VPIDisplayTextBox label="Date" value={format(date || new Date(), 'PP')} />

                        { showComments && <VPITextField name="comments" label="Comments" multiline rows={4} disabled={formProps.isSubmitting} /> }
                        
                        <div style={{ gridArea: 'actions', textAlign: 'right' }}>
                            <div style={{ display: 'flex', gap: '10px', justifyContent: 'flex-end', flexWrap: 'wrap' }}>
                                {
                                    secondaryActions.map(action => buttonFromAction(formProps, action))
                                }
                            </div>
                            <hr />
                            <div style={{ display: 'flex', gap: '10px', justifyContent: 'flex-end' }}>
                                {
                                    primaryActions.map(action => buttonFromAction(formProps, action))
                                }
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};